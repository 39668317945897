<template>
    <div class="document-availability-label">
        <ow-loading-skeleton v-if="isLoading || !documentAvailabilityText"
                             height="22px"
                             width="61" />
        <ow-colored-label v-else
                          :theme="documentAvailabilityColor"
                          is-small>
            {{ documentAvailabilityText }}
        </ow-colored-label>
    </div>
</template>

<script lang="ts">
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'

    export default {
        name: 'OwDocumentAvailabilityLabel',

        components: {
            OwColoredLabel,
            OwLoadingSkeleton,
        },

        props: {
            hmlrAvailabilityCode: {
                type: String,
                required: false,
                validator: prop => typeof prop === 'string' || prop === null,
            },
            isOrdered: {
                type: Boolean,
                required: true,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            documentAvailabilityText(): string {
                if (this.isOrdered) {
                    return 'Ordered'
                }

                return HmlDocumentAvailabilityTextForCode[this.hmlrAvailabilityCode]
            },

            documentAvailabilityColor(): string {
                if (this.isOrdered) {
                    return 'shadow'
                }

                switch (this.hmlrAvailabilityCode) {
                    case HmlrDocumentAvailabilityCode.Available:
                        return 'success'
                    case HmlrDocumentAvailabilityCode.NotImmediatelyAvailable:
                        return 'warning'
                    case HmlrDocumentAvailabilityCode.Unavailable:
                    case HmlrDocumentAvailabilityCode.RequiresInvestigation:
                        return 'danger'
                    default:
                        return 'shadow'
                }
            },
        },
    }
</script>
