<template>
    <tbody>
        <tr v-for="itm in allItems"
            class="base-sub-type-table-rows__row"
            :class="{
                'base-sub-type-table-rows__row--added': itm.type === t('label.added'),
                'base-sub-type-table-rows__row--removed': itm.type === t('label.removed'),
                'base-sub-type-table-rows__row--updated': itm.type === t('label.updated'),
            }">
            <td class="text-no-wrap caption-regular base-sub-type-table-rows__type">
                {{ itm.type }}
            </td>
            <template v-if="!isNullOrEmpty(detailColumns)">
                <td v-for="detailColumn in detailColumns"
                    :key="detailColumn.field"
                    class="text-no-wrap caption-regular base-sub-type-table-rows__details--row-type">
                    <base-sub-type-table-cell :data-test-id="`base-sub-type-table-cell-detail-${ detailColumn.field }`"
                                              :value="getDetailValue(itm.item, detailColumn.field, itm.type === t('label.updated')
                                                  ? itm?.itemAfter?.[detailColumn.field]
                                                  : itm?.item?.[detailColumn.field])" />
                </td>
            </template>
            <template v-else>
                <td class="base-sub-type-table-rows__details--row-type">
                    <div v-for="itemKey in Object.keys(itm?.item)"
                         :key="itemKey">
                        <p v-if="showDetailsColumn(itemKey)"
                           class="text-no-wrap base-sub-type-table-rows__label">
                            {{ getHeadingTitle(itemKey) }}
                        </p>
                    </div>
                </td>
                <td v-if="itm.type === t('label.added')">
                    <template v-for="itemKey in Object.keys(itm?.item)"
                              :key="itemKey">
                        <base-sub-type-table-cell v-if="showDetailsColumn(itemKey)"
                                                  :data-test-id="`base-sub-type-table-cell-added-${ itemKey }`"
                                                  :value="getValue(itm.item, itemKey, itm?.item?.[itemKey])" />
                    </template>
                </td>
                <td v-else-if="itm.type === t('label.removed')">
                    <template v-for="itemKey in Object.keys(itm?.item)"
                              :key="itemKey">
                        <base-sub-type-table-cell v-if="showDetailsColumn(itemKey)"
                                                  :data-test-id="`base-sub-type-table-cell-removed-${ itemKey }`"
                                                  :value="getValue(itm.item, itemKey, itm?.item?.[itemKey])" />
                    </template>
                </td>
                <td v-else-if="itm.type === t('label.updated')">
                    <div v-for="itemKey in Object.keys(itm?.item)"
                         :key="itemKey"
                         class="d-flex flex-row">
                        <base-sub-type-table-cell v-if="showDetailsColumn(itemKey) && (itm?.item?.[itemKey] === itm?.itemAfter?.[itemKey])"
                                                  :data-test-id="`base-sub-type-table-cell-updated-from-${ itemKey }`"
                                                  :value="getValue(itm.item, itemKey, itm?.item?.[itemKey])" />
                        <template v-else>
                            <base-sub-type-table-cell v-if="showDetailsColumn(itemKey)"
                                                      :label="$t('label.from')"
                                                      :data-test-id="`base-sub-type-table-cell-updated-from-${ itemKey }`"
                                                      :value="getValue(itm.item, itemKey, itm?.item?.[itemKey])"
                                                      strikethrough />
                            <base-sub-type-table-cell v-if="showDetailsColumn(itemKey)"
                                                      :data-test-id="`base-sub-type-table-cell-updated-to-${ itemKey }`"
                                                      :label="$t('label.to')"
                                                      :value="getValue(itm.itemAfter, itemKey, itm?.itemAfter?.[itemKey])" />
                        </template>
                    </div>
                </td>
            </template>
        </tr>
    </tbody>
</template>

<script setup lang="ts" generic="T">
    import {
        computed,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import { Difference } from "@/components/asset-monitoring/difference-generic"
    import BaseSubTypeTableCell
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table-cell.vue"
    import {
        BaseSubTypeTableItem,
        IBaseSubTypeTableColumn,
    } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const getValue = (item: T, field: string, value: string) => {
        const heading = props.headings.find((heading) => heading.field === field)
        if (heading.customRender) {
            return heading.customRender(item, value)
        }
        return value
    }

    const getDetailValue = (item: T, field: string, value: string) => {
        const heading = props.detailColumns.find((heading) => heading.field === field)
        if (heading.customRender) {
            return heading.customRender(item, value)
        }
        return value
    }

    const props = defineProps<{
        diff?: Difference<T>
        detailColumns?: IBaseSubTypeTableColumn<T>[]
        headings?: IBaseSubTypeTableColumn<T>[]
    }>()

    const { t } = useI18n()
    const updateCount = defineModel('update-count')

    const showDetailsColumn = (field: string) => {
        return props.headings.some((heading) => heading.field === field)
    }

    const getHeadingTitle = (field: string) => {
        return props.headings.find((heading: IBaseSubTypeTableColumn<T>) => heading.field === field)?.title
    }

    const allItems = computed<Array<BaseSubTypeTableItem<T>>>(() => {
        const result = []

        if (!props.diff) {
            return result
        }

        const addedItems = props.diff.added.map((item) => ({
            type: t('label.added'),
            item,
        }))

        const removedItems = props.diff.removed.map((item) => ({
            type: t('label.removed'),
            item,
        }))

        const updatedItems = props.diff.updated.flatMap((item) => {
            // check all the fields in the before / after and
            // return the ones that are different
            if (isNullOrEmpty(props.headings)) {
                return [
                    {
                        type: t('label.updated'),
                        item: item.before,
                        itemAfter: item.after,
                    },
                ]
            }

            const before = {}
            const after = {}
            for (const heading of props.headings) {
                before[heading.field] = item.before?.[heading.field]
                after[heading.field] = item.after?.[heading.field]

                if (!isNullOrEmpty(props.detailColumns)) {
                    for (const detail of props.detailColumns) {
                        before[detail.field] = item.before?.[detail.field]
                        after[detail.field] = item.after?.[detail.field]
                    }
                }
            }

            return [
                {
                    type: t('label.updated'),
                    item: before,
                    itemAfter: after,
                },
            ]
        })

        if (addedItems.length > 0) {
            result.push(...addedItems)
        }

        if (removedItems.length > 0) {
            result.push(...removedItems)
        }

        if (updatedItems.length > 0) {
            result.push(...updatedItems)
        }

        return result
    })

    watch(() => allItems.value, (val) => {
        if (isNullOrEmpty(val)) {
            updateCount.value = 0
            return
        }
        updateCount.value = allItems.value.length
    }, { immediate: true, deep: true })
</script>

<style lang="scss" scoped>
@import "base-sub-type-table-rows";
</style>
