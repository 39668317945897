<template>
    <section :class="{
                 'title-details-header--drop-shadow': hasDropShadow,
             }"
             class="title-details-header">
        <div class="title-details-header__info">
            <h2 class="body-highlight">
                <span data-test="title-details-panel-header-title-number">{{ selectedTitleNumber }}</span>
                <ow-loading-skeleton v-if="isLoading || !isSelectedTitleLoaded"
                                     data-test="title-details-panel-loading"
                                     height="24px"
                                     style="width: 24px;" />
                <ow-register-status-icon v-else-if="isRegisterPurchased || isRegisterUploaded"
                                         :is-register-ordered="isRegisterPurchased"
                                         :is-register-uploaded="isRegisterUploaded"
                                         :response-date-value="registerResponseDate"
                                         :tr-owner-mismatch="ownershipMismatch"
                                         data-test="title-details-panel-header-register-status"
                                         is-small
                                         should-pulse />
            </h2>
            <ow-loading-skeleton v-if="isLoading || !isSelectedTitleLoaded"
                                 data-test="title-details-panel-loading"
                                 height="24px"
                                 style="width: 250px;" />
            <p v-else
               :title="titleAddress"
               class="caption-regular title-details-header__info--address"
               data-test="title-details-panel-header-address">
                {{ titleAddress?.toUpperCase() }}
            </p>
        </div>
        <div class="title-details-header__actions">
            <ow-loading-skeleton v-if="isLoading || !isSelectedTitleLoaded"
                                 data-test="title-details-panel-loading"
                                 height="25px"
                                 style="min-width: 100px; margin-right: 8px" />
            <template v-else>
                <slot name="header-prefix" />
                <ow-button v-if="!isSelectedTitleInCurrentMatter"
                           data-test="title-details-panel-add-to-matter"
                           data-track="TITLE-DETAILS-PANEL - Add to matter"
                           full-height
                           is-primary
                           @click="emit('pin', true)">
                    <span v-t="'titlePanel.header.actions.addToMatter'" />
                </ow-button>

                <ow-button v-else-if="isRegisterAvailable"
                           :disabled="!isRegisterViewable || isRegisterOrdering"
                           :icon="isRegisterOrdering"
                           data-test="title-details-panel-open-register"
                           data-track="TITLE-DETAILS-PANEL - Open register"
                           full-height
                           is-primary
                           @click="emit('open-register', true)">
                    <v-progress-circular v-if="isRegisterOrdering"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <span v-if="!isRegisterOrdering"
                          v-t="'titlePanel.header.actions.openRegister'" />
                </ow-button>

                <order-register-button v-else-if="!isLoading && isSelectedTitleInCurrentMatter"
                                       :current-matter-id="currentMatterId"
                                       :is-ordering-allowed="isOrderingAllowed"
                                       :selected-title-number="selectedTitleNumber"
                                       button-data-test-attribute="title-details-panel-order-register-btn"
                                       data-test="title-details-panel-order-register"
                                       data-track="TITLE-DETAILS-PANEL - Add to matter"
                                       full-height />
                <v-menu transition="fade-transition">
                    <template #activator="{ props }">
                        <ow-button v-if="isCollapsed"
                                   v-bind="props"
                                   class="title-details-header__btn"
                                   data-test="title-details-panel-toggle-action-3-dot"
                                   :tooltip="hasCopilotRole ? $t('titlePanel.header.tooltip.actions') : $t('titlePanel.header.tooltip.actionsNoCopilot')"
                                   full-height
                                   icon
                                   is-tertiary
                                   large>
                            <v-icon>
                                $menu-more-vertical
                            </v-icon>
                        </ow-button>
                    </template>
                    <side-nav-actions :actions="actions"
                                      is-collapsed
                                      :title-number="selectedTitleNumber"
                                      @click="onActionClick" />
                </v-menu>
                <ow-button v-if="isCollapsed"
                           class="title-details-header__btn"
                           data-test="title-details-panel-toggle-collapse-panel"
                           :tooltip="$t('titlePanel.header.tooltip.hideShow')"
                           full-height
                           icon
                           is-tertiary
                           large
                           @click="emit('collapse-panel')">
                    <v-icon v-if="isPanelCollapsed">
                        $chevron-down
                    </v-icon>
                    <v-icon v-else>
                        $chevron-up
                    </v-icon>
                </ow-button>
                <ow-button v-if="toggleTitlePanelWidthButton && !isTitlePanelWidthButtonDisabled"
                           ref="toggleButtonRef"
                           data-test="title-details-panel-toggle-btn"
                           data-track="TITLE-DETAILS-PANEL - Toggle fullscreen"
                           full-height
                           icon
                           is-tertiary
                           :tooltip="isCollapsed ? $t('titlePanel.header.tooltip.expand') : $t('titlePanel.header.tooltip.collapse')"
                           class="title-details-header__btn"
                           @click="onToggleCollapsed">
                    <v-icon>{{ isCollapsed ? '$fullscreen' : '$restore' }}</v-icon>
                </ow-button>
                <ow-button data-test="title-details-panel-close-btn"
                           data-track="TITLE-DETAILS-PANEL - Close panel"
                           full-height
                           icon
                           is-tertiary
                           :tooltip="$t('titlePanel.header.tooltip.close')"
                           class="title-details-header__btn"
                           @click="emit('close-panel', true)">
                    <v-icon>$close</v-icon>
                </ow-button>
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { full } from "acorn-walk"
    import {
        computed,
        ref,
        watch,
    } from "vue"
    import { useRoute } from "vue-router"
    import { useStore } from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwRegisterStatusIcon from '@/components/core/ow-register-status-icon.vue'
    import { ISideNavAction } from "@/components/side-nav"
    import SideNavActions from "@/components/side-nav/side-nav-actions.vue"
    import OrderRegisterButton from '@/components/title-panel/order-register-button.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { inject as titlePanelProvider } from '@/composables/use-title-panel'
    import { ApplicationRoles } from "@/enums/application-roles.enum"
    import { Route } from "@/enums/route.enum"
    import { checkFlag } from "@/feature-flags"

    withDefaults(defineProps<{
        isLoading?: boolean,
        hasDropShadow?: boolean,
        ownershipMismatch?: boolean,
        isOrderingAllowed?: boolean,
        currentMatterId?: string,
        isCollapsed?: boolean,
    }>(), {
        isLoading: true,
    })

    const route = useRoute()

    const {
        selectedTitle,
        selectedTitleNumber,
        isSelectedTitleLoaded,
        isRegisterPurchased,
        isRegisterUploaded,
        isRegisterOrdering,
        isSelectedTitleInCurrentMatter,
        isRegisterViewable,
        registerResponseDate,
        titleAddress,
        isRegisterAvailable,
    } = useSelectedTitle()

    const { actions } = titlePanelProvider()

    const toggleTitlePanelWidthButton = computed( () => checkFlag('toggle-title-panel-width', false))
    const isTitlePanelWidthButtonDisabled = ref(false)
    const store = useStore()
    const isPanelCollapsed = computed({
        get: () =>  store.state.title.collapsePanel,
        set: (value: boolean) => store.state.title.collapsePanel = value,
    })

    const hasCopilotRole = computed(() => store.state.user?.roles?.includes(ApplicationRoles.Copilot) ?? false)

    const toggleButtonRef = ref<HTMLElement | null>(null)
    const onToggleCollapsed = () => {
        emit('toggle-collapsed')
        isPanelCollapsed.value = false

        // unfocus the button to prevent the focus trap
        toggleButtonRef.value?.$el.blur()
    }

    const onActionClick = (action: ISideNavAction) => {
        if (action.onClick) {
            action.onClick()
        }
    }

    watch(() => route?.name, () => {
        switch (route?.name) {
            case Route.MatterMap:
            case Route.MatterMapTitle:
                isTitlePanelWidthButtonDisabled.value = false
                break
            default:
                isTitlePanelWidthButtonDisabled.value = true
        }
    }, {
        immediate: true,
    })

    const emit = defineEmits<{
        (e: 'close-panel', value: boolean): void
        (e: 'collapse-panel'): void
        (e: 'pin', value: boolean): void
        (e: 'open-register', value: boolean): void
        (e: 'toggle-collapsed'): void
    }>()
</script>

<style lang="scss" scoped>
    @import './title-panel-header';
</style>
