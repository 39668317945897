/**
 * Represents the sub-type of notification.
 *
 * @enum {string}
 */
export enum NotificationSubType {
    Unknown = -1,
    NewDocumentInOCDA = 0,
    CompletedPendingApplication = 1,
    OwnershipChange = 2,
    EditionDateDoesntMatchOcdaResponse = 3,
    BoundaryChange = 4,
    CompaniesHouse = 5,
    Epc = 6,
}
