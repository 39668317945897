import {
    dedupOther,
    getTitleNumbersAndTenuresForVectorTile,
} from '@/store/modules/map/layers/hmlr'
import {
    MAP_ADD_MARKER,
    MAP_HIGHWAYS_SET_OVER_MAINTENANCE_FEATURES,
    MAP_HIGHWAYS_SET_OVER_PROW_FEATURES,
    MAP_MUTATE_OVER_TITLE_NUMBERS,
    MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS,
    MAP_REMOVE_ALL_MARKERS,
    MAP_VALIDATE_NPS_DISPLAY,
} from '@/store/modules/map/types'
import {
    MATTER_HAS_TITLE_NUMBER,
    MATTER_REMOVE_HIGHLIGHT_BOUNDARY,
} from '@/store/modules/matter/types'
import {
    TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
    TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_LOOKUP_TITLE,
    TITLE_MUTATE_CLEAR_TITLES,
    TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
    TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
    TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    TITLE_SET_TITLES,
} from '@/store/modules/titles/types'
import { LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
import { layerEquals } from '@/utils/map-utils'

import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '../../link-share-client/types'

export type TitleObject = {
    titleNumber: string
    tenure: string
}

/**
 * Initialise all the events on the map (single click, pointer move, pointer drag, ...)
 * @param context - state context
 */
export const initialiseMapEvents = async ({ commit, dispatch, rootState, state, rootGetters }) => {
    state.map.on('singleclick', async (e: any) => {
        if (!state.nps.allowLayerClick) {
            return
        }

        await dispatch(MAP_REMOVE_ALL_MARKERS)
        await dispatch(MATTER_REMOVE_HIGHLIGHT_BOUNDARY)

        const pixel = state.map.getEventPixel(e.originalEvent)
        let titleObjects: TitleObject[] = []

        state.map.forEachFeatureAtPixel(pixel, (feature: any, layer: any) => {
            titleObjects.push(...getTitleNumbersAndTenuresForVectorTile(feature, state, layer))
            commit(MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS, titleObjects.map(t => t.titleNumber))
        })
        titleObjects = dedupOther(titleObjects)

        // Want to add a marker to the map if more than 1 title in the selection
        if (titleObjects.length > 1) {
            await dispatch(MAP_ADD_MARKER, { coords: e.coordinate, name: 'title-marker' })
            await dispatch(TITLE_SET_TITLES, titleObjects)
            state.nps.layer.changed()
            commit(TITLE_MUTATE_EXPANDED_TITLE_NUMBER, null)
            commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, null)
            await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'map-titles-click',
                description: titleObjects.length,
            }, { root: true })
        } else if (titleObjects.length === 1) {
            commit(TITLE_MUTATE_CLEAR_TITLES)
            await dispatch(TITLE_LOOKUP_TITLE, titleObjects[0].titleNumber)

            if (rootState.linkShareClient.isMatterLinkShareUser) {
                // if link share user, then want to open the title summary panel
                await dispatch(TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER, titleObjects[0].titleNumber)
            } else {
                commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, titleObjects[0].titleNumber)
            }

            if (rootGetters[MATTER_HAS_TITLE_NUMBER](titleObjects[0].titleNumber)) {
                commit(TITLE_MUTATE_EXPANDED_TITLE_NUMBER, titleObjects[0].titleNumber)
            }

            await dispatch(TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL)
            await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'map-title-click',
                description: titleObjects[0].titleNumber,
            }, { root: true })
        }
    })

    state.map.on('pointermove', async (e: any) => {
        const clickableFeatures = []
        const pixel = state.map.getEventPixel(e.originalEvent)
        let relocateOverlay = false

        // NPS Layer roll-over
        const newOverNPSFeatureIds = []
        if (state.nps.layer.getVisible()) {
            const overNPSTitles = {
                freehold: [],
                leasehold: [],
                other: [],
            }
            const overTitleNumbers = []
            const overFeatures = []

            state.map.forEachFeatureAtPixel(pixel, (feature: any) => {
                newOverNPSFeatureIds.push(feature.get('ogc_fid'))

                overFeatures.push(feature)
            }, {
                layerFilter: currentLayer => layerEquals(state.nps.layer, currentLayer),
            })

            // If mousing over the same polygons, don't update anything, just reposition the pop-up
            let evaluateNPSRollOver = true
            if (newOverNPSFeatureIds.sort().join(',') === state.nps.overIds.sort().join(',')) {
                relocateOverlay = true
                evaluateNPSRollOver = false
            }

            // For each NPS feature rolled-over
            if (evaluateNPSRollOver) {
                overFeatures.forEach((feature) => {
                    clickableFeatures.push(feature)
                    feature.get('freehold')?.split(',').forEach((t, index) => { // eslint-disable-line no-unused-expressions
                        overNPSTitles.freehold.push({
                            titleNumber: t,
                            tenure: feature.get('tenure').split(',')[index],
                        })
                        overTitleNumbers.push(t)
                    })
                    overNPSTitles.freehold = overNPSTitles.freehold
                        .filter((v, i, a) => a.findIndex(t => (t.titleNumber === v.titleNumber)) === i)
                    feature.get('leasehold')?.split(',').forEach((t, index) => { // eslint-disable-line no-unused-expressions
                        overNPSTitles.leasehold.push({
                            titleNumber: t,
                            tenure: feature.get('tenure').split(',')[index],
                        })
                        overTitleNumbers.push(t)
                    })
                    overNPSTitles.leasehold = overNPSTitles.leasehold
                        .filter((v, i, a) => a.findIndex(t => (t.titleNumber === v.titleNumber)) === i)
                    feature.get('other')?.split(',').forEach((t, index) => { // eslint-disable-line no-unused-expressions
                        overNPSTitles.other.push({
                            titleNumber: t,
                            tenure: feature.get('tenure').split(',')[index],
                        })
                        overTitleNumbers.push(t)
                    })
                    overNPSTitles.other = overNPSTitles.other.filter((v, i, a) => a.findIndex(t => (t.titleNumber === v.titleNumber)) === i)
                })

                if (overTitleNumbers.length > 0) {
                    state.nps.overIds = newOverNPSFeatureIds
                    commit('_setOverTitles', overNPSTitles)
                    commit(MAP_MUTATE_OVER_TITLE_NUMBERS, overTitleNumbers)
                    relocateOverlay = true
                } else {
                    commit('_setOverTitles', {
                        freehold: [],
                        leasehold: [],
                        other: [],
                    })
                    state.nps.overIds = []
                    commit(MAP_MUTATE_OVER_TITLE_NUMBERS, [])
                }
            }
        }

        // Street layer roll-over
        if (state.dataLayers.highways.highwayMaintenanceLayer.getVisible() ||
            state.dataLayers.highways.publicRightsOfWayLayer.getVisible()
        ) {
            const overHighwaysPRoWFeatures = []
            const overMaintenanceFeatures = []

            state.map.forEachFeatureAtPixel(pixel, function(feature, layer) {
                if (layer === state.dataLayers.highways.highwayMaintenanceLayer) {
                    overMaintenanceFeatures.push(feature)
                } else if (layer === state.dataLayers.highways.publicRightsOfWayLayer) {
                    overHighwaysPRoWFeatures.push(feature)
                }
            }, {
                layerFilter: currentLayer => layerEquals(state.dataLayers.highways.highwayMaintenanceLayer || state.dataLayers.highways.publicRightsOfWayLayer, currentLayer),
                hitTolerance: 3,
            })
            if (overMaintenanceFeatures.length > 0 || overHighwaysPRoWFeatures.length > 0) {
                relocateOverlay = true
            }

            await dispatch(MAP_HIGHWAYS_SET_OVER_PROW_FEATURES, overHighwaysPRoWFeatures)
            await dispatch(MAP_HIGHWAYS_SET_OVER_MAINTENANCE_FEATURES, overMaintenanceFeatures)
        }

        const cnTitleNos = []
        if (state.dataLayers.unregisteredLand.unregisteredLandLayer.getVisible()) {
            state.map.forEachFeatureAtPixel(pixel, (feature: any) => {
                const cnProperty = feature.get('title_no')
                if (cnProperty) cnTitleNos.push(cnProperty)
            }, {
                layerFilter: currentLayer => layerEquals(state.dataLayers.unregisteredLand.unregisteredLandLayer, currentLayer),
            })
        }

        if (state.dataLayers.listedBuildings.overListedBuildingsText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.listedBuildings.event
        }

        if (state.dataLayers.conservationAreas.overConservationAreasText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.conservationAreas.event
        }

        if (state.dataLayers.scheduledMonuments.overScheduledMonumentsText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.scheduledMonuments.event
        }

        if (state.dataLayers.dno.overDNOText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.dno.event
        }

        if (state.dataLayers.floodZone3.overFloodZone3Text.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.floodZone3.event
        }

        if (state.dataLayers.floodZone2.overFloodZone2Text.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.floodZone2.event
        }

        if (state.dataLayers.floodRisksSeaAndRivers.overFloodRisksSeaAndRiversText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.floodRisksSeaAndRivers.event
        }

        if (state.dataLayers.floodRisksSurfaceWater.overFloodRisksSurfaceWaterText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.floodRisksSurfaceWater.event
        }

        if (state.dataLayers.aonb.overAonbFeatures?.text.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.aonb.event
        }

        if (state.dataLayers.commonLand.overCommonLandText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.commonLand.event
        }

        if (state.dataLayers.ancientWoodland.overAncientWoodlandText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.ancientWoodland.event
        }

        if (state.dataLayers.sssi.overSssiText.length > 0) {
            relocateOverlay = true
            e = state.dataLayers.sssi.event
        }

        if (rootState.sketches.highlightedSketch && !rootGetters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]) {
            relocateOverlay = true
        }

        // Show pointer cursor? - this should be moved to each layer.
        if (newOverNPSFeatureIds.length > 0) {
            state.map.getTargetElement().classList.add('ow-over-layer-nps')
        } else {
            state.map.getTargetElement().classList.remove('ow-over-layer-nps')
        }

        if (cnTitleNos.length > 0) {
            state.map.getTargetElement().classList.add('ow-over-layer-unreg-land')
        } else {
            state.map.getTargetElement().classList.remove('ow-over-layer-unreg-land')
        }

        // Reposition overlay based on new location?
        if (relocateOverlay) {
            // Show to left or right of cursor?
            const newPositionCoordinate = state.map.getEventCoordinate(e.originalEvent)
            const newPositionPixel = state.map.getPixelFromCoordinate(newPositionCoordinate)
            if (newPositionPixel[0] + 400 > state.map.getViewport().clientWidth) {
                state.mapOverlay.setPositioning('top-right')
            } else {
                state.mapOverlay.setPositioning('top-left')
            }
            state.mapOverlay.setPosition(newPositionCoordinate)
        }
    })

    // Map event listeners
    // events
    state.map.on('pointerdrag', () => {
        commit('_setCurrentMapCentre', state.map.getView().getCenter())
    })

    state.map.on('movestart', () => {
        commit('_setMapIsMoving', true)
    })

    state.map.on('moveend', async () => {
        // zoom level changed? take the opportunity to clear vector tile caches
        if (Math.round(state.map.getView().getZoom()) != Math.round(state.currentZoomLevel)) {
            state.map.getLayers()
                .getArray()
                .filter(layer => typeof layer.getSource === 'function')
                .filter(layer => layer.getSource()?.tileClass?.name === 'VectorTile')
                .forEach(layer => {
                    layer.getSource().tileCache?.clear() // eslint-disable-line no-unused-expressions
                    layer.getSource().sourceTileCache?.clear() // eslint-disable-line no-unused-expressions
                })
        }

        state.viewStoppedMovingAt = new Date()
        commit('_setMapIsMoving', false)
        commit('_setCurrentZoomLevel', state.map.getView().getZoom())
        commit('_setCurrentMapCentre', state.map.getView().getCenter())
        await dispatch(MAP_VALIDATE_NPS_DISPLAY)
    })

    // Remove moused-over properties on mouse-out of the map
    state.map.getViewport().addEventListener('mouseout', async () => {
        await dispatch(MAP_HIGHWAYS_SET_OVER_PROW_FEATURES, [])
        await dispatch(MAP_HIGHWAYS_SET_OVER_MAINTENANCE_FEATURES, [])
        commit('_setOverTitles', {
            freehold: [],
            leasehold: [],
            other: [],
        })
        commit(MAP_MUTATE_OVER_TITLE_NUMBERS, [])
        state.nps.overIds = []
        await dispatch('_updateNPSStyle')
    }, false)

    // Input event listeners
    document.body.onmousedown = () => {
        state.mouseIsDown = true
    }

    document.body.onmouseup = () => {
        state.mouseIsDown = false
        rootState.diagnostics.lastClicked = new Date()
    }
}
