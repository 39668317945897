<template>
    <base-row-item :model="baseRowModel"
                   v-bind="props"
                   @view-title="viewTitleClickHandler">
        <template #actions>
            <ow-button is-secondary
                       is-small
                       @click="viewTitleClickHandler">
                {{ t('action.viewTitle') }}
            </ow-button>
        </template>
        <template #extra-info>
            <base-sub-type-table v-model:update-count="numberOfUpdates"
                                 v-model="expanded"
                                 data-test="companies-house"
                                 data-track-type="Companies House Updated"
                                 :headings="headings"
                                 :notification="item?.notificationDetails?.[0]" />
        </template>
    </base-row-item>
</template>

<script setup lang="ts" generic="T">
    import {
        computed,
        ref,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"

    import { IAssetMonitoringNotification } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import {
        AssetMonitoringDataSource,
        BaseRowItemModel,
    } from "@/components/asset-monitoring/base-row-item-model"
    import BaseRowItem from "@/components/asset-monitoring/grid/row-items/base-row-item.vue"
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import { Route } from "@/enums/route.enum"
    import { checkFlag } from '@/feature-flags'

    const { t } = useI18n()
    const router = useRouter()

    const props = defineProps<{
        item: IAssetMonitoringNotification,
        containerSelector: string,
        matterId: number,
    }>()

    const expanded = defineModel({
        default: true,
    })

    const emit = defineEmits<{
        (e: 'view-title', {
            titleNumber,
            notificationSubType,
        }: {
            titleNumber: string
            notificationSubType: NotificationSubType
        }),
    }>()

    const numberOfUpdates = ref<number>(0)

    const viewTitleClickHandler = async (): Promise<void> => {
        if (checkFlag('title-panel-v-2', false)) {
            emit('view-title', {
                titleNumber: props.item.titleNumber,
                notificationSubType: NotificationSubType.CompaniesHouse,
            })
        } else {
            await router.push({
                name: Route.MatterMapTitle,
                params: {
                    titleNumber: props.item.titleNumber,
                    matterId: props.matterId,
                },
            })
        }
    }

    const baseRowModel = computed((): BaseRowItemModel => {
        return {
            title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
            descriptionPath: 'assetMonitoring.subType.companiesHouseUpdated.description',
            dataSource: AssetMonitoringDataSource.companiesHouse,
            titleNumber: props.item.titleNumber,
        }
    })

    const headings = computed<IBaseSubTypeTableColumn<T>[]>(() => ([
        {
            field: 'companyName',
            title: t('assetMonitoring.dataGrid.ownership.name') ,
            dataTest: 'companyName',
        },
        {
            field: 'displayAddress',
            title: t('assetMonitoring.dataGrid.ownership.address'),
            dataTest: 'displayAddress',
        },
        {
            field: 'companyRegistrationNumber',
            title: t('assetMonitoring.dataGrid.ownership.number'),
            dataTest: 'companyRegistrationNumber',
        },
        {
            field: 'companyStatus',
            title: t('assetMonitoring.dataGrid.ownership.status'),
            dataTest: 'companyStatus',
        },
        {
            field: 'displayNatureOfBusiness',
            title: t('assetMonitoring.dataGrid.ownership.natureOfBusiness'),
            dataTest: 'displayNatureOfBusiness',
        },
    ]))

</script>

<style scoped lang="scss">

</style>
