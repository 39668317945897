<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.preferences.cards.presets.cardTitle')"
                                     :card-type-description="t('assetMonitoring.preferences.cards.presets.cardDescription')"
                                     class="preference-presets-card">
        <ul class="preference-presets-card__options">
            <li :class="{'is-selected': isOwRecommendedSelected}"
                @click="setOwRecommended">
                <h4 class="caption-highlight">
                    {{ t(`assetMonitoring.preferences.cards.presets.basicAlerts`) }}
                </h4>
                <p class="caption-regular">
                    {{ t(`assetMonitoring.preferences.cards.presets.basicCompanyAlertsDescription`) }}
                </p>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { ICompanyNotificationSettings } from "@/api/company-notifications"
    import BaseMonitoringPreferenceCard from "@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue"


    const { t } = useI18n()
    const emit = defineEmits<{
        (event: 'update', value: ICompanyNotificationSettings): void
    }>()

    const props = defineProps<{
        settings: ICompanyNotificationSettings,
    }>()

    // OW Recommended card
    const isOwRecommendedSelected = computed<boolean>(() => {
        return props.settings.trackCompaniesHouseInformation && !props.settings.trackCompaniesHouseInsolvency && !props.settings.trackCompanyTitleOwnership
    })
    const setOwRecommended = () => {
        const prefs: ICompanyNotificationSettings = {
            trackCompaniesHouseInformation: true,
            trackCompaniesHouseInsolvency: false,
            trackCompanyTitleOwnership: false,
        }
        emit('update', prefs)
    }

</script>

<style lang="scss" scoped>
    @import './preference-presets-card';
</style>
