<template>
    <div class="matter-create-sharing mb-3">
        <permissions v-model:share-type="formData.shareType"
                     v-model:selected="formData.selectedUsers"
                     :current-user-id="currentUser.id"
                     is-new-matter
                     :loading="isCreateAndShareSubmitting"
                     :permissions="permissions"
                     hide-actions
                     data-test="matter-permissions" />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
    } from "vue"
    import { useStore } from "vuex"

    import OrganisationApi from "@/api/organisations.api"
    import { IWizardStep } from "@/components/core/ow-wizard"
    import {
        IMatterCreateSharingStepData,
    } from "@/components/matter/create/types"
    import Permissions from "@/components/matter/settings/permissions.vue"
    import { MatterShareType } from "@/enums/matter-share-type.enum"

    const props = defineProps<{
        step: IWizardStep<IMatterCreateSharingStepData>,
    }>()

    const store = useStore()

    const currentUser = computed(() => store.state.user)
    const isCreateAndShareSubmitting = ref()
    const permissions = ref()

    const formData = computed({
        get: () => props.step.data,
        set: (value: IMatterCreateSharingStepData) => {
            props.step.data = value
        },
    })

    const nextButtonDisabled = computed(() => {
        return formData.value.shareType === MatterShareType.Specific && !formData.value.selectedUsers.length
    })

    const getPermissions = async () => {
        const response = await OrganisationApi.getOrganisationUserListSummary()
        if (response.ok) {
            const users = response.data.data
            permissions.value = users
                .filter(user => user.isActive === true)
                .map(user => {
                    return {
                        userId: user.id,
                        userName: `${ user.firstName } ${ user.lastName }`,
                        isOwner: false,
                        shared: false,
                    }
                })
                .sort((a, b) => a.userName.localeCompare(b.userName))
        }
    }

    onBeforeMount(() => {
        getPermissions()

        formData.value = {
            shareType: MatterShareType.Specific,
            selectedUsers: [],
        }

        props.step.nextButtonIsDisabled = nextButtonDisabled
    })

</script>

<style scoped>

</style>