<template>
    <div :class="['ow-toggle-card', {
        'ow-toggle-card__disabled': disabled,
        'ow-toggle-card__preferences': theme === 'preferences',
    }]">
        <div class="ow-toggle-card__content"
             :class="{
                 '--is-selected': modelValue,
                 '--no-border': noBorder
             }">
            <div class="ow-toggle-card__content--data">
                <h4 class="caption-highlight">
                    <span v-dompurify-html="title" />
                    <span v-if="isNew"
                          class="label-caps-smallest ow-toggle-card--is-new">
                        {{ t('label.new') }}
                    </span>
                </h4>
                <p v-if="description"
                   v-dompurify-html="description"
                   class="caption-regular" />
                <slot />
            </div>
            <ow-toggle v-model="modelValue"
                       :data-track="`Asset monitoring: Toggle ${title}`"
                       :data-test="`toggle ${title}`"
                       class="ow-toggle-card__toggle px-3"
                       :disabled="disabled" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted } from "vue"
    import { useI18n } from 'vue-i18n'

    import OwToggle from '@/components/core/ow-toggle.vue'

    const { t } = useI18n()

    const props = defineProps<{
        title: string,
        description?: string,
        disabled?: boolean
        selected?: boolean
        isNew?: boolean
        noBorder?: boolean
        theme?: 'preferences'
    }>()

    const modelValue = defineModel()

    onMounted(() => {
        if (props.selected) {
            modelValue.value = props.selected
        }
    })
</script>

<style lang="scss" scoped>
@import './ow-toggle-card';
</style>
