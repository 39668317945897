
<template>
    <component :is="nearbyComponent"
               v-if="isActive"
               v-model="showLock"
               :feature-id="FeatureId.Nearby"
               :selected-title-numbers="[props.selectedTitleNumber]"
               :current-matter-id="props.matterId"
               :tab-controller="props.tabController"
               :target-map="props.targetMap" />
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
        watch,
    } from "vue"
    import {useStore} from "vuex"

    import OwFullPageLock from "@/components/core/ow-full-page-lock.vue"
    import NearbyContainer from "@/components/find-nearby/find-nearby-container.vue"
    import NearbyContainerWithMap from "@/components/title-panel/v2/find-nearby/find-nearby-container-with-map.vue"
    import { FeatureId } from '@/composables/use-licence-controller'
    import {inject as titlePanelProvider} from "@/composables/use-title-panel"
    import {inject as userProvider} from "@/composables/use-user"
    import {TitlePanelTabName} from "@/enums/title-panel-tab-name"
    import {USER_MUTATE_SHOW_MAP_TITLES_NAV} from "@/store/mutation-types"

    const props = defineProps<{
        matterId: number,
        tabController: any,
        selectedTitleNumber: string,
        targetMap: any
    }>()
    const { titlePanelFullScreen, activeTabId } = titlePanelProvider()
    const { hasAccessToFindNearby } = userProvider()
    const store = useStore()

    const showLock = ref<boolean>(true)
    const nearbyComponent = computed(() => {
        return showLock.value? OwFullPageLock : titlePanelFullScreen.value ? NearbyContainerWithMap : NearbyContainer
    })
    const isActive = computed((): boolean => {
        return activeTabId.value === TitlePanelTabName.FindNearby
    })
    const showingSidescreen = computed<boolean>((): boolean => {
        return isActive.value && !titlePanelFullScreen.value
    })

    watch(()=> showingSidescreen.value, (val) => {
        if (val) {
            store.commit(USER_MUTATE_SHOW_MAP_TITLES_NAV, false)
        } else {
            // NOTE: Edge case when going between nearby and planning as both need to be collapsed
            if (activeTabId.value !== TitlePanelTabName.Planning) {
                store.commit(USER_MUTATE_SHOW_MAP_TITLES_NAV, true)
            }
        }
    }, { immediate: true })

    onBeforeMount(async () => {
        const hasAccess = await hasAccessToFindNearby()
        showLock.value = !hasAccess
    })
</script>

<style scoped lang="scss">

</style>
