<template>
    <div class="base-row">
        <div class="d-flex justify-space-between align-center w-100">
            <div class="d-flex justify-space-between align-center w-100">
                <span class="caption-highlight ml-4">{{ title }}</span>

                <div class="d-flex justify-space-between align-center">
                    <i v-if="showRequiredInvestigationTooltip"
                       aria-hidden="true"
                       class="material-icons mr-1">
                        info_outline
                        <ow-tooltip :position="OwTooltipPosition.Top"
                                    activator="parent">
                            <span> {{ t('documents.ordering.requiresInvestigation') }} </span>
                        </ow-tooltip>
                    </i>
                    <ow-document-availability-label :hmlr-availability-code="documentAvailabilityCode"
                                                    :is-loading="documentAvailabilityLoading"
                                                    :is-ordered="false"
                                                    class="mr-4" />
                </div>
            </div>
            <v-divider vertical />
            <order-register-button :current-matter-id="currentMatterId"
                                   :is-ordering-allowed="isOrderingAllowed"
                                   :order-with-checkout="orderWithCheckout"
                                   :selected-title-number="selectedTitleNumber"
                                   button-class="base-row__action"
                                   button-data-test-attribute="order-register-button"
                                   full-height
                                   hide-back-dated-states />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import {useI18n} from "vue-i18n"

    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import OrderRegisterButton from '@/components/title-panel/order-register-button.vue'
    import {HmlrDocumentAvailabilityCode} from '@/consts/document-availability'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"

    const { t } = useI18n()

    const props = defineProps<{
        title: string
        documentAvailabilityLoading?: boolean
        currentMatterId?: number
        selectedTitleNumber?: string
        selectedTitle?: any
        orderWithCheckout: boolean
        isOrderingAllowed: boolean
    }>()

    const registerAvailability = computed(() => props.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const documentAvailabilityCode = computed(() => {
        if (props?.selectedTitle?.record?.source === DOCUMENT_SOURCE.SCOTLAND) {
            return HmlrDocumentAvailabilityCode.Available
        }
        return registerAvailability.value?.hmlrAvailabilityCode ? registerAvailability.value?.hmlrAvailabilityCode : null
    })
    const showRequiredInvestigationTooltip = computed(() => documentAvailabilityCode.value === HmlrDocumentAvailabilityCode.RequiresInvestigation)
</script>

<style scoped lang="scss">
@import './base-row.scss';
</style>
