export const HmlrDocumentAvailabilityCode = {
    // Returned by HMLR OCDA API.
    Available: 'IMMEDIATE',
    NotImmediatelyAvailable: 'MANUAL',
    Unavailable: 'UNAVAILABLE',
    RequiresInvestigation: 'REQUIRES_INVESTIGATION',
    Unknown: null,
}

export const HmlDocumentAvailabilityTextForCode = {
    [HmlrDocumentAvailabilityCode.Available]: 'Available',
    [HmlrDocumentAvailabilityCode.NotImmediatelyAvailable]: 'Post / Digital',
    [HmlrDocumentAvailabilityCode.Unavailable]: 'Unavailable',
    [HmlrDocumentAvailabilityCode.RequiresInvestigation]: 'Unavailable',
    [HmlrDocumentAvailabilityCode.Unknown]: 'Unknown',
}
