<template>
    <core-map v-if="isLoaded && titleNumber"
              :initial-view="initialView"
              :interactions="interactions"
              :layer-attribution="layerAttribution"
              :layers="layers"
              :name="name"
              :show-layer-attribution="showLayerAttribution"
              :show-map-scale="false"
              :show-watermark="true"
              data-test="ow-planning-map"
              @loaded="$emit('loaded')"
              @loading="$emit('loading')"
              @map-initialised="handleMapInitialised" />
    <ow-title-boundary-map-loading-skeleton v-else />
</template>

<script lang="ts" setup>
    import Map from 'ol/Map'
    import {
        computed,
        onBeforeUnmount,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import {MatterBoundariesLayer} from "@/components/core/maps/matter-boundaries-layer"
    import OwTitleBoundaryMapLoadingSkeleton from '@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue'
    import {
        BASE_LAYERS,
        MAX_ZOOM,
        MIN_ZOOM,
    } from '@/consts/map'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import {PLANNING_MUTATE_DISTANCE_LAYER,
            PLANNING_V2_SEARCH_BY_TITLE_NUMBERS} from "@/store/modules/planning/types"
    import { isNullOrEmpty } from '@/utils/array-utils'

    import CoreMap from './core-map.vue'

    const props = defineProps({
        layerAttribution: {
            type: String,
            required: false,
        },
        enableMapInteractions: {
            type: Boolean,
            default: false,
        },
        baseLayer: {
            type: String,
            default: BASE_LAYERS.OS_MAP,
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: 'planning-map',
        },
        titleNumber: {
            type: String,
            required: true,
        },

        /**
         * Used for previewing maps where screen space is limited.
         */
        showLayerAttribution: {
            type: Boolean,
            required: false,
            default: true,
        },
    })

    const emit = defineEmits<{
        (e: 'loaded'),
        (e: 'loading'),
    }>()

    const store = useStore()
    const baseTileLayer = ref<any>(null)
    const map = ref<Map | null>(null) //null until initialised
    let matterTitlesLayer = null as MatterBoundariesLayer

    const npsLayerName = computed(() => store.state.config.settings.npsLayer)
    const interactions = computed((): CoreMapInteractions => {
        if (props.enableMapInteractions) {
            return {
                doubleClickZoom: true,
                dragPan: true,
                keyboard: true,
                mouseWheelZoom: true,
            }
        }
        return {}
    })

    const initialView = computed((): CoreMapView => {
        return {
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            paddingWithinMap: [ 24, 24, 24, 24 ],
        }
    })

    const layers = ref<Array<any>>([])
    const layersSet = computed((): Boolean => {
        return !isNullOrEmpty(layers.value)
    })

    const setLayers = () => {
        baseTileLayer.value = new OsImageTileLayer({
            layerName: props.baseLayer,
            getTargetMapFn: () => undefined,
        }).getLayer()

        layers.value = [
            baseTileLayer.value,
        ]
    }

    watch(() => props.baseLayer, (): void => {
        setLayers()
    })

    onMounted(() => {
        setLayers()
    })

    const isLoaded = computed((): Boolean => {
        return layersSet.value
    })

    const handleMapInitialised = async (mapObj: Map): Promise<void> => {
        if (!map.value) {
            map.value = mapObj
            matterTitlesLayer = new MatterBoundariesLayer({
                targetMap: map.value,
            })
            matterTitlesLayer.setVisible(true)
            await matterTitlesLayer.setTitleNumberBoundaries([props.titleNumber], npsLayerName.value)
            await store.dispatch(PLANNING_V2_SEARCH_BY_TITLE_NUMBERS, {titleNumbers: [props.titleNumber], map: mapObj})
        }
    }

    onBeforeUnmount((): void => {
        store.commit(PLANNING_MUTATE_DISTANCE_LAYER, null)
        matterTitlesLayer?.dispose()
    })
</script>
