<template>
    <div class="planning-container"
         data-test="planning-container">
        <ow-card :subtitle="planningData ? subtitle : null"
                 class="planning-actions"
                 title="Planning applications">
            <!-- Rate limited -->
            <div class="planning-container__planning-view">
                <v-btn v-if="promptForRetry"
                       :disabled="isRateLimited"
                       class="planning-container__retry-button"
                       color="primary"
                       data-test="planning-retry-button"
                       data-track="planning-retry-button"
                       @click="onRetryClick">
                    Retry
                </v-btn>

                <!-- Not rate limited -->
                <div v-else>
                    <ow-button v-if="!loadingResults"
                               :disabled="disableExport"
                               data-test="planning-actions-export-as-csv"
                               data-track="planning-actions-export-as-csv"
                               class="mx-2 my-1 float-right"
                               @click="exportAsCSV">
                        {{ $t('action.exportToExcel') }}
                    </ow-button>
                    <planning-actions v-if="!loadingResults"
                                      :show-advanced-options="showAdvancedOptions" />

                    <v-progress-linear v-if="loadingResults"
                                       :indeterminate="true"
                                       color="primary" />

                    <planning-results v-if="!loadingResults"
                                      :scroll-element-container="scrollElementContainer" />
                </div>
            </div>
        </ow-card>
    </div>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwCard from '@/components/core/ow-card.vue'
    import PlanningActions from '@/components/title-panel/v2/planning/planning-actions.vue'
    import PlanningResults from '@/components/title-panel/v2/planning/planning-results.vue'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        PLANNING_EXPORT_CSV,
        PLANNING_MUTATE_DISTANCE,
        PLANNING_MUTATE_FORCE_PLANNING_PROVIDER,
        PLANNING_RETRY_CURRENT_SEARCH,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'
    import {
        LOGGING_LOG_FEATURE_USAGE,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'

    export default {
        name: 'PlanningContainer',

        components: {
            OwButton,
            PlanningActions,
            PlanningResults,
            OwCard,
        },

        mixins: [flagsMixin],

        props: {
            scrollElementContainer: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                debounceUpdateFilteredResults: debounce(() => {
                    this.updateFilteredResults()
                }, 20),
                debounceRefreshMapLayer: debounce(() => {
                    this.refreshMapLayer()
                }, 20),
            }
        },

        computed: {

            ...mapState({
                selectedDistance: state => state.planning.inputs.selectedDistance,
                selectedDateRange: state => state.planning.inputs.selectedDateRange,
                selectedTitleFeatures: state => state.planning.inputs.selectedTitleFeatures,
                searchedTitleNumbers: state => state.planning.inputs.searchedTitleNumbers,
                loadingResults: state => state.planning.loadingResults,
                textFilter: state => state.planning.inputs.textFilter,
                highlightedFeatures: state => state.planning.highlightedFeatures,
                selectedFeatures: state => state.planning.selectedFeatures,
                mapLayer: state => state.planning.resultsLayer,
                results: state => state.planning.results,
                filteredResultsCount: state => state.planning.filteredResultsCount,
                showAdvancedOptions: state => state.planning.showAdvancedOptions,
                unavailableApplications: state => state.planning.unavailableApplications,
                isRateLimitedRemainingSeconds: state => state.planning.isRateLimitedRemainingSeconds,
                promptForRetry: state => state.planning.promptForRetry,
            }),

            resultsText() {
                let subject = `${ this.searchedTitleNumbers?.length } titles`
                if (this.searchedTitleNumbers?.length === 1) {
                    subject = this.searchedTitleNumbers[0]
                } else if (this.searchedTitleNumbers?.length === 0) {
                    subject = 'your area of interest'
                }

                const oldestAvailablePlanningApplication = this.results[this.results.length - 1]
                const partialResultsMessage = this.unavailableApplications
                    ? `. A further ${ this.unavailableApplications } planning applications prior to ${ oldestAvailablePlanningApplication.getProperties()
                        .receivedDate
                        .substr(0, 10) } are not shown.`
                    : ''

                return `Showing ${ this.filteredResultsCount } results within approximately ${ this.selectedDistance }m of ${ subject }${ partialResultsMessage }`
            },

            isRateLimited() {
                return this.isRateLimitedRemainingSeconds > 0
            },

            subtitle() {
                if (this.loadingResults) {
                    return null
                } else if (this.promptForRetry) {
                    const text = 'We were unable to provide planning application results at this time, please try again'
                    return this.isRateLimitedRemainingSeconds === 0
                        ? `${ text }.`
                        : `${ text } in ${ this.isRateLimitedRemainingSeconds } seconds.`
                } else {
                    return this.resultsText
                }
            },
            disableExport() {
                return this.filteredResultsCount === 0
            },
        },

        watch: {

            selectedTitleFeatures() {
                this.setSelectedDistance(this.selectedDistance)
            },

            selectedDistance() {
                this.debounceUpdateFilteredResults()
            },

            selectedDateRange() {
                this.debounceUpdateFilteredResults()
            },

            textFilter() {
                this.debounceUpdateFilteredResults()
            },

            highlightedFeatures() {
                this.debounceRefreshMapLayer()
            },

            selectedFeatures(val) {
                // More efficient to have one watch than a watch on each result.
                if (val.length > 0) {
                    // Not handling clusters until later PR, select the first one until then.
                    const firstResultItem = val[0]
                    // Scroll first result into view.
                    const resultElement = document.querySelector(`[data-pa-index="${ firstResultItem.getProperties().index }"]`)
                    if (resultElement) {
                        resultElement.scrollIntoView(true)
                    }
                }
            },
        },

        beforeCreate() {
            // To be used for testing purposes, consider removing.
            global.forcePlanningProvider = (provider) => {
                this.setForcePlanningProvider(provider)
            }
        },

        methods: {

            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
                retryCurrentSearch: PLANNING_RETRY_CURRENT_SEARCH,
                exportAsCSV: PLANNING_EXPORT_CSV,
            }),

            ...mapMutations({
                setSelectedDistance: PLANNING_MUTATE_DISTANCE,
                setForcePlanningProvider: PLANNING_MUTATE_FORCE_PLANNING_PROVIDER,
            }),

            refreshMapLayer() {
                // eslint-disable-next-line no-unused-expressions
                this.mapLayer?.getSource().changed()
            },

            onRetryClick() {
                this.retryCurrentSearch()
            },
        },
    }
</script>
<style lang="scss">
    @import 'planning-container.scss';
</style>
