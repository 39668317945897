// Do not reorganise the imports, as it will break the build.
import {
    AUTH_FETCH_USER,
    AUTH_LOGIN,
    AUTH_LOGOUT,
} from '@/store/modules/auth-oidc/types'
import {
    MATTER_GET_CURRENT_MATTER_ID,
    MATTER_GET_IS_CURRENT_MATTER_CLOSED,
    MATTER_MUTATE_RESET_CURRENT_MATTER,
    MATTER_SET_CURRENT_BY_ID,
} from '@/store/modules/matter/types'
import { canUserAccessMatter } from '@/utils/matter-utils'
import i18n from '@/plugins/i18n'
import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
import { Route } from '@/enums/route.enum'
import { routes } from './routes'
import SecurityService from '@/api/security'
import store from '@/store'
import { USER_REQUEST_PROFILE,
    USER_SHOW_POPUP } from '@/store/mutation-types'
import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
} from 'vue-router'
import { ApplicationRoles } from '@/enums/application-roles.enum'

/**
 * Parse a string into an integer
 * @param input
 */
const parseToIntOrNull = (input: any): number | null => {
    return input ? parseInt(input.toString()) : null
}

/**
 * Load the matter. If the current matter is the same as the matter routing to, then do nothing.
 * @param toMatterId
 * @param fromMatterId
 * @param store - reference to the VueX store.
 */
export const loadMatter = async (toMatterId: number, fromMatterId: number, store: any): Promise<void> => {
    if (toMatterId === fromMatterId) {
        return
    }

    const currentMatterId = store.getters[MATTER_GET_CURRENT_MATTER_ID]
    if (toMatterId === null) {
        // NOTE: Fix to not have an empty matter when navigating to the homepage, only happens on large matters that take a while to clear
        setTimeout(() => {
            store.commit(MATTER_MUTATE_RESET_CURRENT_MATTER)
        }, 100)
    } else if (currentMatterId !== toMatterId) {
        await store.dispatch(MATTER_SET_CURRENT_BY_ID, toMatterId)
    }
}

/**
 * Handle authentication and authorisation for transitioning between routes.
 * @param {RouteLocationNormalized} to - The route being navigated to.
 * @param {RouteLocationNormalized} from - The current route.
 * @param store - reference to the VueX store.
 * @returns {Promise<any>} - A Promise that resolves to `true` if the transition is allowed, or `false` otherwise.
 *  If false, then the response can also include a new route to redirect the user to. *
 */
export const handleAuthenticationAndAuthorisation = async (to: RouteLocationNormalized, from: RouteLocationNormalized, store: any): Promise<any> => {
    // If route doesn't require authentication, then go load it!
    // If meta `requiresAuth` property not set, assume it to be true.
    const doesRouteRequireAuth = to?.meta?.requiresAuth ?? true
    if (!doesRouteRequireAuth) {
        return true
    }

    // See if the user is authenticated already and load them into the store.
    await store.dispatch(AUTH_FETCH_USER)
    const isAuthenticated = store.state.authOidc.isAuthenticated
    if (!isAuthenticated) {
        // User might be logged in in another window.
        // Trigger the sign in process to check for a valid user.
        await store.dispatch(AUTH_LOGIN)
    }

    // User is authenticated, so load their profile.
    if (isAuthenticated && !store.state.user.profileLoaded) {
        // Load the user's profile so we know their roles.
        await store.dispatch(USER_REQUEST_PROFILE)
    }
    const userProfile = store.state.user

    // Check roles to see if user is authorised to access this application
    if (!userProfile.roles?.includes(ApplicationRoles.OwApp) &&
        !userProfile.roles?.includes(ApplicationRoles.MatterLinkShareUser)) {
        if (to.name !== Route.Unauthorised) {
            // Redirect to the unauthorised page, which will trigger this again, hence the else!
            return {
                name: Route.Unauthorised,
            }
        } else {
            // Already going to the Authorised page, so go there
            return true
        }
    }

    // Is this a matter share link?
    const isLinkShareUser = store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]

    let toMatterId = parseToIntOrNull(to.params?.matterId)
    if(!toMatterId) {
        // MatterId could also be in the querystring for pages that can be viewed outside a matter (doc viewer, full page title details)
        toMatterId = parseToIntOrNull(to.query?.fromMatterId)
    }
    const fromMatterId = from.params?.matterId ? parseInt(from.params.matterId.toString()) : null
    await loadMatter(toMatterId, fromMatterId, store)
    const currentMatter = store.state.matter?.currentMatter

    // Deal with link share users
    if (isLinkShareUser) {
        // If navigating to the application root while logged in as a link share user,
        // assume we want to prompt them to log in.
        if (from?.redirectedFrom?.path === '/') {
            await SecurityService.signOutRedirect()
            await store.dispatch(AUTH_LOGIN)
            return false
        }

        // Are link share users allowed to view this route?
        if (!to.meta?.allowLinkShareClient) {
            // If there is no matter/link share id, then return unauthorised.
            if (!currentMatter?.linkShare?.linkGuid) {
                await store.dispatch(AUTH_LOGOUT)
                await store.dispatch(AUTH_LOGIN)
                return {
                    name: Route.Unauthorised,
                }
            }

            // If the current matter has a link share ID, then log the user out and redirect to the link share landing page.
            return {
                name: Route.LinkShareLanding,
                params: {
                    linkGuid: currentMatter.linkShare.linkGuid,
                },
            }
        }
    }

    if (isAuthenticated) {
        // check whether the current user can access the matter, if there is one
        if (Boolean(toMatterId) && toMatterId !== fromMatterId) {
            const canAccessMatter = await canUserAccessMatter(toMatterId)
            if (!canAccessMatter) {
                return { name: Route.MattersList }
            }

            let isCurrentMatterClosed = false
            if (toMatterId) {
                isCurrentMatterClosed = store.getters[MATTER_GET_IS_CURRENT_MATTER_CLOSED]
            }

            // Check whether the current matter is closed and this route is no longer valid
            if (isCurrentMatterClosed && !to.meta?.availableForInactiveMatter) {
                await store.dispatch(USER_SHOW_POPUP, {
                    title: i18n.global.t('matterIsClosed'),
                    contentHTML: i18n.global.t('reopenMatterText'),
                })
                return { name: Route.MattersList }
            }
        }

        if (store.state.core?.preventTransition?.isFormDirty) {
            store.state.core.preventTransition.showDialog = true
            store.state.core.preventTransition.transitionRoute = to
            return false
        }

        // Already logged in, so can navigate anywhere
        return true
    }

    // Shouldn't get here, but just in case prevent the transition.
    return false
}

/**
 * Initialise the router plugin
 */
const router = createRouter({
    history: createWebHistory(),
    routes,
})

/**
 * Implements the global navigation guards for the application.
 */
router.beforeEach(async (to, from) => {
    // Don't need to sign in or do any routing if being controlled by Storybook
    if (window.IS_STORYBOOK) {
        return
    }

    return await handleAuthenticationAndAuthorisation(to, from, store)
})

export default router
