// @/stores/asset-monitoring/actions.ts
import { HttpStatusCode } from 'axios'

import CompanyNotificationsApi, { ICompanyNotificationSettings } from "@/api/company-notifications"
import NotificationsApi, {
    IGetNotificationResponseItem,
    IGetNotificationsRequest,
    INotificationMessageArguments,
    ITitleNotificationSettings,
} from '@/api/notifications.api'
import UserEmailPreferencesApi, { IUserEmailPreferences } from "@/api/user-email-preferences.api"
import { ReadStatus } from "@/components/asset-monitoring/read-status.enum"
import { convertToNotificationSubType } from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'
import { format } from "@/utils/date-utils"

async function getMappedNotifications(matterId: number, options: IGetNotificationsRequest): Promise<[Array<IGetNotificationResponseItem>, number]> {
    const response = await NotificationsApi.getNotificationsByMatter(matterId, options)

    let notifications: Map<string, IGetNotificationResponseItem> = new Map(response.data.userNotifications.map((notification: IGetNotificationResponseItem) => {
        return [
            notification.notificationId,
            notification,
        ]
    }))
    let totalNotifications = response.data.totalResults
    return [Array.from(notifications.values()), totalNotifications]
}

export default {

    getNotificationRequestModel(): IGetNotificationsRequest {
        const { selectedNotificationTypes, selectedNotificationSubTypes, filterText, sortBy, pageSize, pageIndex, selectedCompanies, selectedTitles } = this

        const model = {
            pageIndex,
            pageSize,
            sortBy,
            filters: filterText,
            types: selectedNotificationTypes,
            subTypes: selectedNotificationSubTypes,
            readStatus: this.showOnlyUnread ? ReadStatus[ReadStatus.UNREAD] : null,
            titleNumbers: selectedTitles,
            companyNumbers: selectedCompanies,
        }

        return model
    },

    /**
     * Retrieves notifications for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNotifications(matterId: number): Promise<void> {
        this.isLoading = true

        try {
            let [notifications, totalResults] = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            this.notifications = notifications
            this.totalResults = totalResults
            this.lastCheckedDate = format(new Date(), 'dd-MM-yyyy HH:mm')

            await this.getUnreadNotificationCounts(matterId)
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    /**
     * Retrieves the unread notification counts for a given matter.
     * @param matterId - The ID of the matter to retrieve notifications for.
     */
    async getUnreadNotificationCounts(matterId: number): Promise<void> {
        this.isLoading = true

        try {
            const response = await NotificationsApi.getUnreadNotificationsCount(matterId)
            this.unreadTitleNotifications = response.data.unreadTitleNotifications
            this.unreadCompanyNotifications = response.data.unreadCompanyNotifications
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    /**
     * Retrieves next notifications page for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNextNotificationsPage(matterId: number): Promise<void> {
        try {
            this.currentPageIndex++
            let [notifications, totalResults] = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            this.notifications.push(...notifications)
            this.totalResults = totalResults
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        }
    },

    /**
     * Updates the sort order for the notifications and retrieves the notifications for the specified matter.
     *
     * @param {string} sortBy - The sort order to be applied to the notifications.
     * @param {number} currentMatterId - The ID of the matter for which the notifications should be retrieved.
     *
     * @return {Promise<void>} - A promise that resolves once the sort order is updated and the notifications are retrieved.
     */
    async updateSortBy(sortBy: string, currentMatterId: number): Promise<void> {
        this.sortBy = sortBy
        this.currentPageIndex = 0
        await this.getNotifications(currentMatterId)
    },

    async updateSelectedCompanies(matterId: number, values: string[]): Promise<void> {
        this.selectedCompanies  = values
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    async updateSelectedTitles(matterId: number, values: string[]): Promise<void> {
        this.selectedTitles  = values
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the selected notification subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param subTypes
     */
    async updateSelectedNotificationSubTypes(matterId: number, subTypes: string[]): Promise<void> {
        this.selectedNotificationSubTypes = subTypes
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the notification messages for the current entity type.
     * @param messages - The notification messages to display.
     */
    async updateNotificationMessages(messages: Array<INotificationMessageArguments>): Promise<void> {
        this.notificationMessages = messages
    },

    /**
     * Clears the selected entity values and notification subtypes.
     * Without reloading the notifications.
     */
    clear() {
        this.selectedCompanies = []
        this.selectedTitles = []
        this.selectedNotificationSubTypes = []
        this.currentPageIndex = 0
    },

    /**
     * Adds a received notification to the `notifications` array.
     *
     * @param notification - The notification to be added.
     */
    addReceivedNotification(notification: INotificationMessageArguments) {
        if (isNullOrEmpty(this.notificationMessages)) {
            this.notificationMessages = new Array<INotificationMessageArguments>()
        }
        this.notificationMessages.push({
            ...notification,
            type: convertToNotificationSubType(notification.type.toString()),
        })
    },

    /**
     * Dismisses a single user notification from the list of notifications.
     *
     * @param {number} matterId - The ID of the matter for which to dismiss the notification.
     * @param {string} notificationId - The ID of the notification to dismiss.
     */
    async dismissNotification(matterId: number, notificationId: string): Promise<void> {
        await this.dismissNotifications(matterId, [ notificationId ])
    },

    /**
     * Dismisses multiple user notifications from the list of notifications.
     *
     * @param {number} matterId - The ID of the matter for which to dismiss the notifications.
     * @param {string} notificationIds - The IDs of the notifications to dismiss.
     */
    async dismissNotifications(matterId: number, notificationIds: Array<string>): Promise<void> {
        try {
            const response = await NotificationsApi.dismissNotifications(notificationIds, true)
            if (response.status === HttpStatusCode.Ok) {
                notificationIds.forEach((notificationId: string) => {
                    const notification = this.notifications.find(n => n.notificationId === notificationId)
                    if (notification) {
                        notification.isRead = true
                    }
                })
            }
            await this.getUnreadNotificationCounts(matterId)
        } catch (e) {
            console.error('Error dismissing notifications:', e, notificationIds)
        }
    },

    /**
     * Retrieves the title notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the notification settings.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of notification settings.
     * @throws {Error} - If an error occurs while retrieving the notification settings.
     */
    async getTitleNotificationSettings(matterId: number): Promise<void> {
        try {
            this.isLoadingSettings = true
            const response = await NotificationsApi.getTitleNotificationSettings(matterId)
            this.titleNotificationSettings = {
                ...response.data,
                isActive: Object.values(response.data).some((value: boolean) => value === true),
            }
            this.areTitleNotificationSettingsLoaded = true
        } catch (error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                // If monitoring hasn't been setup for this matter, then get not found back from the API
                this.titleNotificationSettings = {
                    trackDaylist: false,
                    trackOcda: false,
                    trackOwnership: false,
                    trackBoundary: false,
                    trackEpc: false,
                    trackCompaniesHouse: false,
                }
            } else{
                console.error('Error retrieving title notification settings:', error)
            }
        } finally {
            this.isLoadingSettings = false
        }
    },

    /**
     * Retrieves the company notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the notification settings.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of notification settings.
     * @throws {Error} - If an error occurs while retrieving the notification settings.
     */
    async getCompanyNotificationSettings(matterId: number): Promise<void> {
        try {
            this.isLoadingSettings = true
            const response = await CompanyNotificationsApi.getCompanyNotificationSettings(matterId)
            this.companyNotificationSettings = {
                ...response.data,
                isActive: Object.values(response.data).some((value: boolean) => value === true),
            }
            this.areCompanyNotificationSettingsLoaded = true
        } catch (error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                // If monitoring hasn't been setup for this matter, then get not found back from the API
                this.companyNotificationSettings = {
                    isActive: false,
                    trackCompaniesHouseInformation: false,
                    trackCompaniesHouseInsolvency: false,
                    trackCompanyTitleOwnership: false,
                }
            } else{
                console.error('Error retrieving company notification settings:', error)
            }
        } finally {
            this.isLoadingSettings = false
        }
    },

    /**
     * Updates the title notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {ITitleNotificationSettings} request - The notification settings to be updated.
     * @return {Promise<void>} A promise that resolves when the notification settings are updated successfully.
     */
    async updateTitleNotificationSettings(matterId: number, request: ITitleNotificationSettings): Promise<void> {
        try {
            // Doesn't return any data, so fire and forget
            this.isUpdatingSettings = true
            await NotificationsApi.updateTitleNotificationSettings(matterId, request)
            this.notificationSettings = request

            // Reload the notification settings after updating
            await this.getTitleNotificationSettings(matterId)

            // Reload notifications after updating settings
            this.reloadNotifications = true

        } catch (error) {
            console.error('Error updating title notification settings:', error)
        } finally {
            this.isUpdatingSettings = false
        }
    },

    /**
     * Updates the company notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {ICompanyNotificationSettings} request - The notification settings to be updated.
     * @return {Promise<void>} A promise that resolves when the notification settings are updated successfully.
     */
    async updateCompanyNotificationSettings(matterId: number, request: ICompanyNotificationSettings): Promise<void> {
        try {
            // Doesn't return any data, so fire and forget
            this.isUpdatingSettings = true
            await CompanyNotificationsApi.updateCompanyNotificationSettings(matterId, request)
            this.notificationSettings = request

            // Reload the notification settings after updating
            await this.getCompanyNotificationSettings(matterId)

            // Reload notifications after updating settings
            this.reloadNotifications = true

        } catch (error) {
            console.error('Error updating company notification settings:', error)
        } finally {
            this.isUpdatingSettings = false
        }
    },

    /**
     * Updates the selected read status subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param readStatus
     */
    async updateShowUnreadOnly(matterId: number, readStatus: boolean): Promise<void> {
        this.showOnlyUnread = readStatus
        this.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Retrieves the user's email preferences for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the preferences.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of the preferences.
     * @throws {Error} - If an error occurs while retrieving the preferences.
     */
    async getUserEmailPreferences(matterId: number): Promise<void> {
        try {
            this.isLoadingUserEmailPreferences = true
            const response = await UserEmailPreferencesApi.getUserEmailPreferences(matterId)
            this.userEmailPreferences = {
                ...response.data,
            }
            this.areUserEmailPreferencesLoaded = true
        } catch (error) {
            console.error('Error retrieving notification settings:', error)
        } finally {
            this.isLoadingUserEmailPreferences = false
        }
    },

    /**
     * Updates the user email preferences for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {IUserEmailPreferences} request - The user email preferences to be updated.
     * @return {Promise<void>} A promise that resolves when the email preferences are updated successfully.
     */
    async updateUserEmailPreferences(matterId: number, request: IUserEmailPreferences): Promise<void> {
        try {
            this.isUpdatingUserEmailPreferences = true
            await UserEmailPreferencesApi.updateUserEmailPreferences(matterId, request)
            this.userEmailPreferences = request
        } catch (error) {
            console.error('Error updating user email preferences:', error)
        } finally {
            this.isUpdatingUserEmailPreferences = false
        }
    },

    initialise(matterId: number) {
        this.clear()
        this.unreadTitleNotifications = 0
        this.unreadCompanyNotifications = 0

        this.getNotifications(matterId)
        this.getTitleNotificationSettings(matterId)
        this.getCompanyNotificationSettings(matterId)
        this.getUserEmailPreferences(matterId)
    },

}
