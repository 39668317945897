<template>
    <div class="matter-create-features d-flex flex-column gr-2">
        <div class="d-flex flex-column gr-3">
            <ow-toggle-card v-model="formData.toggleTitleAlerts"
                            :title="$t('matterCreate.steps.matterFeatures.titleAlerts.heading')"
                            :description="$t('matterCreate.steps.matterFeatures.titleAlerts.description')" />

            <p v-t="'matterCreate.steps.matterFeatures.titleAlerts.footer'"
               class="ow-wizard__header__step-description body-regular-small mt-1">
            <!-- disabled for now -->
            <!--            <ow-toggle-card v-model="formData.toggleCompanyAlerts"-->
            <!--                            is-new-->
            <!--                            :title="$t('matterCreate.steps.matterFeatures.companyAlerts.heading')"-->
            <!--                            :description="$t('matterCreate.steps.matterFeatures.companyAlerts.description')" />-->
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        onMounted,
        ref,
        watch,
    } from "vue"

    import OwToggleCard from "@/components/core/ow-toggle-card.vue"
    import { IWizardStep } from "@/components/core/ow-wizard"
    import {
        IMatterCreateDetailsStepData,
        IMatterCreateFeaturesStepData,
    } from "@/components/matter/create/types"
    import { inject as userProvider } from "@/composables/use-user"
    import { checkFlag } from "@/feature-flags"

    const props = defineProps<{
        step: IWizardStep<IMatterCreateFeaturesStepData>
        stepData: Record<string, Record<string,any>>
    }>()

    const { hasAccessToAlerts } = userProvider()
    const hasAccess = ref<boolean>(false)

    const formData = computed({
        get: () => props.step.data,
        set: (value: IMatterCreateFeaturesStepData) => {
            props.step.data = value
        },
    })

    onBeforeMount(async () => {
        formData.value = {
            toggleTitleAlerts: false,
            toggleCompanyAlerts: false,
        }
        props.step.disabled = true
    })

    onMounted(async () => {
        hasAccess.value = await hasAccessToAlerts()
    })

    watch(() => props.stepData, async () => {
        if (!hasAccess.value) {
            props.step.disabled = true
            return
        }
        props.step.disabled = false
    }, { deep: true, immediate: true })
</script>

<style scoped>

</style>