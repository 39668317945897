<template>
    <base-monitoring-preference-card hide-header
                                     class="company-groups-card">
        <div v-if="loading"
             class="preference-presets-card__loading">
            <ow-loading-logo />
        </div>
        <div v-else-if="companyGroups?.length === 0"
             class="d-flex gr-4 flex-column py-4">
            <p class="body-copy--greyout body-italic text-center">
                <i18n-t class="caption-regular"
                        keypath="assetMonitoring.preferences.noCompanyGroups"
                        tag="span">
                    <template #link>
                        <router-link :to="{ name: Route.AssetMonitoringSettingsAddCompany }">
                            <ow-button class="d-inline-flex"
                                       is-link
                                       small
                                       is-text>
                                {{ t('label.company') }}
                            </ow-button>
                        </router-link>
                    </template>
                </i18n-t>
            </p>
        </div>
        <template v-for="(company, index) of companyGroups"
                  v-else
                  :key="company.id">
            <company-group-card class="pa-6"
                                v-bind="company"
                                :company-group="company"
                                @toggle-group="onToggleGroup(company)"
                                @edit-group="onEditGroup(company)" />
            <v-divider v-if="index !== companyGroups.length - 1" />
        </template>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from "vue-router"

    import CompanyGroupsApi, { IGetCompanyGroupResponse } from "@/api/company-groups.api"
    import CompanyGroupCard from "@/components/asset-monitoring/company-groups/company-group-card.vue"
    import BaseMonitoringPreferenceCard from "@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwLoadingLogo from "@/components/core/ow-loading-logo.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import { Route } from "@/enums/route.enum"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"

    const props = defineProps<{
        matterId: number
    }>()

    const { t } = useI18n()
    const companyGroups = ref<IGetCompanyGroupResponse[]>()

    // OW Recommended card
    const loading = ref<boolean>(true)
    const router = useRouter()
    const assetMonitoringStore = useAssetMonitoringStore()

    const onEditGroup = (company: IGetCompanyGroupResponse) => {
        router.push({
            name: Route.AssetMonitoringSettingsEditCompany,
            params: {
                matterId: props.matterId,
                groupId: company.id,
            },
        })
    }

    const onToggleGroup = async (company: IGetCompanyGroupResponse) => {
        const resp = await CompanyGroupsApi.updateCompanyGroup(company.id, {
            groupName: company.groupName,
            isEnabled: !company.isEnabled,
        })
        if (resp?.ok) {
            const index = companyGroups.value.findIndex((c) => c.id === company.id)
            if (index !== -1) {
                companyGroups.value[index].isEnabled = !company.isEnabled
            }
        }

        // Reload notifications after updating settings
        assetMonitoringStore.reloadNotifications = true
    }

    const fetch = async () => {
        loading.value = true

        if (!assetMonitoringStore.areCompanyNotificationSettingsLoaded) {
            await assetMonitoringStore.getCompanyNotificationSettings(props.matterId)
        }

        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(props.matterId)
            if (resp?.data?.companyGroups) {
                companyGroups.value = resp.data.companyGroups
            }
        } finally {
            loading.value = false
        }
    }

    onMounted(async () => {
        await fetch()
    })

    onActivated(async () => {
        await fetch()
    })

</script>

<style lang="scss" scoped>
    @import './preference-presets-card';
</style>
