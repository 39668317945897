import httpClient from '@/api/http-client'
import {IHttpClientResponse} from '@/interfaces/http-client-response.interface'
import { getNotificationHubUri } from '@/utils/environment.utils'

export type ICompanyNotificationSettings = {
    isActive?: boolean,
    trackCompaniesHouseInformation: boolean,
    trackCompaniesHouseInsolvency: boolean,
    trackCompanyTitleOwnership: boolean,
}

/**
 * @class NotificationsApi
 * This class provides methods to interact with the notification API.
 */
export default class CompanyNotificationsApi {
    static updateController: AbortController = null

    static END_POINT = `${ getNotificationHubUri() }/api/matter`

    /**
     * Retrieves the company notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     *
     * @return {Promise<IHttpClientResponse<ICompanyNotificationSettings>>} - A Promise that resolves to the HTTP response containing the notification settings.
     */
    static getCompanyNotificationSettings(matterId: number): Promise<IHttpClientResponse<ICompanyNotificationSettings>> {
        return httpClient.get(`${ this.END_POINT }/${ matterId }/settings/company`)
    }

    /**
     * Updates the company notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter to update the notification settings for.
     * @param {ICompanyNotificationSettings} request - The new notification settings to be applied.
     *
     * @returns {Promise<IHttpClientResponse<ICompanyNotificationSettings>>} A promise that resolves to the updated notification settings.
     */
    static updateCompanyNotificationSettings(matterId: number, request: ICompanyNotificationSettings): Promise<IHttpClientResponse<ICompanyNotificationSettings>> {
        if (this.updateController) {
            this.updateController.abort()
        }

        this.updateController = new AbortController()
        return httpClient.post(`${ this.END_POINT }/${ matterId }/settings/company`, request)
    }
}
