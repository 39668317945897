import {
    computed,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from "vue-router"
import { useStore } from 'vuex'

import {
    NavAction,
    NavItem,
} from '@/components/top-nav/types'
import useAppNav from "@/composables/use-app-nav"
import { Route } from '@/enums/route.enum'
import {checkFlag} from "@/feature-flags"
import { useAssetMonitoringStore } from "@/stores/asset-monitoring"

const useAssetMonitoringTopNav = () => {

    const { t } = useI18n()

    const assetMonitoringStore = useAssetMonitoringStore()
    const unreadCompanyNotifications = computed(() => assetMonitoringStore.unreadCompanyNotifications)
    const unreadTitleNotifications = computed(() => assetMonitoringStore.unreadTitleNotifications)
    const unreadNotifications = computed(() => unreadCompanyNotifications.value + unreadTitleNotifications.value)

    const isActive = computed(() => assetMonitoringStore.titleNotificationSettings.isActive)
    const topNavItems = computed<NavItem[]>(() => {
        return [
            {
                title: t('assetMonitoring.navigationHeadings.alerts').toString(),
                route: Route.AssetMonitoringAlerts,
                childNavName: 'alerts',
                dataTrackId: 'AM Top Nav - Alerts clicked',
                dataTestId: 'am-top-nav-alerts',
                alertCount: !isActive.value ? undefined : unreadNotifications.value,
            },
            {
                title: t('assetMonitoring.navigationHeadings.settings').toString(),
                route: Route.AssetMonitoringSettings,
                routeParams: { tabId: 'titles' },
                childNavName: 'settings',
                dataTrackId: 'AM Top Nav - Settings clicked',
                dataTestId: 'am-top-nav-settings',
            },
        ]
    })

    const topNavActions = computed<NavAction[]>(() => {
        return [{
            title: t('assetMonitoring.navigationActions.addCompany'),
            dataTrackId: 'AM Top Nav - Add Company clicked',
            dataTestId: 'am-top-nav-add-company',
        } as NavAction]
    })

    const store = useStore()

    const subNavItems = computed<NavItem[]>(() => {
        return []
    })

    return {
        topNavItems,
        subNavItems,
        topNavActions,
    }
}

export default useAssetMonitoringTopNav
