<template>
    <p v-t="'assetMonitoring.settings.description'"
       class="body-regular my-2" />
    <div class="mt-6 alerts-settings__grid">
        <ow-card has-outline
                 border-rounded
                 rounded-lg
                 border
                 no-padding
                 class="alerts-settings__alerts">
            <ow-tabs v-model="selectedTab">
                <v-tab :key="Tabs.Titles">
                    {{ t('label.titles') }}
                </v-tab>
                <v-tab :key="Tabs.Companies">
                    {{ t('label.companies') }}
                </v-tab>
            </ow-tabs>
            <v-window v-model="selectedTab">
                <v-window-item :key="Tabs.Titles">
                    <title-alerts-preferences hide-header />
                </v-window-item>
                <v-window-item :key="Tabs.Companies">
                    <company-alerts-preferences hide-header
                                                :matter-id="matterId" />
                </v-window-item>
            </v-window>
        </ow-card>
        <email-notifications-settings-card />
    </div>
</template>

<script setup lang="ts">

    import {
        onActivated,
        onMounted,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"

    import CompanyGroupsApi, {
        IGetCompanyGroupResponse,
    } from "@/api/company-groups.api"
    import CompanyAlertsPreferences from "@/components/asset-monitoring/monitoring-preferences/company-alerts-preferences.vue"
    import EmailNotificationsSettingsCard
        from "@/components/asset-monitoring/monitoring-preferences/email-notifications-settings-card.vue"
    import TitleAlertsPreferences from "@/components/asset-monitoring/monitoring-preferences/title-alerts-preferences.vue"
    import OwCard from "@/components/core/ow-card.vue"
    import OwTabs from "@/components/core/ow-tabs.vue"
    import { Route } from "@/enums/route.enum"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    const router = useRouter()
    const companyGroups = ref<IGetCompanyGroupResponse[]>()

    enum Tabs {
        Titles = 0,
        Companies = 1,
    }

    const props = defineProps<{
        matterId: number
        tabId: string
    }>()

    const loading = ref(false)
    const selectedTab = ref<number>(Tabs.Titles)
    const assetMonitoringStore = useAssetMonitoringStore()
    const { t } = useI18n()

    const onAddGroup = () => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsCreate,
            params: {
                matterId: props.matterId,
            },
        })
    }

    const onEditGroup = (company: IGetCompanyGroupResponse) => {
        router.push({
            name: Route.AssetMonitoringCompanyGroupsEdit,
            params: {
                matterId: props.matterId,
                groupId: company.id,
            },
        })
    }

    const onToggleGroup = async (company: IGetCompanyGroupResponse) => {
        const resp = await CompanyGroupsApi.updateCompanyGroup(company.id, {
            groupName: company.groupName,
            isEnabled: !company.isEnabled,
        })
        if (resp?.ok) {
            const index = companyGroups.value.findIndex((c) => c.id === company.id)
            if (index !== -1) {
                companyGroups.value[index].isEnabled = !company.isEnabled
            }
        }
        assetMonitoringStore.reloadNotifications = true
    }

    onMounted(async () => {
        await fetch()
    })

    onActivated(async () => {
        await fetch()
    })

    const fetch = async () => {
        loading.value = true
        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(props.matterId)
            if (resp?.data?.companyGroups) {
                companyGroups.value = resp.data.companyGroups
            }
        } finally {
            loading.value = false
        }
    }

    watch(() => props.tabId, (tabId) => {
        switch (tabId) {
            case "titles":
                selectedTab.value = Tabs.Titles
                break
            case "companies":
                selectedTab.value = Tabs.Companies
                break
        }
    }, {
        immediate: true,
    })

    watch(() => selectedTab.value, (tab) => {
        switch (tab) {
            case Tabs.Titles:
                router.push({
                    name: Route.AssetMonitoringSettings,
                    params: {
                        matterId: props.matterId,
                        tabId: "titles",
                    },
                })
                break
            case Tabs.Companies:
                router.push({
                    name: Route.AssetMonitoringSettings,
                    params: {
                        matterId: props.matterId,
                        tabId: "companies",
                    },
                })
                break
        }
    })
</script>

<style lang="scss" scoped>
@import './alerts-settings';
</style>
