<template>
    <div class="matter-create-details-step">
        <div class="d-flex flex-column gr-2">
            <!-- Matter & client code-->
            <matter-client-code-inputs v-model="clientCodeInputs"
                                       :client-code="formData.clientMatterCode.clientCode"
                                       :matter-code="formData.clientMatterCode.matterCode"
                                       client-code-data-test-attribute="matters-create-client-code"
                                       matter-code-data-test-attribute="matter-create-code" />

            <!-- Matter name-->
            <ow-field :label="$t('matterCreate.steps.createMatter.fields.matterName')"
                      label-for="matter-name">
                <v-text-field id="matter-name"
                              v-model="formData.name"
                              :maxlength="matterNameMaxLength"
                              :rules="[rules.matterName]"
                              counter
                              variant="outlined"
                              data-test="matter-create-name"
                              required />
            </ow-field>

            <!-- Matter type-->
            <ow-field :label="$t('matterCreate.steps.createMatter.fields.transactionType')"
                      label-for="transaction-type">
                <v-select id="transaction-type"
                          v-model="formData.type"
                          :items="transactionTypes"
                          :rules="[rules.type]"
                          :clearable="true"
                          variant="outlined"
                          data-test="matter-create-type"
                          style="margin-bottom: 36px" />
            </ow-field>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from "vuex"

    import OwField from "@/components/core/ow-field.vue"
    import { IWizardStep } from "@/components/core/ow-wizard"
    import {
        IMatterCreateDetailsStepData,
    } from "@/components/matter/create/types"
    import MatterClientCodeInputs from "@/components/matter/matter-client-code-inputs.vue"
    import { MATTER_MAX_NAME_LENGTH } from "@/consts/matter"
    import { MATTER_GET_TYPES } from "@/store/modules/matter/types"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const props = defineProps<{
        step: IWizardStep<IMatterCreateDetailsStepData>
    }>()
    const store = useStore()

    const { t } = useI18n()
    const clientCodeInputs = ref<{
        clientCode?: string
        matterCode?: string
        matterCodeError?: boolean,
        clientCodeCheckbox?: boolean,
    } | null>({})

    const formData = computed<IMatterCreateDetailsStepData>({
        get: () => props.step.data,
        set: (value: IMatterCreateDetailsStepData) => {
            props.step.data = value
        },
    })

    const usesClientCodes = computed(() => store.state.user.usesClientCodes)

    const rules = computed(() => ({
        matterName: value => {
            const hasName = !isNullOrWhitespace(value)
            const nameLengthIsValid = value?.length <= MATTER_MAX_NAME_LENGTH
            if (hasName && nameLengthIsValid) {
                return true
            } else if (!hasName) {
                return t('label.required')
            }
            return t('matterCreate.steps.createMatter.validation.nameTooLong')
        },
        type: (value) => Boolean(value) || t('label.required'),
    }))

    const transactionTypes = computed(() => store.getters[MATTER_GET_TYPES])

    const matterNameMaxLength = computed(() => 100)
    const hasError = ref(false)

    const nextButtonDisabled = computed(() => {
        let validClientCode = true
        if (usesClientCodes.value) {
            validClientCode = formData.value.clientMatterCode?.clientCodeCheckbox || !isNullOrWhitespace(formData.value.clientMatterCode?.clientCode)
        }
        const validMatterCode = !isNullOrWhitespace(formData.value.clientMatterCode?.matterCode) && !formData.value.clientMatterCode.matterCodeError
        return !validClientCode ||
            !validMatterCode ||
            !formData.value.type ||
            !formData.value.name
    })

    const prevButtonDisabled = computed(() => false)

    onBeforeMount(() => {
        formData.value = {
            clientMatterCode: {
                clientCode: null,
                matterCode: null,
                matterCodeError: false,
            },
            name: '',
            type: '',
        }
        props.step.nextButtonIsDisabled = nextButtonDisabled
        props.step.prevButtonIsDisabled = prevButtonDisabled
        props.step.beforeNext = () => {
            if (nextButtonDisabled.value) {
                hasError.value = true
                return false
            }
            hasError.value = false
            return true
        }
    })

    watch(() => clientCodeInputs.value, (value) => {
        formData.value.clientMatterCode = {
            clientCode: value?.clientCode,
            matterCode: value?.matterCode,
            clientCodeCheckbox: value?.clientCodeCheckbox,
            matterCodeError: value?.matterCodeError,
        }
    }, {
        deep: true,
    })

    defineExpose({
        nextButtonDisabled,
    })
</script>

<style scoped>

</style>