import { computed,
    inject as VueInject,
    provide as VueProvide } from "vue"
import { useStore } from "vuex"

import useLicenceController, { FeatureId } from "@/composables/use-licence-controller"
import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

export const USER_PROVIDER_KEY = Symbol('userProvider')

export const inject = (): ReturnType<typeof useUser> => {
    if (!VueInject(USER_PROVIDER_KEY)) {
        throw new Error(`${ USER_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(USER_PROVIDER_KEY) as ReturnType<typeof useUser>
}

export const provide = () => {
    if ((import.meta as any).env.DEV) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ USER_PROVIDER_KEY.toString() }`)
    }
    VueProvide(USER_PROVIDER_KEY, useUser())
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useUser = () => {
    const { userHasAccess, licencedFeatures, accessTokenData, active } = useLicenceController({
        debug: (import.meta as any).env.DEV,
    })

    const store = useStore()
    const assetMonitoringStore = useAssetMonitoringStore()


    const hasAccessToPlanning = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.Planning)
    }

    const hasAccessToFindNearby = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.Nearby)
    }

    const hasAccessToAlerts = async (): Promise<boolean> => {
        const hasAccess = await userHasAccess(FeatureId.Alerts)

        // this needs to be set in the asset monitoring store as it is used within matter hub action
        assetMonitoringStore.hasAccessToAlerts = hasAccess

        return hasAccess
    }

    const hasAccessToShapefileExport = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.ShapefileExport)
    }

    const hasAccessToMapLayers = async (): Promise<boolean> => {
        return await userHasAccess(FeatureId.MapLayers)
    }

    const isPremiumUser = computed(() => {
        return accessTokenData.value?.licenceType.toLowerCase() === "premium"
    })

    const isStandardUser = computed(() => {
        return accessTokenData.value?.licenceType.toLowerCase() === "standard"
    })

    const isLiteUser = computed(() => {
        return accessTokenData.value?.licenceType.toLowerCase() === "lite"
    })

    const isDisabledUser = computed(() => {
        return accessTokenData.value?.licenceType.toLowerCase() === "none"
    })

    const isLicencingActive = computed(() => {
        return active.value
    })

    const hasAccessToFeature = async (featureId: FeatureId): Promise<boolean> => {
        return await userHasAccess(featureId)
    }

    const getLicenceByFeatureId = (featureId: FeatureId) => {
        return licencedFeatures[featureId]?.licenceType ?? null
    }

    return {
        isLicencingActive,
        isDisabledUser,
        isLiteUser,
        isPremiumUser,
        isStandardUser,

        hasAccessToPlanning,
        hasAccessToFindNearby,
        hasAccessToAlerts,
        hasAccessToShapefileExport,
        hasAccessToMapLayers,

        hasAccessToFeature,
        getLicenceByFeatureId,
    }
}

export default useUser
