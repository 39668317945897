<template>
    <div class="map-download-site-plan">
        <ow-button v-if="!isTopNavVisible"
                   :disabled="snapshotLoading"
                   is-primary
                   data-test="map-download-site-plan-btn"
                   data-track="MAP - Download site plan button clicked - New variant"
                   @click="openDialog">
            <template #iconPrefix>
                <v-icon>$snapshot</v-icon>
            </template>
            {{ $t('map.options.downloadText', [downloadText]) }}
        </ow-button>
        <teleport to="body">
            <ow-modal v-model="isDialogOpened"
                      class="map-download-site-plan-modal"
                      data-test="map-download-site-plan-dialog"
                      :title="$t('map.options.downloadText', [downloadText.toLowerCase()])">
                <!-- Snapshot feature hint -->
                <div class="feature-hint">
                    <v-icon>$key</v-icon>
                    <div class="feature-hint-text">
                        <span v-t="'map.downloadPopup.createHighResPlan'"
                              class="body-highlight" />
                        <span v-t="'map.downloadPopup.createHighResPlanDescription'"
                              class="caption-regular" />
                    </div>
                    <ow-button is-primary
                               data-track="MAP - Try Snapshot Assistant"
                               data-test="map-download-site-plan-dialog-snapshot-assistant-hint-button"
                               @click="onSnapshotAssistantClick">
                        {{ $t('map.downloadPopup.createHighResPlanLink') }}
                    </ow-button>
                </div>

                <span class="body-regular">  {{ $t('map.downloadPopup.chooseText', [downloadText.toLowerCase()]) }} </span>

                <div class="site-plan-options">
                    <div class="site-plan-download">
                        <v-icon>$snapshot</v-icon>
                        <span class="body-highlight"
                              style="margin-bottom: 16px;">
                            {{ $t('map.options.downloadText', [downloadText]) }}
                        </span>
                        <div class="site-plan-download-buttons">
                            <ow-button data-test="map-download-site-plan-button-download-png"
                                       is-secondary
                                       full-width
                                       data-track="MAP - Snapshot as PNG"
                                       @click="onSnapshot('png')">
                                {{ $t('map.downloadPopup.downloadPng') }}
                            </ow-button>
                            <ow-button data-test="map-download-site-plan-button-download-pdf"
                                       is-secondary
                                       full-width
                                       data-track="MAP - Snapshot as PDF"
                                       @click="onSnapshot('pdf')">
                                {{ $t('map.downloadPopup.downloadPdf') }}
                            </ow-button>
                        </div>
                    </div>
                    <div class="site-plan-isp">
                        <v-icon>link</v-icon>
                        <span class="body-highlight"
                              style="margin-bottom: 16px;">
                            {{ $t('map.downloadPopup.isp') }}
                        </span>
                        <span class="map-download-site-plan__area-subtitle">
                            {{ $t('map.downloadPopup.ispDescription') }}
                        </span>
                        <a class="accents-link-text"
                           @click="openHelpArticle">
                            {{ $t('buttons.readMore') }}
                        </a>
                        <ow-button v-if="isLinkDisabled"
                                   :loading="isActivateLinkLoading"
                                   is-primary
                                   data-test="map-download-site-plan-button-activate-link"
                                   data-track="MAP - Snapshot activate link"
                                   @click="activateLink">
                            {{ $t('map.downloadPopup.activateLink') }}
                        </ow-button>
                        <div v-else-if="hasBeenShared"
                             class="isp-shared-container">
                            <ow-textfield ref="urlLinkInput"
                                          v-model="URLForLink"
                                          :disabled="!linkSharingEnabled"
                                          data-test="map-download-site-plan-input-shared-url"
                                          label="Shareable link:"
                                          read-only />
                            <div>
                                <v-icon data-test="link-share-settings-copy-button"
                                        style="margin-top: 20px;"
                                        data-track="MAP - Snapshot copy link to clipboard"
                                        @click="onCopyButtonClick">
                                    $copy
                                </v-icon>
                                <ow-tooltip v-model="showCopiedLinkTip"
                                            activator="parent"
                                            :position="OwTooltipPosition.Left">
                                    <!-- eslint-disable-next-line vue/no-unused-vars -->
                                    <span>{{ $t('message.copied') }}</span>
                                </ow-tooltip>
                            </div>
                        </div>
                        <ow-button v-else
                                   :loading="isCreateShareableLinkLoading"
                                   is-primary
                                   data-test="map-download-site-plan-button-generate-link"
                                   data-track="MAP - Snapshot generate link"
                                   @click="onCreateShareableLink">
                            {{ $t('map.downloadPopup.generateLink') }}
                        </ow-button>
                    </div>
                </div>
            </ow-modal>
        </teleport>
    </div>
</template>

<script lang="ts">
    import * as htmlToImage from 'html-to-image'
    import { jsPDF as JsPDF } from 'jspdf'
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import SnapshotApi from '@/api/snapshot.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    // @ts-ignore
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route as OwRoutes } from '@/enums/route.enum'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        MAP_GET_VIEW,
        MAP_TAKING_SNAPSHOT,
    } from '@/store/modules/map/types'
    import {
        MATTER_CREATE_LINK_SHARE,
        MATTER_GET_LINK_SHARE_URL,
        MATTER_UPDATE_LINK_SHARE,
    } from '@/store/modules/matter/types'
    import {
        LOGGING_LOG_FEATURE_USAGE,
        OPEN_WINDOW,
    } from '@/store/mutation-types'

    export default {
        name: 'MapDownloadSitePlan',

        components: {
            OwTextfield,
            OwModal,
            OwButton,
            OwTooltip,
        },
        mixins: [FeatureFlagsMixin],

        setup() {
            const { isTopNavVisible } = useMapTopNav()
            return {
                isTopNavVisible,
            }
        },

        data() {
            return {
                snapshotLoading: false,
                isDialogOpened: false,
                showCopiedLinkTip: false,
                isCreateShareableLinkLoading: false,
                isActivateLinkLoading: false,
                OwTooltipPosition,
            }
        },

        computed: {
            ...mapState({
                map: (state: IState) => state.map.map,
                currentLinkShare: (state: IState) => state.matter.currentMatter.linkShare,
                selectedTitleNumber: (state: IState) => state.title.selectedTitleNumber,
                currentMatter: (state: IState) => state.matter.currentMatter,
            }),

            ...mapGetters({
                shareLinkUrl: MATTER_GET_LINK_SHARE_URL,
            }),

            URLForLink() {
                return this.hasBeenShared ? this.shareLinkUrl : null
            },

            linkSharingEnabled() {
                return this.currentLinkShare?.enabled
            },

            hasBeenShared() {
                return Boolean(this.currentLinkShare)
            },

            isLinkDisabled() {
                return this.currentLinkShare && !(this.currentLinkShare.enabled && this.currentLinkShare.isIspShare)
            },

            downloadText() {
                return this.$t('label.snapshot')
            },
        },

        methods: {
            ...mapActions({
                setTakingSnapshot: MAP_TAKING_SNAPSHOT,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                createShareableLink: MATTER_CREATE_LINK_SHARE,
                updateShareableLink: MATTER_UPDATE_LINK_SHARE,
                openWindow: OPEN_WINDOW,
            }),

            openDialog() {
                this.isDialogOpened = true
            },

            async onCreateShareableLink() {
                if (!this.hasBeenShared) {
                    this.isCreateShareableLinkLoading = true
                    await this.createShareableLink({
                        enabled: true,
                        isIspShare: true,
                    })
                    this.isCreateShareableLinkLoading = false
                }
            },

            async activateLink() {
                this.isActivateLinkLoading = true
                const request = {
                    matterId: this.currentMatter.id,
                    title: this.currentLinkShare.title,
                    description: this.currentLinkShare.description,
                    password: this.currentLinkShare.passwordProtected ? this.newPassword : null,
                    configurationJSON: this.currentLinkShare.configurationJSON,
                    expiresOn: this.currentLinkShare.expiresOn,
                    enabled: true,
                    isIspShare: true,
                    isWalkthroughShare: Boolean(this.currentLinkShare?.isWalkthroughShare),
                }

                await this.updateShareableLink(request)
                this.isActivateLinkLoading = false
            },

            onCopyButtonClick() {
                this.$refs.urlLinkInput.$el.getElementsByTagName('input')[0].select()
                document.execCommand('copy')
                this.showCopiedLinkTip = true
                setTimeout(() => {
                    this.showCopiedLinkTip = false
                }, 2000)
                getSelection().removeAllRanges()
            },

            onSnapshot(format) {
                this.isDialogOpened = false
                this.snapshotLoading = true

                this.setTakingSnapshot(true)

                const fileName = `${ this.getAGoodFileName() }.${ format }`
                const mapContainer: HTMLElement = document.querySelector('.ol-viewport')

                // Add watermark
                const watermark = document.createElement('div')
                watermark.classList.add('map__snapshot-watermark')
                mapContainer.appendChild(watermark)

                // Add north arrow
                const northArrow: Element = document.querySelector('.map-north-arrow')
                const northArrowClone: HTMLElement = northArrow.cloneNode(true) as HTMLElement
                northArrowClone.style.display = 'block'
                mapContainer.appendChild(northArrowClone)

                window.requestAnimationFrame(() => {
                    htmlToImage.toPng(mapContainer)
                        .then(dataUrl => {
                            if (format === 'png') {
                                window.saveAs(dataUrl, fileName)
                            } else {
                                // PDF
                                const pageOrientation = mapContainer.clientWidth > mapContainer.clientHeight ? 'l' : 'p'
                                const doc = new JsPDF(pageOrientation, 'px', [mapContainer.clientWidth, mapContainer.clientHeight])
                                doc.addImage(dataUrl, 'PNG', 0, 0, mapContainer.clientWidth, mapContainer.clientHeight, fileName)
                                doc.save(fileName)
                            }
                        })
                        .catch(error => {
                            console.error(error)
                        })
                        .finally(() => {
                            mapContainer.removeChild(watermark)
                            mapContainer.removeChild(northArrowClone)
                            this.onSnapshotComplete()
                        })
                })
            },

            openHelpArticle() {
                const articleUrl = 'https://intercom.help/orbital-witness/en/articles/4689498-interactive-site-plans-explained'
                this.openWindow(articleUrl)
            },

            onSnapshotComplete() {
                this.snapshotLoading = false

                this.setTakingSnapshot(false)

                // Log usage
                const logMessages = []
                if (this.selectedTitleNumber != null) {
                    logMessages.push('selected title')
                }

                let logMessage = null
                if (logMessages.length > 0) {
                    logMessage = 'with ' + logMessages.join(', ')
                }
                this.logFeatureUsage({
                    type: 'snapshot-map',
                    description: logMessage,
                })
            },

            getAGoodFileName() {
                return `Orbital Witness - ${ this.selectedTitleNumber ?? 'site-plan' }`
            },

            async onSnapshotAssistantClick() {
                this.isDialogOpened = false
                const snapshotRequest = MapSnapshotService.createNewSnapshotApiModel(this.map, this.currentMatter.id)
                const configId = await SnapshotApi.createSnapshot(snapshotRequest)

                await this.$router.push({
                    name: OwRoutes.SnapshotAssistant,
                    params: {
                        configId,
                    },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import './map-download-site-plan';
</style>
