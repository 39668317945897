<template>
    <section class="matter-client-code-inputs d-flex gc-3">
        <fieldset v-if="usesClientCodes"
                  class="w-50">
            <!-- Client code-->
            <ow-checkbox id="client-code-checkbox"
                         v-model="clientCodeCheckbox"
                         data-test="matter-client-code-inputs-work-without-client-code"
                         data-track="toggle-work-without-client-code"
                         :label="$t('matterCreate.workWithoutClientCode')" />
            <p v-if="usesClientCodes && clientCodeCheckbox"
               v-t="'matterCreate.maybeRequiredToProvide'"
               class="body-regular-small"
               data-test="matter-client-code-inputs-work-without-client-code-tip" />
            <ow-field v-show="usesClientCodes && !clientCodeCheckbox"
                      :label="$t('matterCreate.clientCode')"
                      label-for="client-code-field">
                <v-text-field id="client-code-field"
                              ref="clientCodeField"
                              v-model="formClientCode"
                              :data-test="clientCodeDataTestAttribute"
                              :hint="clientCodeFormat"
                              :persistent-hint="hasClientCodeHint"
                              :rules="[rules.clientCode, rules.validCode]"
                              data-track="collect-client-code"
                              variant="outlined" />
            </ow-field>
        </fieldset>

        <!-- Matter code-->
        <fieldset class="w-50">
            <ow-field :class="{
                          'mt-0': clientCodeCheckbox,
                          'mt-6': !clientCodeCheckbox,
                          'w-100': !usesClientCodes,
                      }"
                      :label="$t('matterCreate.matterCode')"
                      label-for="matter-code-field">
                <v-text-field id="matter-code-field"
                              ref="matterCodeField"
                              v-model="formMatterCode"
                              :data-test="matterCodeDataTestAttribute"
                              :hint="matterCodeFormat"
                              :persistent-hint="hasMatterCodeHint"
                              :rules="[rules.mandatoryMatterCodes, rules.validCode, rules.validOrganisationMatterCode]"
                              data-track="collect-matter-code"
                              variant="outlined" />
            </ow-field>
        </fieldset>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        nextTick,
        ref,
        useTemplateRef,
        watch,
    } from 'vue'
    import { useI18n } from "vue-i18n"
    import {
        useStore,
    } from 'vuex'

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwField from "@/components/core/ow-field.vue"
    import { matterCodeValidationRegEx } from '@/consts/matter'
    import { validateMatterCodeWithMask } from '@/utils/matter-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = withDefaults(defineProps<{
        clientCode?: string,
        matterCode?: string,
        clientCodeDataTestAttribute: string,
        matterCodeDataTestAttribute: string,
    }>(), {
        clientCode: '',
        matterCode: '',
        clientCodeDataTestAttribute: 'matter-client-code-inputs-client-code',
        matterCodeDataTestAttribute: 'matter-client-code-inputs-matter-code',
    })

    const formClientCode = ref(props.clientCode)
    const formMatterCode = ref(props.matterCode)
    const clientCodeCheckbox = ref(false)

    const modelValue = defineModel<
        { clientCode?: string,
          matterCode?: string,
          matterCodeError?: boolean,
          clientCodeCheckbox?: boolean }
    >({
        default: {},
    })

    const emit = defineEmits(['change'])

    const { t } = useI18n()
    const store = useStore()

    const matterCodeField = useTemplateRef('matterCodeField')
    const clientCodeField = useTemplateRef('clientCodeField')

    const rules = computed(() => ({
        clientCode: (value: string) => {
            if (usesClientCodes.value && !clientCodeCheckbox.value) {
                if (isNullOrWhitespace(value)) {
                    return t('matter.mandatoryClientCode', { format: clientCodeFormat.value })
                }
            }
            return true
        },
        mandatoryMatterCodes: (value: string) => {
            if (mandatoryMatterCodes.value) {
                if (isNullOrWhitespace(value)) {
                    return hasMatterCodeHint.value ? t('matter.mandatoryMatterCode', { format: matterCodeFormat.value }) :
                        t('matter.mandatoryMatterCodeWithoutHint')
                }
            }
            return true
        },
        validCode: (value: string) => {
            const validHMLRMatterCode = matterCodeValidationRegEx.test(value)

            if (!validHMLRMatterCode) {
                return t('matter.invalidCode')
            }
            return true
        },
        validOrganisationMatterCode: (value: string) => {
            const validOrganisationMatterCode = isNullOrWhitespace(matterCodeMask.value)
                ? true
                : validateMatterCodeWithMask(value, matterCodeMask.value)

            if (!validOrganisationMatterCode) {
                return hasMatterCodeHint.value
                    ? t('matter.invalidCodeFormat', { format: matterCodeFormat.value })
                    : t('matter.invalidCodeFormatWithoutHint')
            }

            return true
        },
    }))

    const usesClientCodes = computed(() => store.state.user.usesClientCodes)
    const mandatoryMatterCodes = computed(() => store.state.user.mandatoryMatterCodes)
    const matterCodeFormat = computed(() => store.state.user.matterCodeFormat)
    const clientCodeFormat = computed(() => store.state.user.clientCodeFormat)
    const matterCodeMask = computed(() => store.state.user.matterCodeMask)
    const hasClientCodeHint = computed(() => !isNullOrWhitespace(clientCodeFormat.value))
    const hasMatterCodeHint = computed(() => !isNullOrWhitespace(matterCodeFormat.value))

    watch(() => clientCodeCheckbox.value, async (newValue) => {
        if (newValue) {
            formClientCode.value = ''
        }

        await emitChange()
        await nextTick(() => {
            if (!newValue) {
                clientCodeField.value.focus()
            } else {
                matterCodeField.value.focus()
            }
        })
    })

    watch(() => formClientCode.value, async () => {
        await emitChange()
    })

    watch(() => formMatterCode.value, async () => {
        await emitChange()
    })

    const emitChange = async () => {
        let matterCodeFieldValidationResult = await matterCodeField.value.validate()
        let clientCodeFieldValidationResult = []
        if (clientCodeField?.value) {
            clientCodeFieldValidationResult = await clientCodeField.value.validate()
        }
        if (clientCodeFieldValidationResult && matterCodeFieldValidationResult) {
            modelValue.value = {
                clientCode: formClientCode.value,
                matterCode: formMatterCode.value,
                matterCodeError: matterCodeFieldValidationResult.length !== 0 || clientCodeFieldValidationResult.length !== 0,
                clientCodeCheckbox: clientCodeCheckbox.value,
            }
        } else {
            modelValue.value = null
        }
        emit('change', modelValue.value)
    }
</script>
