<template>
    <div v-if="showLock"
         :class="{
             'ow-lock--background': !hideBackground,
             'ow-lock--hide-tooltip': hideTooltip,
             'ow-lock--card': isCard,
             'ow-lock--invalid': showLock,
         }"
         :style="{ 'left': offsetX, position: 'relative' }"
         v-bind="$attrs"
         class="ow-lock">
        <section :class="{
                     'ow-lock--right': !isCard && align === 'right',
                     'ow-lock--left': !isCard && align === 'left',
                     'ow-lock--center': !isCard && align === 'center',
                     'justify-start': !isCard && align === 'right',
                     'justify-space-between': !isCard && align === 'right',
                 }"
                 class="ow-lock__content d-flex align-center w-100">
            <v-icon class="ow-lock__icon label-caps-medium"
                    :class="{
                        'ow-lock__icon--primary': isPrimary,
                    }"
                    data-test="lock-icon">
                $lock
            </v-icon>
            <section v-if="$slots.label"
                     class="ow-lock__slot text-no-wrap"
                     data-test="lock-slot-label">
                <slot name="label" />
            </section>
            <section v-else
                     class="ow-lock__label label-caps-smallest"
                     data-test="lock-label">
                {{ label ?? featureId }}
            </section>
            <ow-lock-card v-if="isCard"
                          :feature-id="featureId"
                          :licence-type="licenceType"
                          :title="tooltip" />
        </section>
        <ow-lock-tooltip v-if="!hideTooltip && !isCard"
                         :feature-id="featureId"
                         :licence-type="licenceType"
                         :tooltip-position="tooltipPosition"
                         :tooltip="tooltip" />
    </div>
    <template v-else>
        <slot name="default" />
    </template>
</template>

<script lang="ts" setup>
    import {
        computed,
        inject,
        onBeforeMount,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from "vuex"

    import OwLockCard from "@/components/core/ow-lock-card.vue"
    import OwLockTooltip from "@/components/core/ow-lock-tooltip.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { inject as userProvider } from "@/composables/use-user"
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"

    const props = withDefaults(defineProps<{
        featureId?: FeatureId
        label?: string,
        isCard?: boolean,
        tooltip?: string,
        tooltipPosition?: OwTooltipPosition,
        align?: 'right' | 'left' | 'center',
        offsetX?: string
        hideTooltip?: boolean
        hideBackground?: boolean
        isPrimary?: boolean
    }>(), {
        hideTooltip: false,
        hideBackground: false,
        isCard: false,
        tooltipPosition: OwTooltipPosition.Bottom,
        isPrimary: false,
        align: 'left',
    })

    defineOptions({
        inheritAttrs: false,
    })

    const store = useStore()
    const showLock = defineModel<boolean>()

    const { isLicencingActive, getLicenceByFeatureId, hasAccessToFeature } = userProvider()
    const { t } = useI18n()

    const licenceType = computed(() => props.featureId && (getLicenceByFeatureId(props.featureId)) || hasAccessToFeature(props.featureId))

    onBeforeMount(() => {
        if (props.label) {
            showLock.value = true
        } else if (!isLicencingActive.value || !props.featureId) {
            showLock.value = false
        }
    })

</script>


<style lang="scss">
@import './ow-lock.scss';
</style>
