<template>
    <div class="am-alerts">
        <div v-if="loading">
            <div class="am-alerts__loading-skeleton"
                 data-test="asset-monitoring-loading-skeleton">
                <ow-loading-logo class="am-alerts__loading-skeleton__logo" />
            </div>
        </div>
        <div v-else-if="splashPage"
             class="am-alerts__splash-page">
            <splash-page :title="splashTitle"
                         :button-title="t('action.enableMonitoring')"
                         :matter-id="matterId"
                         @button-click="$emit('splash-button-click', !splashPage)" />
        </div>
        <div v-else>
            <am-manage-monitors-header />
            <am-alert-filter v-model:items="items"
                             v-model:filters="selectedItems"
                             :disabled="showLoadingSkeleton"
                             @clear="$emit('clear')"
                             @check="$emit('item-check', $event)" />

            <div v-if="showLoadingSkeleton">
                <div class="am-alerts__loading-skeleton"
                     data-test="asset-monitoring-loading-skeleton">
                    <ow-loading-logo class="am-alerts__loading-skeleton__logo" />
                </div>
            </div>
            <am-data-grid v-else
                          :is-loading="assetMonitoringStore.isLoading"
                          :items="userNotifications"
                          :matter-id="matterId"
                          data-test="alert-monitors-grid"
                          class="am-alerts__data-grid"
                          @refresh="$emit('refresh')"
                          @sort-by="$emit('sort-by', $event)"
                          @view-title="onViewTitle"
                          @load-next-page="loadNextNotificationsPageHandler" />
        </div>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import {
        useRoute,
        useRouter,
    } from "vue-router"
    import { useStore } from "vuex"

    import AmAlertFilter from "@/components/asset-monitoring/am-alert-filter.vue"
    import AmManageMonitorsHeader from "@/components/asset-monitoring/am-manage-monitors-header.vue"
    import { IAssetMonitoringNotification } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import AmDataGrid from "@/components/asset-monitoring/grid/data-grid.vue"
    import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"
    import SplashPage from "@/components/asset-monitoring/splash-page.vue"
    import OwLoadingLogo from "@/components/core/ow-loading-logo.vue"
    import { Route } from "@/enums/route.enum"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { checkFlag } from "@/feature-flags"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"

    const { t } = useI18n()

    const props = withDefaults(defineProps<{
        matterId: number
        splashPage: boolean
    }>(), {
        splashPage: false,
    })

    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const assetMonitoringStore = useAssetMonitoringStore()
    const userNotifications = computed((): Array<IAssetMonitoringNotification> => assetMonitoringStore.userNotifications)
    const isLoadingSettings = computed(() => assetMonitoringStore.isLoadingSettings)
    const isLoadingNotifications = computed(() => assetMonitoringStore.isLoading)
    const isActive = computed(() => assetMonitoringStore.titleNotificationSettings.isActive)
    const loading = ref<boolean>(true)
    const splashTitle = computed(() =>{
        return t('assetMonitoring.splashPage.assetMonitoring')
    })
    const items = defineModel('items', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })
    const selectedItems = defineModel('selectedItems', {
        type: Array as () => Array<IOptionLinkItem>,
        default: [],
    })

    const showLoadingSkeleton = computed(() => {
        return isLoadingNotifications.value || isLoadingSettings.value
    })

    const loadNextNotificationsPageHandler = async () => {
        await assetMonitoringStore.getNextNotificationsPage(props.matterId)
    }

    const getActiveTab = (notificationSubType: NotificationSubType) => {
        let activeTab =  TitlePanelTabName.Summary
        switch (notificationSubType) {
            case NotificationSubType.Epc:
                activeTab = TitlePanelTabName.Epc
                break
            case NotificationSubType.NewDocumentInOCDA:
            case NotificationSubType.EditionDateDoesntMatchOcdaResponse:
                activeTab = TitlePanelTabName.CopiesFiled
                break
            case NotificationSubType.CompletedPendingApplication:
                activeTab = TitlePanelTabName.Planning
                break
        }
        return activeTab
    }

    const onViewTitle = async (args: {
        titleNumber: string,
        notificationSubType: NotificationSubType
    }): Promise<void> => {
        if (checkFlag('title-panel-v-2', false)) {
            const activeTab = getActiveTab(args.notificationSubType)
            await router.push({
                name: Route.AssetMonitoringTitleDetailsPanel,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
                query: {
                    from: route.name.toString(),
                    tab: activeTab,
                },
            })
        } else {
            await router.push({
                name: Route.MatterMapTitle,
                params: {
                    titleNumber: args.titleNumber,
                    matterId: props.matterId,
                },
            })
        }
    }

    const emit = defineEmits<{
        (e: 'splash-button-click', active: boolean)
        (e: 'clear')
        (e: 'item-check', item: IOptionLinkItem)
        (e: 'refresh')
        (e: 'sort-by', value: string)
    }>()

    const fetch = async () => {
        await assetMonitoringStore.getTitleNotificationSettings(props.matterId)
        await assetMonitoringStore.getNotifications(props.matterId)
    }

    defineExpose({
        fetch,
    })

    watch(() => props.matterId, (value, oldValue) => {
        if (value !== oldValue) {
            emit('clear')
        }
    })

    watch(() => assetMonitoringStore.isLoading, (val) => {
        if (!val) {
            loading.value = false
            return
        }
        setTimeout(() => {
            loading.value = false
        }, 500)
    }, {
        immediate: true,
    })
</script>

<style scoped lang="scss">
@import './am-alerts';
</style>
