<template>
    <ow-card class="find-companies-card pa-6 d-flex flex-column"
             :class="{
                 'find-companies-card--fixed-height': fixedHeight,
             }"
             :title="$t('assetMonitoring.preferences.cards.findCompanies.title')"
             has-outline
             border-rounded-lg>
        <find-companies-search v-model="modelSearch"
                               class="find-companies-card__search mb-4"
                               :is-loading="loading"
                               @search="onSearch" />

        <find-companies-grid-filter v-model:filter="filter"
                                    :items="modelItems"
                                    :sort-by="sortBy"
                                    :is-loading="loading"
                                    :headers="headers"
                                    @sort="sortBy = $event" />
        <find-companies-grid v-if="!isNullOrEmpty(modelItems)"
                             v-model:sort-by="sortBy"
                             :current-group="currentGroup"
                             class="find-companies-card__grid"
                             :is-loading="loading"
                             :items="modelItems"
                             :selected="modelSelected"
                             :headers="headers"
                             @items-selected="onItemsSelected"
                             @filter="filter = $event" />
    </ow-card>
</template>
<script setup lang="ts">
    import uniqueId from "lodash/uniqueId"
    import {
        computed,
        ref,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import { ICompanySearch } from "@/api/search.api"
    import FindCompaniesGrid from "@/components/asset-monitoring/company-groups/find-companies-grid.vue"
    import FindCompaniesGridFilter from "@/components/asset-monitoring/company-groups/find-companies-grid-filter.vue"
    import FindCompaniesSearch from "@/components/asset-monitoring/company-groups/find-companies-search.vue"
    import {
        IAssetMonitoringCompanyGroup,
        IAssetMonitoringCompanyGroupItem,
        IAssetMonitoringCompanyGroupSelected,
        ICompaniesGroupSearchRef,
    } from "@/components/asset-monitoring/company-groups/types"
    import OwCard from "@/components/core/ow-card.vue"
    import { isNullOrEmpty } from "@/utils/array-utils"
    import { getApiUri } from "@/utils/environment.utils"
    import {
        JsonStreamPayload,
        jsonStreamReader,
    } from "@/utils/stream-iterator"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const { t } = useI18n()
    const loading = ref<boolean>(false)
    const sortBy = ref<any[]>([{ key: 'name', order: 'asc' }])
    const filter = ref<string>('name')

    defineProps<{
        currentGroup: IAssetMonitoringCompanyGroup | null,
        fixedHeight?: boolean,
    }>()

    const modelItems = defineModel<IAssetMonitoringCompanyGroupItem[]>('items', {
        default: [],
    })

    const modelSelected = defineModel<IAssetMonitoringCompanyGroupSelected>('selected')

    const modelSearch = defineModel<string>('search')

    const searchId = ref<ICompaniesGroupSearchRef>({
        id: '',
        searchText: '',
    })

    const headers = computed(() => {
        return [
            {
                title: t('assetMonitoring.preferences.cards.findCompanies.headers.name'),
                align: 'left',
                key: 'name',
                nowrap: true,
            },
            {
                title: t('assetMonitoring.preferences.cards.findCompanies.headers.number'),
                align: 'right',
                key: 'regNumber',
                width: '150px',
            },
        ]
    })

    const fetchStreamedResults = async () => {
        modelItems.value = []
        await jsonStreamReader({
            url: getApiUri() + `/search/company/${ modelSearch.value }`,
            onChunkPayload: (chunk: JsonStreamPayload<ICompanySearch>) => {
                const { Address: address, Name: name, RegNumber: regNumber } = chunk.payload

                // don't add items without a registration number
                if (isNullOrWhitespace(regNumber)) {
                    return
                }
                modelItems.value.push({
                    address,
                    name,
                    regNumber,
                    id: uniqueId('company_'),
                    searchRef: {
                        id: searchId.value.id,
                        searchText: searchId.value.searchText,
                    },
                } as IAssetMonitoringCompanyGroupItem)
            },
        })
    }

    const onSearch = async () => {
        loading.value = true
        searchId.value = {
            id: uniqueId(),
            searchText: modelSearch.value,
        }
        modelSelected.value = null
        try {
            await fetchStreamedResults()
        } finally {
            loading.value = false
        }
    }

    const onItemsSelected = (selectedItems: any[]) => {
        modelSelected.value = {
            searchRef: {
                id: searchId.value.id,
                searchText: searchId.value.searchText,
            },
            selected: selectedItems.map((key) => {
                return modelItems.value.find((item) => item.id === key)
            }),
        }
    }
</script>

<style scoped lang="scss">
@import './find-companies-card.scss';
</style>