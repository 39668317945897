import httpClient from '@/api/http-client'
import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
import { NotificationType } from '@/components/asset-monitoring/notification-type.enum'
import {IHttpClientResponse} from '@/interfaces/http-client-response.interface'
import { getNotificationHubUri } from '@/utils/environment.utils'

/**
 * Represents the sorting options for notifications.
 * @enum {string}
 */
export enum NotificationSorting {
    dateAscending = 'DateAsc',
    dateDescending = 'DateDesc',
}

/**
 * Represents the request to get notifications.
 */
export type IGetNotificationsRequest = {
    dateSince?: Date,
    pageIndex?: number,
    pageSize?: number,
    sortBy?: NotificationSorting,
    filters?: string[],
    titleNumbers?: string[],
    types?: NotificationType[],
    subTypes?: NotificationSubType[],
    companyNumbers?: string[],
    readStatus?: string,
}

export type IGetNotificationResponseItem = {
    createdOn?: string,
    isRead?: boolean,
    matterId?: number,
    message?: string,
    notificationId: string,
    organisationId?: number,
    subType: string,
    titleNumber?: string,
    type: string,
    userNotificationId?: string,
    companyNumbers?: string[],
    notificationDetails: Array<INotificationDetailResponseItem>
}

export type INotificationDetailResponseItem = {
    notificationSubType: string,
    difference?: string
}

export type ITitleNotificationSettings = {
    trackDaylist?: boolean,
    trackOcda?: boolean,
    trackOwnership?: boolean,
    trackBoundary?: boolean,
    dailySummaryEmailEnabled?: boolean,
    trackCompaniesHouse?: boolean,
    trackEpc?: boolean,
    isActive?: boolean,
}

export type INotificationMessageArguments = {
    notificationId: string,
    type: NotificationSubType,
    titleNumber: string,
    message: string,
}

export type IUnreadNotificationsCount = {
    unreadTitleNotifications: number,
    unreadCompanyNotifications: number,
}

/**
 * @class NotificationsApi
 * This class provides methods to interact with the notification API.
 */
export default class NotificationsApi {
    static updateController: AbortController = null

    static END_POINT = `${ getNotificationHubUri() }/api/notification`

    /**
     * Retrieve notifications by matter ID.
     * @returns {Promise<any>} - A promise that resolves with the retrieved notifications.
     * @param matterId
     * @param options
     */
    static getNotificationsByMatter(matterId: number, options: IGetNotificationsRequest = {}): Promise<IHttpClientResponse> {
        let uri = `${ this.END_POINT }/matter/${ matterId }`

        const request = {
            ...options,
            since: options.dateSince?.toISOString(),
        }
        // No need to send this one too as it's called `since` in the API request.
        delete request.dateSince

        return httpClient.post(uri, request)
    }

    /**
     * Dismisses notifications for a user.
     *
     * @param {Array<string>} notificationIds - The IDs of the notifications to dismiss.
     * @param {boolean} isRead - Specifies if the notifications should be marked as read.
     * @return {Promise<IHttpClientResponse>} - A promise that resolves to an HTTP client response.
     */
    static dismissNotifications(notificationIds: Array<string>, isRead: boolean): Promise<IHttpClientResponse> {
        return httpClient.put(`${ this.END_POINT }/user-notification`, {
            notificationIds,
            isRead,
        })
    }

    /**
     * Retrieves the title notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     *
     * @return {Promise<IHttpClientResponse<ITitleNotificationSettings>>} - A Promise that resolves to the HTTP response containing the notification settings.
     */
    static getTitleNotificationSettings(matterId: number): Promise<IHttpClientResponse<ITitleNotificationSettings>> {
        return httpClient.get(`${ this.END_POINT }/matter/${ matterId }/settings`)
    }

    /**
     * Updates the title notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter to update the notification settings for.
     * @param {ITitleNotificationSettings} request - The new notification settings to be applied.
     *
     * @returns {Promise<IHttpClientResponse<ITitleNotificationSettings>>} A promise that resolves to the updated notification settings.
     */
    static updateTitleNotificationSettings(matterId: number, request: ITitleNotificationSettings): Promise<IHttpClientResponse<ITitleNotificationSettings>> {
        if (this.updateController) {
            this.updateController.abort()
        }

        this.updateController = new AbortController()
        return httpClient.post(`${ this.END_POINT }/matter/${ matterId }/settings`, request)
    }

    static getUnreadNotificationsCount(matterId: number): Promise<IHttpClientResponse<IUnreadNotificationsCount>> {
        return httpClient.get(`${ this.END_POINT }/matter/${ matterId }/unread-notifications`)
    }
}
