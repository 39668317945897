<template>
    <thead>
        <tr>
            <th class="label-caps-small base-sub-type-item__table--heading base-sub-type-item__table--heading-type">
                {{ t('label.type') }}
            </th>

            <template v-if="!isNullOrEmpty(detailColumns)">
                <th v-for="heading in detailColumns"
                    class="label-caps-small base-sub-type-item__table--heading">
                    {{ heading.title }}
                </th>
            </template>
            <template v-else>
                <th class="label-caps-small base-sub-type-item__table--heading base-sub-type-item__table--heading-type">
                    {{ t('label.label') }}
                </th>
                <th class="label-caps-small base-sub-type-item__table--heading">
                    {{ t('label.details') }}
                </th>
            </template>
        </tr>
    </thead>
</template>

<script setup lang="ts" generic="T extends IBaseSubTypeTableColumn<T>">
    import { useI18n } from "vue-i18n"

    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const { t } = useI18n()
    defineProps<{
        detailColumns?: T[]
    }>()
</script>

<style lang="scss" scoped>
    @import "../base-sub-type-item";
</style>
