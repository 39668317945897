<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         data-test="ownership-change"
                         data-track-type="Ownership change"
                         :headings="headings"
                         :notification="item" />
</template>

<script lang="ts" setup generic="T">
    import {
        computed,
    } from 'vue'
    import { useI18n } from "vue-i18n"

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"

    defineProps<{
        item: IAssetMonitoringNotificationDetail,
    }>()

    const updateCount = defineModel<number>('update-count')
    const { t } = useI18n()

    const headings = computed<IBaseSubTypeTableColumn<T>[]>(() => ([
        {
            field: 'name',
            title: t('assetMonitoring.dataGrid.ownership.name'),
            dataTest: 'name',
        },
        {
            field: 'address',
            title: t('assetMonitoring.dataGrid.ownership.address'),
            dataTest: 'address',
        },
    ]))

</script>

<style lang="scss" scoped>
    @import './ownership-change';
</style>
