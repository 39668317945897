<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         data-test="pending-applications"
                         data-track-type="Pending applications"
                         :detail-columns="detailColumns"
                         render-details-column
                         :notification="item" />
</template>

<script lang="ts" setup generic="T">
    import {
        computed,
        PropType,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"

    defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
    })

    const updateCount = defineModel<number>('update-count')

    const { t } = useI18n()

    const detailColumns = computed<IBaseSubTypeTableColumn<T>[]>(() => ([
        {
            field: 'applicationReference',
            title: t('assetMonitoring.subType.pendingApplicationsUpdated.applicationReference'),
            dataTest: 'applicationReference',
        },
        {
            field: 'customerReference',
            title: t('assetMonitoring.subType.pendingApplicationsUpdated.customerReference'),
            dataTest: 'customerReference',
        },
        {
            field: 'status',
            title: t('label.status'),
            dataTest: 'status',
        },
    ]))

    defineExpose({
        updateCount,
    })
</script>

<style lang="scss" scoped>
    @import "completed-pending-application.scss";
</style>
