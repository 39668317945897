import ReportApi from '@/api/report.api'
import TitleInformationApi from '@/api/title-information.api'
import { ApplicationRoles } from '@/enums/application-roles.enum'
import { IExportTemplateOptions } from "@/interfaces/export-template-options.interface"
import { customReportOptions } from "@/store/modules/reports/utils"
import { isNullOrEmpty } from '@/utils/array-utils'

import {
    LOAD_EXPORT_OPTIONS,
    LOAD_TITLE_AVAILABILITY,
    MUTATE_EXPORT_OPTIONS,
    MUTATE_EXPORT_OPTIONS_LOADING,
    MUTATE_TITLE_AVAILABILITY,
    MUTATE_TITLE_AVAILABILITY_LOADING,
} from './types'

export default {
    async [LOAD_EXPORT_OPTIONS]({ rootState, commit, state }) {
        // If templates already loaded, then don't go and load them again.
        if (!isNullOrEmpty(state.exportOptions)) {
            return
        }

        commit(MUTATE_EXPORT_OPTIONS_LOADING, true)

        try {
            // NOTE: Build export options as some are in the reporting API and others in the main API
            const exportOptions: Array<IExportTemplateOptions> = []
            const response = await ReportApi.getExportOptions()
            const hasCopilotRole = rootState.user.roles.includes(ApplicationRoles.Copilot)

            customReportOptions.forEach((option) => {
                // check the root state feature flags for the option feature flags
                if (option.enabled?.(rootState.config.featureFlags) ?? true) {
                    // if the option is a copilot option and the user has the copilot role, add the option
                    if (option?.copilot && hasCopilotRole) {
                        exportOptions.push(option)
                    } else if (!option?.copilot) {
                        exportOptions.push(option)
                    }
                }
            })

            if (response.ok && 'data' in response) {
                exportOptions.push(...response.data)
            }

            if (!isNullOrEmpty(exportOptions)) commit(MUTATE_EXPORT_OPTIONS, exportOptions)

            return response
        } catch (error) {
            return { ok: false, error }
        } finally {
            commit(MUTATE_EXPORT_OPTIONS_LOADING, false)
        }
    },

    async [LOAD_TITLE_AVAILABILITY]({ commit }, { matterId, titleNumbers }) {
        commit(MUTATE_TITLE_AVAILABILITY_LOADING, true)

        try {
            const response = await TitleInformationApi.getTitleAvailability(matterId, titleNumbers)

            commit(MUTATE_TITLE_AVAILABILITY, response)
        } catch (error) {
            const unavailableTitles = titleNumbers.reduce((acc, titleNumber) => ({
                ...acc,
                [titleNumber]: false,
            }), {})

            commit(MUTATE_TITLE_AVAILABILITY, unavailableTitles)
        } finally {
            commit(MUTATE_TITLE_AVAILABILITY_LOADING, false)
        }
    },
}
