<template>
    <div class="review-assistant"
         data-test="review-assistant">
        <header class="review-assistant__header">
            <section class="review-assistant__header--description">
                <ow-register-status-icon :is-register-ordered="isRegisterPurchased"
                                         :is-register-uploaded="isRegisterUploaded"
                                         :response-date-value="registerAvailability?.responseDateValue"
                                         should-pulse />
                <h1 class="headers-h1-page-title"
                    data-test="review-assistant-page-title">
                    {{ titleNumber }} - Register
                </h1>
                <template v-if="isLoading">
                    <ow-loading-skeleton height="24px"
                                         width="125" />
                    <ow-loading-skeleton height="24px"
                                         width="143" />
                </template>
                <div v-else
                     class="review-assistant__header--issue-date">
                    <div class="caption-regular">
                        Edition: {{ editionDate }}
                    </div>
                    <div class="caption-regular">
                        Issued On: {{ issuedDate }}
                        <span v-if="isBackdated">
                            ({{ $t('label.backdated') }})
                        </span>
                    </div>
                    <ow-icon-text v-if="issuedOverThreeMonthsAgo"
                                  :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="$t('documents.message.oldTitleRegister')"
                                  data-test="review-assistant-old-title-register"
                                  icon="$flag" />
                </div>
            </section>
            <ow-search v-model="filterTitlesText"
                       :search-result-index="searchResultIndex"
                       :total-search-result-count="filterTitlesText && totalSearchResultCount ? totalSearchResultCount : 0"
                       :search-context="activeDocumentTabText"
                       parent-class="review-assistant__header--description"
                       location="left"
                       :height="57"
                       auto-width
                       @next-button-click="handleNextButtonClick"
                       @previous-button-click="handlePreviousButtonClick" />
            <ow-export-button-menu :current-matter-id="matterId"
                                   :disabled="isLoading"
                                   :selected-title-numbers="[titleNumber]"
                                   :tooltip-position="OwTooltipPosition.Left"
                                   button-height="56px"
                                   class="review-assistant__header--export"
                                   data-test="review-assistant-export-button"
                                   heap-tracker-prefix="REVIEW_ASSISTANT"
                                   is-borderless
                                   is-full-height
                                   is-small />
            <open-copilot-button v-if="showCopilotButton"
                                 full-width
                                 full-height
                                 send-to-copilot
                                 is-borderless
                                 track-position="Review Assistant"
                                 :title="$t('action.sendToOrbitalCopilot')"
                                 data-test="open-copilot-button" />
            <router-link :to="backLink"
                         data-test="review-assistant-close-button">
                <ow-button class="review-assistant__header--close"
                           full-height
                           icon
                           is-borderless
                           large>
                    <v-icon>$close</v-icon>
                </ow-button>
            </router-link>
        </header>
        <register-tab :date-map="dateMap"
                      :documents="documents"
                      :entry-types="entryTypes"
                      :is-loading="isLoading"
                      :registers="registers"
                      :schedule-of-leases="scheduleOfLeases"
                      :search-text="filterTitlesText"
                      :is-register-uploaded="isRegisterUploaded && !isRegisterPurchased"
                      class="review-assistant__register"
                      @entry-clicked="onEntryClicked"
                      @view-link-clicked="onViewLinkClicked"
                      @match-count-changed="registerSearchResultCount = $event"
                      @highlight-dates="filterTitlesText = ''"
                      @view-plan-clicked="onViewPlanClicked" />
        <document-tab :matter-id="matterId"
                      :title-number="titleNumber"
                      :active-tab="activeDocumentTab"
                      :in-focus="isSearchFocusOnDocument"
                      :pdf-page="pdfPage"
                      :register-metadata="registerMetadata"
                      :related-document-metadata="relatedDocumentMetadata"
                      :search-index="documentSearchResultIndex"
                      :search-text="filterTitlesText"
                      :title-plan-metadata="titlePlanMetadata"
                      class="review-assistant__document"
                      @page-changed="pageChangedHandler"
                      @search-count-changed="documentSearchResultCount = $event"
                      @tab-changed="tabChangeHandler"
                      @upload-documents="documentsUploadHandler" />
    </div>
</template>

<script lang="ts">
    import {
        provide,
        ref } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapState,
        useStore,
    } from 'vuex'

    // APIs
    import DocumentsApi from '@/api/documents.api'
    import TitleInformationApi from '@/api/title-information.api'
    import OpenCopilotButton from '@/components/copilot/open-copilot-button.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwRegisterStatusIcon from '@/components/core/ow-register-status-icon.vue'
    import OwSearch from '@/components/core/ow-search.vue'
    import { OW_TEXT_HIGHLIGHT_SELECTOR } from '@/components/core/ow-text-highlight.vue'
    import OwExportButtonMenu from '@/components/reporting/export-button-menu.vue'
    // Components
    import DocumentTab from '@/components/review-assistant/document-tab.vue'
    import RegisterTab from '@/components/review-assistant/register-tab.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { ApplicationRoles } from '@/enums/application-roles.enum'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import featureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    // Interfaces
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { IRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import { ReviewAssistantDocumentTab } from '@/interfaces/review-assistant.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        TITLE_GET_IS_REGISTER_PURCHASED,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'
    import { differenceInMonths } from '@/utils/date-utils'

    export default {
        name: 'ReviewAssistantPage',

        components: {
            OwButton,
            OwExportButtonMenu,
            OwIconText,
            OwLoadingSkeleton,
            RegisterTab,
            DocumentTab,
            OwRegisterStatusIcon,
            OpenCopilotButton,
            OwSearch,
        },
        mixins: [
            featureFlagsMixin,
        ],

        beforeRouteEnter(_, from, next) {
            next((vm: any) => {
                // Set where the user has come from for the back link when the page loads.
                vm.entryPoint = from.fullPath
            })
        },

        setup() {
            const filterTitlesText = ref(null)
            provide('FILTER_PROVIDER', filterTitlesText)

            return {
                filterTitlesText,
                // eslint-disable-next-line vue/no-unused-properties
                store: useStore(),
            }
        },

        data() {
            return {
                OwTooltipPosition,

                registerMetadata: null,
                titlePlanMetadata: null,
                relatedDocumentMetadata: null,

                activeDocumentTab: ReviewAssistantDocumentTab.TitleRegister,
                isScrollSearch: false,
                pdfPage: 1,
                scheduleOfLeases: [] as IScheduleOfLeaseEntry[],
                searchResultIndex: 1,
                documentSearchResultIndex: 0,

                registerSearchResultCount: 0,
                documentSearchResultCount: 0,

                entryPoint: '',
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state: IState) => state.matter.currentMatter,
                selectedTitle: (state: IState) => state.title?.selectedTitle,
                registerAvailability: (state: IState) => state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister,
                titlePlanAvailability: (state: IState) => state.title?.selectedTitle?.officialCopiesAvailability?.results?.titlePlan,
                hasCopilotRole: (state: IState) => state.user.roles.includes(ApplicationRoles.Copilot),
            }),

            ...mapGetters({
                isRegisterPurchasedGetter: TITLE_GET_IS_REGISTER_PURCHASED,
            }),

            internalRouteName(): string {
                return this.$route.name
            },

            internalRouteHash(): string {
                return this.$route.hash
            },

            titleNumber(): string {
                return this.$route.params.titleNumber
            },

            matterId(): number {
                return Number(this.$route.params.matterId)
            },

            isLoading(): boolean {
                return !this.selectedTitle?.record
            },

            // Title Register issued on date is over 3 months ago
            issuedOverThreeMonthsAgo(): boolean {
                if (this.registerAvailability?.responseDateValue) {
                    const issuedOnDate = new Date(this.registerAvailability.responseDateValue)
                    return differenceInMonths(new Date(), issuedOnDate) >= 3
                }

                return false
            },

            dateMap(): any {
                const data = this.selectedTitle.record?.bgProprietorshipData

                if (!data) {
                    return new Map()
                }

                const entries = Object.keys(data)
                    .map(key => data[key]?.entry)
                    .filter(entry => entry)
                    .flat()
                    .map(({ code, date }) => [code, date]) as [string, string][]

                return new Map(entries)
            },

            registers(): IRegister[] {
                if (this.isLoading) {
                    return []
                }

                return this.selectedTitle.record?.bgProprietorshipData?.registers ?? []
            },

            documents(): IReferencedDocument[] {
                if (this.isLoading) {
                    return []
                }

                return this.selectedTitle.record?.bgProprietorshipData?.documentDetails ?? []
            },

            editionDate(): string {
                return this.selectedTitle.officialCopiesAvailability?.results?.titleRegister?.editionDate ?? ''
            },

            issuedDate(): string {
                return this.selectedTitle.officialCopiesAvailability?.results?.titleRegister?.responseDate ?? ''
            },

            entryTypes(): IRegisterLinkedIndicator[] {
                if (this.isLoading) {
                    return []
                }

                return this.selectedTitle.record?.bgProprietorshipData?.linkedIndicators?.filter(({ codes }) => codes.length) ?? []
            },

            isBackdated(): boolean {
                return !this.isLoading && this.selectedTitle.record.titleMetadata?.isBackOrderCopy
            },

            totalSearchResultCount(): number {
                return this.registerSearchResultCount + this.documentSearchResultCount
            },

            isSearchFocusOnDocument(): boolean {
                return this.searchResultIndex > this.registerSearchResultCount
            },

            activeDocumentTabFromHash(): ReviewAssistantDocumentTab {
                if (this.$route.hash === '#register') {
                    return ReviewAssistantDocumentTab.TitleRegister
                }

                if (this.$route.hash === '#plan') {
                    return ReviewAssistantDocumentTab.TitlePlan
                }

                if (this.$route.hash === '#related' && this.relatedDocumentMetadata) {
                    return ReviewAssistantDocumentTab.RelatedDocument
                }

                return ReviewAssistantDocumentTab.TitleRegister
            },

            activeDocumentTabText(): string {
                switch (this.activeDocumentTab) {
                    case (ReviewAssistantDocumentTab.TitleRegister):
                        return 'register'
                    case (ReviewAssistantDocumentTab.TitlePlan):
                        return 'plan'
                    default:
                        return 'document'
                }
            },

            backLink(): any {
                // If navigated to review assistant directly, return user to map / title panel page
                if (this.entryPoint === '/') {
                    return {
                        name: Route.MatterMapTitle,
                        params: {
                            matterId: this.matterId,
                            titleNumber: this.titleNumber,
                        },
                    }
                }

                // Navigate back to map page
                return this.entryPoint
            },

            isRegisterPurchased(): boolean {
                return this.isRegisterPurchasedGetter
            },

            isRegisterUploaded(): boolean {
                return this.selectedTitle?.officialCopiesAvailability?.results?.titleRegister?.documentSource === DOCUMENT_SOURCE.UPLOADED
            },

            showCopilotButton(): boolean {
                return this.hasCopilotRole
            },
        },

        watch: {
            'internalRouteName': {
                async handler() {
                    await this.handleRouteChange()
                },
                immediate: true,
            },

            'internalRouteHash': {
                async handler() {
                    if (this.$route.name == Route.ReviewAssistant) this.activeDocumentTab = this.activeDocumentTabFromHash
                },
                immediate: true,
            },

            registerAvailability: {
                handler(value) {
                    if (value) {
                        this.registerMetadata = {
                            documentType: 'Register',
                            documentDownloadUrl: value.downloadUrl,
                            documentId: value.documentId,
                        }
                    }
                },
                immediate: true,
                deep: true,
            },

            titlePlanAvailability: {
                async handler(value) {
                    if (!value) {
                        return
                    }

                    if (!value.documentId) {
                        this.titlePlanMetadata = value
                    } else {
                        this.titlePlanMetadata = await DocumentsApi.getDocumentMetadataByTypeAndId(
                            HighLevelDocumentType.TitlePlan,
                            value.documentId,
                        )
                    }
                },
                immediate: true,
                deep: true,
            },

            filterTitlesText(newVal, oldVal) {
                if (newVal !== oldVal) {
                    if (this.isScrollSearch) {
                        // If scrolling, then don't do the resetSearch() as it will reset the scroll position
                        this.isScrollSearch = false
                    } else {
                        this.resetSearch()
                    }
                }
            },

            searchResultIndex(value: number, previousValue: number) {
                const markers = document.querySelectorAll(`[${ OW_TEXT_HIGHLIGHT_SELECTOR }]`)
                this.setFocus(markers[value - 1], true, { behavior: 'smooth' })
                this.setFocus(markers[previousValue - 1], false)

                if (value > this.registerSearchResultCount) {
                    // pdf.js can only jump forward/backward by firing a 'find' event with a bool 'findPrevious' property
                    // ow-pdf-viewer triggers this event by watching the int nextSearchResult prop (positive = forward, negative = backward)
                    // therefore, to jump inside the pdf, nextSearchResult should be given a new value (positive for forward, negative for backward)

                    // except for the first forward jump when focus is already on the first search result
                    if (value > previousValue && value == this.registerSearchResultCount + 1) {
                        return
                    }

                    const direction = value > previousValue ? 1 : -1
                    this.documentSearchResultIndex = value * direction
                }
            },
        },

        methods: {
            ...mapActions({
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),

            async handleRouteChange(): Promise<void> {
                if (this.$route.name == Route.ReviewAssistant) {
                    this.activeDocumentTab = this.activeDocumentTabFromHash

                    await this.lookupTitle(this.titleNumber)
                    this.filterTitlesText = ''

                    // if navigated to review assistant with a title without available register
                    if (!this.isRegisterPurchased && !this.isRegisterUploaded) {
                        const titles = this.currentMatter?.selectedTitles ?? []
                        const inCurrentMatter = titles.some(title => title.titleNumber === this.titleNumber)

                        if (inCurrentMatter) {
                            // if title is in the matter, navigate to matter map page
                            await this.$router.push({
                                name: Route.MatterMapTitle,
                                params: {
                                    titleNumber: this.titleNumber,
                                    matterId: this.matterId,
                                },
                            })
                        } else {
                            // otherwise, go to homepage
                            await this.$router.push({ name: Route.Homepage })
                        }
                    } else {
                        const scheduleOfNoticeOfLeasesList = await TitleInformationApi.getTitleScheduleOfNoticeOfLeases(this.matterId, [this.titleNumber])
                        this.scheduleOfLeases = scheduleOfNoticeOfLeasesList[0]
                    }
                }
            },

            onViewLinkClicked(document: IDocumentMetadata): void {
                this.activeDocumentTab = ReviewAssistantDocumentTab.RelatedDocument
                this.resetSearch()

                this.relatedDocumentMetadata = {
                    ...document,
                }
            },

            onViewPlanClicked(): void {
                this.activeDocumentTab = ReviewAssistantDocumentTab.TitlePlan
                this.resetSearch()
            },

            onEntryClicked(entryText: string): void {
                this.activeDocumentTab = ReviewAssistantDocumentTab.TitleRegister
                this.isScrollSearch = true
                this.documentSearchResultIndex = 1
                this.searchResultIndex = 1
                this.filterTitlesText = entryText
                this.highlightFirstSearchResult()
            },

            setFocus(element: any, focused = true, options = {}): void {
                if (!element) {
                    return
                }

                if (focused) {
                    element.scrollIntoView({ block: 'center', ...options })
                    element.classList.add('selected')
                } else {
                    element.classList.remove('selected')
                }
            },

            async tabChangeHandler(newTab: ReviewAssistantDocumentTab): Promise<void> {
                await this.$router.push({
                    name: Route.ReviewAssistant,
                    params: {
                        matterId: this.matterId,
                        titleNumber: this.titleNumber,
                    },
                    hash: ['#register', '#plan', '#related'][newTab],
                })

                this.resetSearch()
            },

            handlePreviousButtonClick(): void {
                if (this.searchResultIndex > 1) {
                    this.searchResultIndex--
                } else {
                    this.searchResultIndex = this.totalSearchResultCount
                }
            },

            handleNextButtonClick(): void {
                if (this.searchResultIndex < this.totalSearchResultCount) {
                    this.searchResultIndex++
                } else {
                    this.searchResultIndex = 1
                }
            },

            resetSearch(): void {
                this.documentSearchResultIndex = 1
                this.searchResultIndex = 1
                this.pdfPage = 1

                this.highlightFirstSearchResult()
            },

            highlightFirstSearchResult(): void {
                this.$nextTick(() => {
                    const marker = document.querySelector(`[${ OW_TEXT_HIGHLIGHT_SELECTOR }]`)
                    this.setFocus(marker)
                })
            },

            pageChangedHandler(newPage: number): void {
                this.pdfPage = newPage
            },

            async documentsUploadHandler() {
                await this.$router.push({
                    name: Route.DocumentsLibrary,
                    hash: '#uploaded',
                    params: {
                        matterId: this.matterId,
                    },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import 'review-assistant.scss';
</style>
