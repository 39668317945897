<template>
    <ow-data-grid v-model="selectedGridItems"
                  data-test="find-companies-grid"
                  v-bind="$attrs"
                  :headers="headers"
                  :items="items"
                  :sort-by="sortBy"
                  fixed-header
                  item-key="id"
                  theme="light"
                  is-row-checkable
                  :is-loading="isLoading"
                  @update-data-sorted="onDataSorted"
                  @items-selected="$emit('items-selected', $event)" />
</template>

<script setup lang="ts">
    import {
        nextTick,
        ref,
        watch,
    } from "vue"

    import {
        IAssetMonitoringCompanyGroup,
        IAssetMonitoringCompanyGroupItem,
        IAssetMonitoringCompanyGroupSelected,
    } from "@/components/asset-monitoring/company-groups/types"
    import OwDataGrid from "@/components/core/ow-data-grid.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const props = defineProps<{
        items: IAssetMonitoringCompanyGroupItem[],
        selected: IAssetMonitoringCompanyGroupSelected | null,
        headers: any[],
        currentGroup: IAssetMonitoringCompanyGroup,
        isLoading?: boolean,
    }>()

    const selectedGridItems = ref<string[]>([])

    const sortBy = defineModel('sortBy', {
        default: [{ key: 'name', order: 'asc' }],
    })

    const emit = defineEmits<{
        (e: 'filter', value: string),
        (e: 'items-selected', value?: any[])
    }>()

    const onDataSorted = (_sorted: any[], key: string) => {
        emit('filter', key.replace('-', ''))
    }

    watch(() => props.selected, (value: IAssetMonitoringCompanyGroupSelected) => {
        if (!value?.selected) {
            selectedGridItems.value = []
            return
        }
    })

    watch(() => props.currentGroup, (group: IAssetMonitoringCompanyGroup) => {
        if (!group?.items) {
            selectedGridItems.value = []
            return
        }

        const itemsToRemove = []
        for (const item of selectedGridItems.value) {
            const found = group.items.find((i) => i.id === item)
            if (!found) {
                const itemIndex = selectedGridItems.value.indexOf(item)
                itemsToRemove.push(itemIndex)
            }
        }

        if (itemsToRemove.length > 0) {
            selectedGridItems.value = selectedGridItems.value.filter((_, index) => !itemsToRemove.includes(index))
        }

    })

</script>


<style scoped lang="scss">
@import './find-companies-grid.scss';
</style>