<template>
    <div class="sketches-list"
         data-test="sketches-list"
         :class="{'is-collapsed':isListCollapsed, 'top-nav-visible': isTopNavVisible}"
         @click="onListClick">
        <ow-sidebar-header :title="store.state?.matter?.currentMatter?.name"
                           header-data-test="matter-titles-header"
                           button-data-test="sketches-list-collapse-button"
                           button-data-track="sketches-list-collapse-button"
                           :collapsed="isListCollapsed"
                           @toggle="toggleCollapseList" />
        <section v-if="showSketchesActions && !Boolean(sketchesState.currentSketch)"
                 data-test="sketches-list-actions"
                 class="sketches-list__actions flex-shrink-1">
            <ow-button-ds :disabled="isSketchModeOptionsVisible"
                          is-secondary
                          full-width
                          data-test="sketches-list-add-sketch"
                          data-track="sketches-list-add-sketch"
                          @click="onAddClick">
                <label v-t="'sketches.addSketch'" />
            </ow-button-ds>
            <ow-button-ds is-secondary
                          full-width
                          data-test="sketches-list-import-sketch"
                          data-track="sketches-list-import-sketch"
                          @click="setUploadModalVisible(true)">
                <label v-t="'sketches.importSketches'" />
            </ow-button-ds>
            <ow-file-uploader :accepted-file-types="SKETCH_IMPORT_SUPPORTED_FILE_TYPES"
                              :accepted-file-extensions="SKETCH_IMPORT_SUPPORTED_FILE_EXTENSIONS"
                              :min-file-size="800"
                              :data-test-attribute="'sketches-list-import-modal'"
                              i18n-path-for-accepted-file-types-error="sketches.import.acceptedFileTypeError"
                              i18n-path-for-drop-zone-text="sketches.import.dropZoneText"
                              i18n-path-for-text="sketches.import.instructions"
                              i18n-path-for-title="sketches.import.uploadFiles"
                              multiple
                              @files="importSketchesHandler" />
        </section>
        <section class="layout column overflow-y-auto sketches-list__list">
            <section v-if="isLoading"
                     data-test="sketches-list-loading">
                <v-progress-linear v-if="!sketchesState.collapsedList"
                                   indeterminate
                                   color="primary" />
            </section>
            <section v-if="showNoSketchesPrompt"
                     data-test="sketches-list-no-sketches"
                     class="sketches-list__no-sketches">
                <h2 v-t="'sketches.noSketches.heading'"
                    class="headers-h2" />
                <div v-t="'sketches.noSketches.subheading'"
                     class="body-regular" />
                <a v-t="'sketches.noSketches.faqLinkText'"
                   class="caption-regular"
                   :href="t('sketches.noSketches.faqLinkUrl')"
                   target="_blank" />
            </section>
            <ul class="flex-shrink-1">
                <sketches-list-item v-for="sketch in sketches"
                                    :key="sketch.id"
                                    :alt-background="sketches.indexOf(sketch) % 2 === 0"
                                    :sketch="sketch"
                                    :selected="isSelected(sketch)"
                                    :active="isActive(sketch)"
                                    :unit-of-measurement="sketchesUnit"
                                    data-test="sketches-list-item"
                                    data-track="sketches-list-item"
                                    tag="li"
                                    @goto-sketch="gotoSketch"
                                    @select="selectSketch"
                                    @highlight="highightSketch"
                                    @deselect="deSelectSketch"
                                    @toggle-visibility="toggleSketchVisibility" />
            </ul>
        </section>
        <section class="layout column flex-grow-0 ma-0 pa-0 sketches-list__settings">
            <ow-button-ds v-if="!isListCollapsed"
                          v-t="'sketches.settings'"
                          full-width
                          full-height
                          is-borderless
                          is-secondary
                          class="py-3 sketches-list__settings-button"
                          @click="onShowSettingModal" />
            <ow-callout :model-value="showCallout"
                        :position="OwTooltipPosition.Top"
                        callout-id="first-sketch"
                        :max-width="380"
                        :context-id="matterId && matterId.toString()"
                        :highlight-offset="{top : '-10px'}"
                        :close-delay="5000"
                        persistence="LocalStorage"
                        activator="parent">
                <div class="sketches-list__callout">
                    {{ t('callouts.sketchesFirstSketch') }}
                </div>
            </ow-callout>
        </section>

        <sketches-options-panel v-if="showBulkOptions"
                                :anchor-element-selector="'#sketchesList'"
                                :anchor-offset="[-100, 0, 0, 0]"
                                :selected-sketches="selectedSketches"
                                @dismiss="onBulkOptionsDismiss"
                                @show-selection="onShowSelectedSketches"
                                @show-labels="onShowLabels"
                                @hide-labels="onHideLabels"
                                @hide-selection="onHideSelectedSketches"
                                @change-label="onChangeLabel"
                                @delete-sketches="onDeleteSketches"
                                @export-as="onExportAs"
                                @import-titles="onImportTitlesMenu" />

        <sketches-name-description-modal />
        <sketches-setting-modal />
        <sketches-title-import-modal :show-line-buffer="showLineBuffer"
                                     @import="handleImport" />
    </div>
</template>

<script setup lang="ts">
    import Feature from 'ol/Feature'
    import { computed,
             onBeforeMount,
             onMounted,
             onUnmounted,
             ref,
             watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButtonDs from '@/components/core/ow-button-ds.vue'
    import OwCallout from '@/components/core/ow-callout.vue'
    import OwFileUploader from '@/components/core/ow-file-uploader.vue'
    import OwSidebarHeader from '@/components/core/ow-sidebar-header.vue'
    import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
    import SketchesNameDescriptionModal from '@/components/sketches/sketch-name-description-modal.vue'
    import SketchesListItem from '@/components/sketches/sketches-list-item.vue'
    import SketchesOptionsPanel from '@/components/sketches/sketches-options-panel.vue'
    import SketchesSettingModal from '@/components/sketches/sketches-setting-modal.vue'
    import SketchesTitleImportModal from '@/components/sketches/sketches-title-import-modal.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { SKETCH_IMPORT_SUPPORTED_FILE_EXTENSIONS,
             SKETCH_IMPORT_SUPPORTED_FILE_TYPES } from '@/consts/sketch-consts'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { SketchExportFormat,
             SketchMode,
             SketchType } from '@/enums/sketches-enums'
    import { UploadFileExtensions } from '@/enums/upload-file-types.enum'
    import {SET_UPLOAD_DOCUMENTS_DIALOG_STATE} from "@/store/modules/documents/documents-types"
    import { MAP_UPDATE_SIZE } from '@/store/modules/map/types'
    import { MATTER_GET_IS_SKETCHES_VISIBLE_MAP,
             MATTER_GET_SKETCHES_UNIT,
             MATTER_UPDATE_SKETCHES_VISIBLE_MAP } from '@/store/modules/matter/types'
    import { ISketchesState } from '@/store/modules/sketches'
    import {
        SKETCHES_ACTIVATE_SKETCH,
        SKETCHES_EXPORT_SPATIAL_FILE,
        SKETCHES_IMPORT_TITLES,
        SKETCHES_LOAD_BY_MATTER_ID,
        SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES,
        SKETCHES_MUTATE_COLLAPSE_LIST,
        SKETCHES_MUTATE_CURRENT_SKETCH,
        SKETCHES_MUTATE_DESELECT_SKETCHES,
        SKETCHES_MUTATE_HIGHLIGHT_SKETCH,
        SKETCHES_MUTATE_SELECT_SKETCHES,
        SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL,
        SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
        SKETCHES_MUTATE_SHOW_SETTING_MODAL,
        SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
        SKETCHES_MUTATE_TARGET_MAP,
        SKETCHES_MUTATE_ZOOM_TO_SKETCH,
        SKETCHES_NEW_SKETCH,
        SKETCHES_REMOVE_SKETCH,
        SKETCHES_SET_MODE,
        SKETCHES_SET_SKETCHES_LABEL_VISIBILITY,
        SKETCHES_SET_SKETCHES_VISIBILITY,
        SKETCHES_TOGGLE_SKETCH_VISIBILITY,
    } from '@/store/modules/sketches/types'
    import { ISketch } from '@/store/modules/sketches/types/sketch'
    import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { fileExtensionFromFile } from '@/utils/file-utils'
    import {
        isSupportedImportFile,
        openLayersFeaturesFromGeoJSON,
        openLayersFeaturesFromKML,
        openLayersFeaturesFromKMZ,
        openLayersFeaturesFromShapefile,
    } from '@/utils/spatial-io'

    const { t } = useI18n()
    const store = useStore()
    const sketchesState:ISketchesState = store.state.sketches

    const displaySketches = computed({
        get: () => store.getters[MATTER_GET_IS_SKETCHES_VISIBLE_MAP],
        set: async (value) => {
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'SKETCHES - Toggle sketches map visibility',
                metadata: {
                    enabled: value,
                },
            })
            store.dispatch(MATTER_UPDATE_SKETCHES_VISIBLE_MAP, value)
        },
    })

    const sketchesUnit = ref(store.getters[MATTER_GET_SKETCHES_UNIT])
    watch(() => store.getters[MATTER_GET_SKETCHES_UNIT], (value) => {
        sketchesUnit.value = value
    })

    interface Props {
        matterId: number
    }
    const props = defineProps<Props>()

    onBeforeMount(async () => {
        await refreshList()
        store.commit(SKETCHES_MUTATE_TARGET_MAP, store.state.map.map)
    })
    const refreshList = () => store.dispatch(SKETCHES_LOAD_BY_MATTER_ID, props.matterId)
    const toggleCollapseList = () => {
        store.commit(SKETCHES_MUTATE_COLLAPSE_LIST, !isListCollapsed.value)
        requestAnimationFrame(() => {
            store.dispatch(MAP_UPDATE_SIZE)
        })
    }
    const onListClick = () => {
        if (isListCollapsed.value) {
            toggleCollapseList()
        }
    }
    const isListCollapsed = computed(() => sketchesState.collapsedList)
    const sketches = computed(() => sketchesState.sketches)
    const isLoading = computed(() => sketchesState.loading)
    const showNoSketchesPrompt = computed(() => !isLoading.value && !sketches.value.length && !sketchesState.collapsedList)
    const showSketchesActions = computed(() => !isLoading.value && !sketchesState.collapsedList)
    const isSketchModeOptionsVisible = computed(() => sketchesState.showSketchModeOptions)
    const { isTopNavVisible } = useMapTopNav()

    // Sketch selection.
    const selectedSketches = computed(() => sketchesState.selectedSketches)
    const isSelected = (sketch: ISketch) => selectedSketches.value.includes(sketch)
    const isActive = (sketch: ISketch) => sketchesState.currentSketch === sketch
    const selectSketch = (sketch: ISketch) => store.commit(SKETCHES_MUTATE_SELECT_SKETCHES, [sketch])
    const highightSketch = (sketch: ISketch) => store.commit(SKETCHES_MUTATE_HIGHLIGHT_SKETCH, { sketch })
    const deSelectSketch = (sketch: ISketch) => store.commit(SKETCHES_MUTATE_DESELECT_SKETCHES, [sketch])

    // Visibility.
    const toggleSketchVisibility = (sketch: ISketch) =>
        store.dispatch(SKETCHES_TOGGLE_SKETCH_VISIBILITY, sketch)
    const onShowSelectedSketches = async () => {
        await store.dispatch(SKETCHES_SET_SKETCHES_VISIBILITY, { matterId: props.matterId, sketches: selectedSketches.value, visible: true })
    }
    const onHideSelectedSketches = async () => {
        await store.dispatch(SKETCHES_SET_SKETCHES_VISIBILITY, { matterId: props.matterId, sketches: selectedSketches.value, visible: false })
    }
    const onShowLabels = async () => {
        await store.dispatch(SKETCHES_SET_SKETCHES_LABEL_VISIBILITY, { matterId: props.matterId, sketches: selectedSketches.value, visible: true })
    }
    const onHideLabels = async () => {
        await store.dispatch(SKETCHES_SET_SKETCHES_LABEL_VISIBILITY, { matterId: props.matterId, sketches: selectedSketches.value, visible: false })
    }

    // Goto sketch.
    const gotoSketch = async (sketch: ISketch) => {
        await store.dispatch(SKETCHES_ACTIVATE_SKETCH, sketch)
    }

    // Bulk Actions
    const showBulkOptions = computed(() => sketchesState.selectedSketches.length > 0)
    const onBulkOptionsDismiss = () => {
        store.commit(SKETCHES_MUTATE_DESELECT_SKETCHES, selectedSketches.value)
    }
    const onChangeLabel = () => {
        if (selectedSketches.value.length === 1) {
            store.commit(SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL, { show: true, sketch: selectedSketches.value[0] })
        }
    }

    const onShowSettingModal = () => {
        store.commit(SKETCHES_MUTATE_SHOW_SETTING_MODAL, {
            show: true,
            sketches: selectedSketches.value,
        })
    }

    const onDeleteSketches = async () => {
        if (selectedSketches.value.includes(sketchesState.currentSketch)) {
            await store.dispatch(SKETCHES_SET_MODE, SketchMode.None)
            store.commit(SKETCHES_MUTATE_CURRENT_SKETCH, null)
        }
        await Promise.all(selectedSketches.value.map(async (sketch) => {
            await store.dispatch(SKETCHES_REMOVE_SKETCH, { matterId: store.state.matter.currentMatter.id, sketch })
        }))
        store.commit(SKETCHES_MUTATE_DESELECT_SKETCHES, selectedSketches.value)
    }

    const onExportAs = (format: SketchExportFormat) => {
        store.dispatch(SKETCHES_EXPORT_SPATIAL_FILE, {
            sketches: selectedSketches.value,
            format,
        })
    }

    const showLineBuffer = ref<boolean>(false)
    const onImportTitlesMenu = async () => {
        showLineBuffer.value = selectedSketches.value[0].sketchType === SketchType.Line
        store.commit(SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL, true)
    }
    const handleImport = async (val) => {
        await store.dispatch(SKETCHES_IMPORT_TITLES, {
            sketch: selectedSketches.value[0],
            matterId: props.matterId,
            matterGroupId: val?.groupId,
            buffer: val?.buffer,
            onlyWithin: val.importOnlyWithinTitles,
        })
    }

    const onAddClick = async () => {
        store.commit(SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS, true)
        await store.dispatch(SKETCHES_SET_MODE, SketchMode.None)
        store.commit(SKETCHES_MUTATE_CURRENT_SKETCH, null)
    }

    onMounted(() => {
        sketchesState.layer?.setVisible(true)
        sketchesState.layer?.setOpacity(1)
    })
    onUnmounted(async () => {
        // The following code will remove the sketches from the main map
        // - we're going to disable this for now while the final UX is being worked out, but may re-enable it if it becomes annoying.
        // sketchesState.layer.setVisible(false)

        store.commit(SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES)
        store.commit(SKETCHES_MUTATE_CURRENT_SKETCH, null)
        await store.dispatch(SKETCHES_SET_MODE, SketchMode.None)
    })

    const firstSketch = computed(() =>
        sketchesState.sketches.length === 1 &&
        sketchesState.sketches[0].features.length > 0 &&
        sketchesState.currentMode === SketchMode.None)

    const showCallout = ref(false)
    watch(() => sketchesState.currentMode, () => {
        if (firstSketch.value) {
            showCallout.value = true
        }
    })

    // Sketches import
    const importSketchesHandler = async (filesToUpload: IOwFileUploaderEvent): Promise<void> => {
        try {
            const files = filesToUpload.formData.getAll('files') as Array<File>
            const newSketches: Array<ISketch> = []
            for (const file of files) {
                const supported = isSupportedImportFile(file)
                if (!supported) {
                    console.error(`Sketches import - unsupported file type ${ file.name }, ${ file.type } `)
                    continue
                }
                const fileExtension = fileExtensionFromFile(file)
                let features: Array<Feature> = []
                switch (fileExtension) {
                    case UploadFileExtensions.JSON:
                    case UploadFileExtensions.GEOJSON:
                        features = await openLayersFeaturesFromGeoJSON(file as any)
                        break
                    case UploadFileExtensions.SHP:
                        features = await openLayersFeaturesFromShapefile(file as any)
                        break
                    case UploadFileExtensions.KML:
                        features = await openLayersFeaturesFromKML(file as any)
                        break
                    case UploadFileExtensions.KMZ:
                        features = await openLayersFeaturesFromKMZ(file as any)
                        break
                    default:
                        console.error(`Sketches import - unsupported file extension ${ fileExtension }`)
                        break
                }

                if (features.length) {
                    const sketchesFromFile = SketchesFactory.newSketchesFromFeatures(file.name, features)
                    newSketches.push(...sketchesFromFile)
                }
            }
            for (const sketch of newSketches) {
                await store.dispatch(SKETCHES_NEW_SKETCH, { matterId: props.matterId, sketch })
            }
            if (newSketches.length === 1) {
                store.commit(SKETCHES_MUTATE_ZOOM_TO_SKETCH, newSketches[0])
            }
            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'SKETCHES - Import sketches',
                metadata: {
                    count: newSketches.length,
                },
            })
        } catch (e) {
            console.error('Error encountered when importing sketches', e)
        } finally {
            setUploadModalVisible(false)
        }
    }

    const setUploadModalVisible = (value) => store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, value)

    defineExpose({
        onShowSelectedSketches,
        onHideSelectedSketches,
        importSketchesHandler,
    })
</script>
<style lang="scss">
@import 'sketches-list.scss';
</style>
