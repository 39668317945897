<template>
    <base-sub-type-table v-model:update-count="updateCount"
                         data-test="oc2"
                         data-track-type="Oc2 change"
                         :detail-columns="detailColumns"
                         render-details-column
                         :notification="item" />
</template>

<script lang="ts" setup generic="T">
    import {
        computed,
        PropType,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BaseSubTypeTable
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table.vue"
    import { IBaseSubTypeTableColumn } from "@/components/asset-monitoring/grid/sub-type-items/types"
    import useDates from '@/composables/use-dates'

    defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
    })

    const { t } = useI18n()
    const { formatDateShort } = useDates()
    const updateCount = defineModel('update-count')

    const detailColumns = computed<IBaseSubTypeTableColumn<T>[]>(() => ([
        {
            field: 'type',
            title: t('label.document'),
            dataTest: 'oc2-change-row-document',
            customRender: (_row: T, value: string) => {
                return `<b>${ value }</b>`
            },
        },
        {
            field: 'date',
            title: t('label.updated'),
            dataTest: 'oc2-change-row-updated',
            customRender: (_row: T, value) => {
                return formatDateShort(value)
            },
        },
        {
            field: 'entryNumbers',
            title: t('label.entries'),
            dataTest: 'oc2-change-row-entries',
            customRender: (_row: T, value) => {
                return value.join(', ')
            },
        },
        {
            field: 'filedUnder',
            title: t('label.filedUnder'),
            dataTest: 'oc2-change-row-filed-under',
        },
    ]))

</script>

<style lang="scss" scoped>
    @import "oc2-document-updated.scss";
</style>
