import { MapBrowserEvent } from 'ol'
import Feature, { FeatureLike } from 'ol/Feature'
import MVT from "ol/format/MVT"
import VectorTileSource from "ol/source/VectorTile"
import {
    Fill,
    Stroke,
} from 'ol/style'
import Style, { StyleLike } from 'ol/style/Style'

import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import {LayerSnapshotModel} from "@/components/snapshots/map-snapshots/map-snapshot-models"
import { LayerNames } from '@/consts/map-layers'
import { Colors } from '@/enums/colors.enum'
import { CoordinateSystemCode } from "@/enums/coordinate-systems"
import i18n from '@/plugins/i18n'
import { geoserver27700TileGrid2048 } from "@/store/modules/map/layers/geoserver-27700-2048-tilegrid"
import { hexToRGBArray } from '@/utils/colour-utils'
import { getGeoserverApiUri } from "@/utils/environment.utils"

import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from './base-mvt-data-layer'
import { getUnregisteredRolloverOptions } from './unregistered-land-common'

export type UnregisteredLandFeatureType = {
    id: string,
    title_no: string,
    tenure: string
}

export type UnregisteredLandLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: ({ text, hoverCaption } : { text: Array<string>, hoverCaption: string },
                                event: MapBrowserEvent<any>) => void
    gridset2048Enabled?: boolean
    snapshotConfig: any
}

export class UnregisteredLandLayer extends BaseMvtDataLayer {
    public maxResolution = 3
    public opacity = 0.8

    constructor(params: UnregisteredLandLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:unregistered_land'
        if (params.snapshotConfig) {
            this.parseSnapshotConfig(params.snapshotConfig)
        }
        if (params.gridset2048Enabled) {
            this.vectorTileSource = new VectorTileSource({
                tileSize: 2048,
                format: new MVT(),
                attributions: this.attributionText,
                url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/${ this.layerName }@EPSG:27700_2048@pbf/{z}/{x}/{-y}.pbf`,
                transition: 0,
                projection: CoordinateSystemCode.EPSG27700,
                tileGrid: geoserver27700TileGrid2048,
                overlaps: true,
            })
        }
        this.name = LayerNames.UnregisteredLand
        this.rolloverOptions = getUnregisteredRolloverOptions(LayerNames.UnregisteredLand, this.style)
        this.initialiseLayer()
    }

    public style: StyleLike = (feature: Feature) => {
        return this.getStyleForFeature(feature)
    }

    private getStyleForFeature(feature: FeatureLike): Style {
        const properties: UnregisteredLandFeatureType = feature.getProperties() as UnregisteredLandFeatureType
        let colour = hexToRGBArray(Colors.EmphasiseUnregisteredColour)
        if (properties.tenure == 'CN') {
            colour = hexToRGBArray(Colors.CautionAgainstFirstRegistrationLayer)
            return new Style({
                fill: new Fill({
                    color: `rgba(${ colour }, ${ this.opacity })`,
                }),
                stroke: new Stroke({
                    color: `rgba(${ colour }, 0.6)`,
                    width: 1,
                }),
            })
        } else {
            return new Style({
                fill: new Fill({
                    color: `rgba(${ colour }, ${ this.opacity })`,
                }),
                stroke: new Stroke({
                    color: `rgba(${ colour }, 0.8)`,
                    width: 1,
                }),
            })
        }
    }

    private parseSnapshotConfig(snapshotConfig: any): void {
        const config = JSON.parse(snapshotConfig.configJson)
        this.opacity = config.opacity
    }

    public getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer.getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
        const keyItems: Array<KeyConfigItemModel> = []
        if (!featuresInExtent.length) {
            return keyItems
        }

        for (const f of featuresInExtent) {
            const properties: UnregisteredLandFeatureType = f.getProperties() as UnregisteredLandFeatureType
            if (properties.tenure == 'CN') {
                const colour = hexToRGBArray(Colors.CautionAgainstFirstRegistrationLayer)
                keyItems.push({
                    id: this.name,
                    label: i18n.global.t('map.options.unregistered_land.text_cn') as string,
                    style: {
                        fillColour: `rgba(${ colour }, 0.6)`,
                        strokeColour: 'transparent',
                    },
                })
                break
            }
        }

        const colour = hexToRGBArray(Colors.EmphasiseUnregisteredColour)
        keyItems.push({
            id: this.name,
            label: i18n.global.t('map.options.unregistered_land.text_unregistered_land') as string,
            style: {
                fillColour: `rgba(${ colour }, ${ this.opacity })`,
                strokeColour: 'transparent',
            },
        })

        return keyItems
    }

    public changeOpacity(opacity: number): void {
        this.opacity = opacity
        this.layer.setStyle(this.style)
        this.layer.changed()
    }

    public getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.UnregisteredLand,
            configJson: JSON.stringify({
                opacity: this.opacity,
            }),
        }
    }
}
