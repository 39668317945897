<template>
    <div class="style-panel">
        <div class="style-options tool-section">
            <label v-t="'style.selectStyle'"
                   class="label-caps-small" />
            <!-- Default styles -->
            <section>
                <style-option v-for="(style, index) in stylePresets"
                              :key="index"
                              :sketch-type="type"
                              :ow-style="style"
                              :is-selected="isSelected(style)"
                              is-style-preset
                              @click="selectStyle(style)" />
            </section>
        </div>
        <div v-if="showSizeOptions"
             data-test="sketches-styles-size-options"
             class="size-options tool-section">
            <!-- Marker style -->
            <label v-t="'style.size'"
                   class="label-caps-small" />
            <section>
                <style-option v-for="stroke in 6"
                              :key="stroke"
                              :data-test="`sketches-styles-size-${stroke}`"
                              :stroke-width="stroke"
                              :sketch-type="type"
                              is-style-preset
                              :ow-style="selectedStyle"
                              :is-selected="isSizeSelected(stroke)"
                              @click="selectSize(stroke)" />
            </section>
        </div>
        <div v-if="showArrowheadOptions"
             data-test="sketches-styles-arrowhead-options"
             class="arrowhead-options tool-section">
            <!-- Solid or outline -->
            <label v-t="'style.arrowhead'"
                   class="label-caps-small" />
            <section>
                <style-option :sketch-type="SketchType.Line"
                              class="arrowhead-option"
                              :ow-style="{
                                  strokeWidth: 4,
                                  strokeColour: selectedStyle.strokeColour,
                                  arrowhead: Arrowhead.None,
                              }"
                              :is-selected="isArrowheadSelected(Arrowhead.None)"
                              data-test="sketches-styles-arrowhead-none"
                              is-style-preset
                              @click="selectArrowhead(Arrowhead.None)" />
                <style-option :sketch-type="SketchType.Line"
                              class="arrowhead-option"
                              :ow-style="{
                                  strokeWidth: 4,
                                  strokeColour: selectedStyle.strokeColour,
                                  arrowhead: Arrowhead.End,
                              }"
                              :is-selected="isArrowheadSelected(Arrowhead.End)"
                              data-test="sketches-styles-arrowhead-end"
                              is-style-preset
                              @click="selectArrowhead(Arrowhead.End)" />
                <style-option :sketch-type="SketchType.Line"
                              class="arrowhead-option"
                              :ow-style="{
                                  strokeWidth: 4,
                                  strokeColour: selectedStyle.strokeColour,
                                  arrowhead: Arrowhead.Both,
                              }"
                              :is-selected="isArrowheadSelected(Arrowhead.Both)"
                              data-test="sketches-styles-arrowhead-both"
                              is-style-preset
                              @click="selectArrowhead(Arrowhead.Both)" />
            </section>
        </div>
        <!-- Advanced options -->
        <div class="advanced-options-toggle"
             data-test="sketches-styles-advanced-options-toggle"
             @click="toggleAdvancedOptions">
            <label v-t="'sketches.advancedOptions'"
                   class="accents-highlight" />
            <v-icon v-if="!showAdvancedOptions"
                    tabindex="0">
                $chevron-down
            </v-icon>
            <v-icon v-if="showAdvancedOptions"
                    tabindex="0">
                $chevron-up
            </v-icon>
        </div>
        <div v-if="showAdvancedOptions && selectedStyle"
             class="advanced-options">
            <section v-if="showStrokeOptions"
                     data-test="sketches-styles-stroke-options"
                     class="stroke-options tool-section">
                <div class="tool-section">
                    <!-- Stroke width -->
                    <label v-t="'style.stroke'"
                           class="label-caps-small" />
                    <section class="stroke-option">
                        <style-option :ow-style="selectedStyle"
                                      data-test="sketches-styles-stroke-2"
                                      :is-selected="isStrokeSelected(2)"
                                      @click="selectStroke(2)">
                            <svg width="26"
                                 height="24"
                                 viewBox="0 0 26 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 0C25 0 25 24 13 12C0.999996 1.90735e-06 1 24 1 24"
                                      :stroke="selectedStyle.strokeColour"
                                      stroke-width="2" />
                            </svg>
                        </style-option>
                        <style-option :ow-style="selectedStyle"
                                      data-test="sketches-styles-stroke-4"
                                      :is-selected="isStrokeSelected(4)"
                                      @click="selectStroke(4)">
                            <svg width="28"
                                 height="24"
                                 viewBox="0 0 28 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 0C26 0 26 24 14 12C2 1.90735e-06 2 24 2 24"
                                      :stroke="selectedStyle.strokeColour"
                                      stroke-width="4" />
                            </svg>
                        </style-option>
                        <style-option :ow-style="selectedStyle"
                                      data-test="sketches-styles-stroke-6"
                                      :is-selected="isStrokeSelected(6)"
                                      @click="selectStroke(6)">
                            <svg width="30"
                                 height="26"
                                 viewBox="0 0 30 26"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M27 1C27 1 27 25 15 13C3 1 3 25 3 25"
                                      :stroke="selectedStyle.strokeColour"
                                      stroke-width="6" />
                            </svg>
                        </style-option>
                    </section>
                </div>
                <div class="tool-section">
                    <!-- Stroke style -->
                    <label v-t="'style.solidOrDashed'"
                           class="label-caps-small" />
                    <section class="stroke-option tool-section">
                        <style-option :ow-style="selectedStyle"
                                      data-test="sketches-styles-solid"
                                      :is-selected="isSolidStyleSelected"
                                      @click="selectStrokeStyle(false)">
                            <svg width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="1"
                                      y="1"
                                      width="22"
                                      height="22"
                                      rx="3"
                                      :stroke="selectedStyle.strokeColour"
                                      stroke-width="2" />
                            </svg>
                        </style-option>
                        <style-option :ow-style="selectedStyle"
                                      data-test="sketches-styles-dash"
                                      :is-selected="isDashStyleSelected"
                                      @click="selectStrokeStyle(true)">
                            <svg width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="1"
                                      y="1"
                                      width="22"
                                      height="22"
                                      rx="3"
                                      :stroke="selectedStyle.strokeColour"
                                      stroke-width="2"
                                      stroke-dasharray="4 4" />
                            </svg>
                        </style-option>
                    </section>
                </div>
            </section>
            <div v-if="showFillOptions"
                 data-test="sketches-styles-fill-options"
                 class="tool-section">
                <!-- Fill style -->
                <label v-t="'style.fill'"
                       class="label-caps-small" />
                <section>
                    <style-option :ow-style="selectedStyle"
                                  data-test="sketches-styles-fill-02"
                                  :is-selected="isFillStyleSelected(0.2, false)"
                                  @click="selectFillStyle(0.2,false)">
                        <svg width="24"
                             height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1"
                                  y="1"
                                  width="22"
                                  height="22"
                                  rx="3"
                                  :fill="selectedStyle.strokeColour"
                                  fill-opacity="0.2"
                                  :stroke="selectedStyle.strokeColour"
                                  stroke-width="2" />
                        </svg>
                    </style-option>
                    <style-option :ow-style="selectedStyle"
                                  data-test="sketches-styles-fill-04"
                                  :is-selected="isFillStyleSelected(0.4, false)"
                                  @click="selectFillStyle(0.4,false)">
                        <svg width="24"
                             height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1"
                                  y="1"
                                  width="22"
                                  height="22"
                                  rx="3"
                                  :fill="selectedStyle.strokeColour"
                                  fill-opacity="0.4"
                                  :stroke="selectedStyle.strokeColour"
                                  stroke-width="2" />
                        </svg>
                    </style-option>
                    <style-option :ow-style="selectedStyle"
                                  data-test="sketches-styles-fill-06"
                                  :is-selected="isFillStyleSelected(0.6, false)"
                                  @click="selectFillStyle(0.6,false)">
                        <svg width="24"
                             height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1"
                                  y="1"
                                  width="22"
                                  height="22"
                                  rx="3"
                                  :fill="selectedStyle.strokeColour"
                                  fill-opacity="0.6"
                                  :stroke="selectedStyle.strokeColour"
                                  stroke-width="2" />
                        </svg>
                    </style-option>
                    <style-option :ow-style="selectedStyle"
                                  data-test="sketches-styles-fill-hatch"
                                  :is-selected="isFillStyleSelected(1, true)"
                                  @click="selectFillStyle(1,true)">
                        <svg width="24"
                             height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 14.5858V10.4142L10.4142 1H14.5858L1 14.5858ZM7.58579 1L1 7.58579V4C1 2.34315 2.34315 1 4 1H7.58579ZM17.2071 1.20711L17 1H20C20.4631 1 20.9018 1.10495 21.2934 1.29237L1.29237 21.2934C1.10495 20.9018 1 20.4631 1 20V17L1.20711 17.2071L17.2071 1.20711ZM2.70659 22.7076L22.7076 2.70659C22.895 3.09822 23 3.53685 23 4V7.5L22.7929 7.29289L7.29289 22.7929L7.5 23H4C3.53685 23 3.09822 22.895 2.70659 22.7076ZM9.91421 23L23 9.91421V14.5L22.7929 14.2929L14.2929 22.7929L14.5 23H9.91421ZM16.9142 23L23 16.9142V20C23 21.6569 21.6569 23 20 23H16.9142Z"
                                  :stroke="selectedStyle.strokeColour"
                                  stroke-width="2" />
                        </svg>
                    </style-option>
                    <style-option :ow-style="selectedStyle"
                                  data-test="sketches-styles-fill-none"
                                  :is-selected="isFillStyleSelected(0, false)"
                                  @click="selectFillStyle(0,false)">
                        <svg width="24"
                             height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1"
                                  y="1"
                                  width="22"
                                  height="22"
                                  rx="3"
                                  :stroke="selectedStyle.strokeColour"
                                  stroke-width="2" />
                        </svg>
                    </style-option>
                </section>
            </div>
            <div v-if="showTextColourOptions"
                 data-test="sketches-styles-text-options"
                 class="text-color-options">
                <div class="tool-section">
                    <!-- Colours -->
                    <label v-t="'style.textColour'"
                           class="label-caps-small" />
                    <section class="colour-options">
                        <button v-for="textColour in presetTextColours"
                                :key="textColour"
                                tabindex="0"
                                data-test="sketches-styles-text-colour"
                                class="colour-option text-colour-option"
                                :class="{ 'is-selected': isSelectedTextColour(textColour) }"
                                @click="selectTextColour(textColour)">
                            <div class="colour-option__inner text-colour-option__inner"
                                 :style="{background: textColour}" />
                        </button>
                    </section>
                </div>
                <div class="tool-section">
                    <!-- Solid or outline -->
                    <label v-t="'style.textSolid'"
                           class="label-caps-small" />
                    <section>
                        <style-option :sketch-type="SketchType.Text"
                                      :ow-style="{
                                          strokeWidth: 4,
                                          strokeColour: selectedStyle.strokeColour,
                                          fillColour: selectedStyle.fillColour,
                                      }"
                                      :is-selected="isSolidTextSelected()"
                                      data-test="sketches-styles-text-solid"
                                      is-style-preset
                                      @click="selectSolidOutlineText(1)" />
                        <style-option :sketch-type="SketchType.Text"
                                      :ow-style="{
                                          strokeWidth: 4,
                                          strokeColour: selectedStyle.strokeColour,
                                          fillColour: selectedStyle.fillColour,
                                          fillOpacity: 0,
                                      }"
                                      :is-selected="isOutlineTextSelected()"
                                      data-test="sketches-styles-text-outline"
                                      is-style-preset
                                      @click="selectSolidOutlineText(0)" />
                    </section>
                </div>
            </div>
            <div class="tool-section">
                <!-- Colours -->
                <label v-t="type === SketchType.Text ? 'style.backgroundColour' : 'style.colour'"
                       class="label-caps-small" />
                <section class="colour-options">
                    <button v-for="colour in presetColours"
                            :key="colour"
                            tabindex="0"
                            data-test="sketches-styles-colour"
                            class="colour-option"
                            :class="{ 'is-selected': isSelectedColour(colour) }"
                            @click="selectColour(colour)">
                        <div class="colour-option__inner"
                             :style="{background: colour}" />
                    </button>
                </section>
            </div>
            <div v-if="showLabelOptions"
                 data-test="sketches-styles-label-options"
                 class="show-label-options"
                 @click="toggleShowLabelOptions">
                <label v-t="'style.showLabel'"
                       class="label-caps-small" />
                <v-switch v-model="props.selectedStyle.showLabel"
                          data-test="sketches-show-label"
                          color="primary"
                          hide-details
                          @click="emit('select-style', props.selectedStyle)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from "vuex"

    import StyleOption from '@/components/sketches/style-option.vue'
    import { BoundaryColourPaletteUsage } from '@/enums/boundary-colour-palette-usage'
    import {
        Arrowhead,
        SketchType,
    } from '@/enums/sketches-enums'
    import { DefaultBoundaryColourPalette } from '@/models/styles/default-boundary-colour-palette'
    import { SketchesStyleFactory } from '@/models/styles/sketches-style-factory'
    import { IOwStyle } from '@/store/modules/sketches/types/style'
    import { unique } from '@/utils/array-utils'
    import { owStyleDefinitionsAreEqual } from '@/utils/style-utils'

    const store = useStore()

    interface Props {
        selectedStyle?: Partial<IOwStyle> | undefined | null
        type: SketchType
        isComplexSketch: boolean
    }
    const props = withDefaults(defineProps<Props>(), {
        selectedStyle: null,
    })
    const emit = defineEmits<{
        (e: 'select-style', style: Partial<IOwStyle>): void
    }>()

    // Selection
    const selectStyle = (style: IOwStyle) => {
        emit('select-style', { ...props.selectedStyle, ...style })
    }

    const isSelected = (style: IOwStyle) => {
        return owStyleDefinitionsAreEqual(style, props.selectedStyle, props.type)
    }

    const stylePresets = computed(() => {
        return SketchesStyleFactory.getDefaultSketchStyles(props.type, props.isComplexSketch)
    })

    // Advanced options
    const showAdvancedOptions = ref(false)
    const toggleAdvancedOptions = () => {
        showAdvancedOptions.value = !showAdvancedOptions.value
    }

    // Stroke width
    const isStrokeSelected = (strokeWidth: number) => {
        return props.selectedStyle?.strokeWidth === strokeWidth
    }
    const selectStroke = (strokeWidth: number) => {
        const style = props.selectedStyle
        style.strokeWidth = strokeWidth
        emit('select-style', style)
    }

    // Arrowhead
    const showArrowheadOptions = computed(() =>
        props.type === SketchType.Line &&
        !props.isComplexSketch)
    const isArrowheadSelected = (arrowhead: Arrowhead) => {
        return props.selectedStyle?.arrowhead === arrowhead
    }
    const selectArrowhead = (arrowhead: Arrowhead) => {
        const style = props.selectedStyle
        style.arrowhead = arrowhead
        emit('select-style', style)
    }

    // Stroke style
    const showStrokeOptions = computed(() => props.type !== SketchType.Marker && props.type !== SketchType.Text)
    const isSolidStyleSelected = computed(() => {
        return props.selectedStyle.dash === false || props.selectedStyle.dash === undefined
    })

    const isDashStyleSelected = computed(() => {
        return props.selectedStyle.dash === true
    })

    const selectStrokeStyle = (dash: boolean) => {
        const style = props.selectedStyle
        style.dash = dash
        emit('select-style', style)
    }

    // Fill style
    const showFillOptions = computed(() => props.type === SketchType.Area)
    const isFillStyleSelected = (fillOpacity: number, hatch: boolean) => {
        const style = props.selectedStyle
        return style.fillOpacity === fillOpacity && style.hatch === hatch
    }
    const selectFillStyle = (fillOpacity: number, hatch: boolean) => {
        const style = props.selectedStyle
        style.fillOpacity = fillOpacity
        style.hatch = hatch
        style.fillColour = fillOpacity > 0 ? style.strokeColour : null
        emit('select-style', style)
    }

    // Size
    const showSizeOptions = computed(() => props.type === SketchType.Marker || props.type === SketchType.Text)
    const isSizeSelected = (strokeSize: number) => {
        const style = props.selectedStyle
        return style.strokeWidth === strokeSize
    }
    const selectSize = (strokeSize: number) => {
        const style = props.selectedStyle
        style.strokeWidth = strokeSize
        emit('select-style', style)
    }

    // Preset colours
    const palette = new DefaultBoundaryColourPalette(BoundaryColourPaletteUsage.Sketches)

    // Text colour style
    const showTextColourOptions = computed(() => props.type === SketchType.Text)
    const presetTextColours = unique(palette.getSketchesTextColours().map(x => x.colour))
    const isSelectedTextColour = (colour: string) => {
        return props.selectedStyle?.fillColour === colour
    }
    const selectTextColour = (textColour: string) => {
        const style = props.selectedStyle
        style.fillColour = textColour

        emit('select-style', style)
    }

    // Solid / stroke text style
    const isSolidTextSelected = () => {
        return props.selectedStyle?.fillOpacity !== 0
    }
    const isOutlineTextSelected = () => {
        return props.selectedStyle?.fillOpacity === 0
    }
    const selectSolidOutlineText = (fillOpacity: number) => {
        const style = props.selectedStyle
        style.fillOpacity = fillOpacity

        emit('select-style', style)
    }

    // Preset colours
    const presetColours = unique(palette.getPalette().map(x => x.colour))
    const isSelectedColour = (colour: string) => {
        return props.selectedStyle?.strokeColour === colour
    }
    const selectColour = (colour: string) => {
        const style = props.selectedStyle
        style.strokeColour = colour
        style.fillColour = colour
        emit('select-style', style)
    }

    // Label visibility
    const showLabelOptions = computed(() => props.type !== SketchType.Text)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectShowLabel = (showLabel: boolean) => {
        const style = props.selectedStyle
        style.showLabel = showLabel

        emit('select-style', style)
    }
    const toggleShowLabelOptions = () => {
        const style = props.selectedStyle
        style.showLabel = !style.showLabel

        emit('select-style', props.selectedStyle)
    }

    defineExpose({
        toggleAdvancedOptions,
        isSelected,
        selectStyle,
        showAdvancedOptions,
    })

</script>

<style lang="scss">
@import 'style-panel.scss';
</style>
