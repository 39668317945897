<template>
    <ow-full-page-lock v-model="showLock"
                       :feature-id="FeatureId.Alerts"
                       offset-x="2rem">
        <!-- Rendered if show lock is false -->

        <ow-full-width :inline-step-flow="false"
                       is-fixed-width
                       page-name="asset-monitoring">
            <template #navigation>
                <top-nav v-if="hasAccessToAlerts"
                         :header-title="t('assetMonitoring.navigationHeadings.pageTitle')"
                         :items="topNavItems"
                         :actions="topNavActions"
                         :side-nav-collapsed="true"
                         :value="route.name.toString()"
                         class="asset-monitoring__top-nav"
                         has-beta-label
                         header-icon="$notification"
                         @action-click="onActionClick" />
            </template>
            <template #sub-navigation>
                <ow-tabs v-if="hasAccessToAlerts && !isNullOrEmpty(subNavItems)"
                         :model-value="route.meta.subChildNavName"
                         class="w-100"
                         color="primary"
                         show-arrows>
                    <v-tab v-for="subNav in subNavItems"
                           :key="subNav.title"
                           :data-test="`copies-filed-document-table-tab-${ subNav.dataTestId }`"
                           :value="subNav.subChildNavName"
                           @click="onClickSubNav(subNav)">
                        {{ subNav.title }}
                    </v-tab>
                </ow-tabs>
            </template>
            <template #default>
                <div class="content-container">
                    <router-view v-slot="{ Component }">
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </router-view>
                </div>
            </template>
        </ow-full-width>
    </ow-full-page-lock>
</template>

<script lang="ts" setup>
    import {
        onBeforeMount,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'

    import OwFullWidth from '@/components/core/layout/full-width-white-header.vue'
    import OwFullPageLock from '@/components/core/ow-full-page-lock.vue'
    import OwTabs from '@/components/core/ow-tabs.vue'
    import TopNav from '@/components/top-nav/top-nav.vue'
    import {
        NavAction,
        NavItem,
    } from '@/components/top-nav/types'
    import useAssetMonitoringTopNav from '@/composables/use-asset-monitoring-top-nav'
    import { FeatureId } from '@/composables/use-licence-controller'
    import { inject as userProvider } from '@/composables/use-user'
    import { Route } from "@/enums/route.enum"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const showLock = ref<boolean>()
    const { topNavItems, topNavActions, subNavItems } = useAssetMonitoringTopNav()
    const { hasAccessToAlerts } = userProvider()

    onBeforeMount(async () => {
        const hasAccess = await hasAccessToAlerts()
        showLock.value = !hasAccess
    })

    const onClickSubNav = (subNav: NavItem) => {
        router.push({ name: subNav?.route, params: subNav?.routeParams })
    }

    const onActionClick = (action: NavAction) => {
        if (action.title === t('assetMonitoring.navigationActions.addCompany')) {
            router.push({ name: Route.AssetMonitoringSettingsAddCompany })
        }
    }
</script>

<style lang="scss" scoped>
    @import './asset-monitoring';
</style>
