<template>
    <div v-if="document"
         :class="{'show-only-download-button' : showOnlyDownloadButton, 'is-small': isSmall}"
         class="document-order-view-link">
        <!-- Order document -->
        <div v-if="hasDocumentStatus"
             class="document-order-view-link__document-status-info">
            <div class="document-order-view-link__document-status-info--order-btn">
                <v-btn v-if="showOrderButton"
                       class="title-lr-button document-order-view-link__button-order"
                       color="primary"
                       data-test="document-order-view-link-orderdocument"
                       size="small"
                       variant="text"
                       :disabled="disabled || !isOrderingAllowed"
                       @click="orderDocument()">
                    {{ $t('action.order') }} {{ isSmall ? '' :'document ' }}({{ documentCharge }})
                </v-btn>
                <ow-tooltip activator="parent"
                            :disabled="isOrderingAllowed"
                            :position="OwTooltipPosition.Bottom">
                    {{ $t('documents.message.addTitleToMatter') }}
                </ow-tooltip>
            </div>

            <!-- Document status -->
            <span v-if="documentStatusText"
                  class="document-order-view-link__document-status-info--text"
                  :title="documentStatusText"
                  data-test="document-order-view-link-message">
                {{ documentStatusText }}
            </span>

            <!-- View in PDF viewer-->
            <router-link :to="viewRoute"
                         @click="onViewLinkClicked">
                <v-btn v-if="showViewButton"
                       class="title-lr-button document-order-view-link__button-view"
                       color="primary"
                       data-test="document-order-view-link-view-in-ow"
                       data-track="DOCS - View document"
                       variant="text">
                    <span v-t="'action.view'"
                          class="body-regular" />
                </v-btn>
            </router-link>
        </div>
        <div v-else-if="!showOnlyDownloadButton"
             v-t="'label.notApplicable'"
             class="document-order-view-link__not-applicable"
             data-test="document-order-view-link-not-applicable" />

        <!-- Download -->
        <span v-if="showDownloadButton">
            <v-btn class="document-order-view-link__button-download title-lr-button"
                   color="primary"
                   data-test="document-order-view-link-download"
                   data-track="DOCS - Download document"
                   variant="text"
                   icon="$download"
                   @click="downloadDocument()" />
        </span>
    </div>
</template>

<script>
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelSectionName } from '@/enums/title-panel-section-name'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import {
        ADD_DOCUMENT_TO_MATTER,
        MUTATE_DOCUMENT_ORDER_ERROR,
        ORDER_COPY_FILED_DOCUMENT,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_GET_DOCUMENT_CHARGE,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {
        getDocumentStatus,
        showDownloadButton,
        showOrderButton,
        showViewButton,
    } from '@/utils/document-utils'

    export default {
        name: 'DocumentOrderViewLink',

        components: {
            OwTooltip,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        props: {
            document: {
                type: Object,
                required: true,
            },
            documentType: {
                type: String,
                required: true,
            },
            heapMetadata: {
                type: Object,
                required: false,
                default: null,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            showOnlyDownloadButton: {
                type: Boolean,
                required: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            isSmall: {
                type: Boolean,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                OwTooltipPosition,
            }
        },

        computed: {
            ...mapState({
                currentMatter: state => state.matter.currentMatter,
                orderErrors: state => state.documents.orderErrors,
            }),

            ...mapGetters({
                documentCharge: USER_GET_DOCUMENT_CHARGE,
            }),

            showOrderButton() {
                return showOrderButton(this.document)
            },

            showViewButton() {
                return showViewButton(this.document)
            },

            showDownloadButton() {
                return showDownloadButton(this.document)
            },

            documentStatusText() {
                return getDocumentStatus(this.document)
            },

            hasDocumentStatus() {
                return (this.showOrderButton || this.showViewButton || this.documentStatusText) && !this.showOnlyDownloadButton
            },

            viewRoute() {
                if(this.isTitlePanelV2Enabled &&
                    this.document.documentType === 'Register') {
                    return {
                        name: Route.MatterMapTitle,
                        params: {
                            matterId: this.$route.params.matterId,
                            titleNumber: this.titleNumber,
                        },
                        query: {
                            tab: TitlePanelTabName.Register,
                            section: TitlePanelSectionName.RegisterDocument,
                        },
                    }
                } else if (this.document.documentType === 'Register') {
                    return {
                        name: Route.ReviewAssistant,
                        params: {
                            matterId: this.$route.params.matterId,
                            titleNumber: this.titleNumber,
                        },
                    }
                } else {
                    return {
                        name: Route.DocumentViewer,
                        params: {
                            documentType: this.documentType,
                            documentId: this.document.documentId,
                        },
                        query: {
                            fromMatterId: this.currentMatter.id,
                        },
                    }
                }
            },
        },

        methods: {
            ...mapActions({
                addDocumentToMatter: ADD_DOCUMENT_TO_MATTER,
                addTitleToMatter: MATTER_ADD_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                orderCopyFiledDocument: ORDER_COPY_FILED_DOCUMENT,
                showPopup: USER_SHOW_POPUP,
            }),

            ...mapMutations({
                mutateDocumentErrors: MUTATE_DOCUMENT_ORDER_ERROR,
            }),

            async onViewLinkClicked() {
                const matterId = this.$route.params.matterId

                await this.addDocumentToMatter({
                    matterId,
                    documentType: this.documentType,
                    documentId: this.document.documentId,
                })

                // Log the event
                await this.logHeapEvent({
                    type: 'View augmented PDF link clicked',
                    metadata: {
                        titleNumber: this.titleNumber,
                        documentType: this.document.documentType,
                        documentDate: this.document.documentDate,
                        filedUnder: this.document.filedUnderField,
                    },
                })
            },
            async downloadDocument() {
                try {
                    await DocumentsApi.downloadDocumentByTypeAndId(this.documentType, this.document.documentId)
                    await this.addDocumentToMatter({
                        documentType: this.documentType,
                        document: this.document,
                    })
                } catch (error) {
                    await this.showPopup({
                        title: 'Error',
                        contentHTML: 'An error was encountered when downloading the document',
                    })
                    console.error(error)
                }
            },

            async orderDocument() {
                if (!this.document.loadingOrder) {
                    // Add to current matter
                    await this.addTitleToMatter({
                        titleNumber: this.titleNumber,
                        show: false,
                    })

                    // Currently this order link only appears for copy filed documents
                    await this.orderCopyFiledDocument({
                        titleNumber: this.titleNumber,
                        document: this.document,
                        show: false,
                    })

                    if (this.heapMetadata) {
                        await this.logHeapEvent(this.heapMetadata)
                    } else {
                        await this.logHeapEvent({
                            type: 'DOCS - Order document',
                        })
                    }

                    if (!isNullOrEmpty(this.orderErrors)) {
                        await this.showPopup({
                            title: 'Order Documents',
                            icon: '$info',
                            contentHTML: this.orderErrors.join(''),
                        })
                        this.mutateDocumentErrors(null)
                    }
                }
            },
        },
    }
</script>
<style lang="scss">
    @import './document-order-view-link.scss';
</style>
