<template>
    <div class="ow-wizard pa-6">
        <v-window v-model="modelValue"
                  class="ow-wizard__container">
            <v-window-item v-for="step in steps"
                           :key="step.id"
                           eager>
                <div class="ow-wizard__header d-flex flex-column gr-2">
                    <slot name="header" />
                    <h1 v-show="modelValue > 0"
                        v-dompurify-html="heading"
                        class="ow-wizard__header__heading label-caps-xx-small" />
                    <span v-dompurify-html="step.heading"
                          class="ow-wizard__header__step-heading headers-display" />
                    <span v-if="step.description"
                          v-dompurify-html="step.description"
                          class="ow-wizard__header__step-description body-regular-small" />
                </div>
                <div class="ow-wizard__slot w-100">
                    <slot :name="`step-${step.id}`"
                          v-bind="{
                              onNext: onNextClick,
                              step,
                          }" />
                </div>
            </v-window-item>
        </v-window>
        <div class="ow-wizard__buttons d-flex justify-space-between align-center">
            <ow-button class="ow-wizard__button"
                       :disabled="previousButtonDisabled"
                       is-secondary
                       data-test="wizard-prev-button"
                       @click="onPreviousClick">
                {{ previousButtonLabel }}
            </ow-button>
            <div class="d-flex align-center gc-1">
                <v-icon v-for="step in activeSteps"
                        class="ow-wizard__progress-icon"
                        :class="{
                            'ow-wizard__progress-icon--active': step.id === activeStep?.id,
                        }"
                        @click="onClickStep(step)">
                    {{ step.id === activeStep?.id ? '$circle-outline' : '$circle' }}
                </v-icon>
            </div>
            <ow-button class="ow-wizard__button"
                       :disabled="nextButtonDisabled"
                       data-test="wizard-next-button"
                       :is-primary="activeStep?.nextButtonIsPrimary ?? true"
                       :is-secondary="activeStep?.nextButtonIsSecondary ?? false"
                       @click="onNextClick">
                {{ nextButtonLabel }}
            </ow-button>
        </div>
        <div id="wizard-side-nav" />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import { IWizardStep } from "@/components/core/ow-wizard/types"
    import { Route } from "@/enums/route.enum"
    import { isNullOrEmpty } from "@/utils/array-utils"
    const modelValue = defineModel<number>()

    const { t } = useI18n()

    const props = defineProps<{
        steps: IWizardStep<any>[],
        heading: string
    }>()

    const emit = defineEmits<{
        (e: 'submit', value: Record<number, any[]>): void
    }>()

    const activeStep = computed(() => props.steps?.[modelValue.value] ?? null)
    const router = useRouter()

    const nextButtonLabel = computed(() => {
        if (modelValue.value === props.steps.length - 1) {
            return activeStep.value?.nextButtonLabel ?? t('action.finish')
        }
        return activeStep.value?.nextButtonLabel ?? t('action.next')
    })

    const previousButtonLabel = computed(() => {
        return activeStep.value?.prevButtonLabel ?? t('action.back')
    })

    const onNextClick = () => {
        const canProceed = activeStep.value?.beforeNext?.() ?? true
        if (canProceed) {
            if (modelValue.value === props.steps.length - 1) {
                const check = activeStep.value?.beforeFinish?.()
                if (check === false) return

                activeStep.value?.onFinish?.()
                emit('submit', props.steps.reduce((acc, step) => {
                    acc[step.id] = step.data
                    return acc
                }, {}))
                return
            }

            if (modelValue.value < props.steps.length - 1) {
                activeStep.value?.onNext?.()

                // find next step that is not disabled
                const nextStep = props.steps.find((_, i) => i > modelValue.value && !_.disabled)
                if (nextStep) {
                    modelValue.value = props.steps.findIndex(s => s.id === nextStep.id)
                }
            }
        }
    }

    const onPreviousClick = () => {
        if (activeStep.value?.beforePrev?.() === false) {
            return
        }
        activeStep.value?.onPrev?.()
        if (modelValue.value > 0) {
            // find previous step that is not disabled
            const prevStep = props.steps.slice(0, modelValue.value).reverse().find(s => !s.disabled)
            if (prevStep) {
                modelValue.value = props.steps.findIndex(s => s.id === prevStep.id)
            }
        }
    }

    const activeSteps = computed(() => props.steps.filter(s => !s.disabled))

    const onClickStep = (step: IWizardStep<any>) => {
        const stepIndex = props.steps.findIndex(s => s.id === step.id)

        // check can go to step
        if (activeStep.value?.beforeNext?.() === false) {
            return
        }

        modelValue.value = stepIndex
    }

    const nextButtonDisabled = computed(() => {
        if (isNullOrEmpty(props.steps)) {
            return true
        }
        return activeStep.value?.nextButtonIsDisabled ?? false
    })

    const previousButtonDisabled = computed(() => {
        if (isNullOrEmpty(props.steps)) {
            return true
        }
        return activeStep.value?.prevButtonIsDisabled ?? modelValue.value === 0
    })

    watch(() => activeStep.value, () => {
        if (activeStep.value?.onEnter) {
            activeStep.value.onEnter()
        }
        // update route
        if (activeStep.value?.id) {
            router.push({
                name: Route.MattersCreate,
                query: {
                    step: activeStep.value.id,
                },
            })
        }
    }, { immediate: true })

    // watch route
    watch(() => router?.currentRoute?.value?.query?.step, (step: string) => {
        const stepIndex = props.steps.findIndex(s => s.id === step)
        if (stepIndex !== -1) {

            if (props.steps[stepIndex].disabled) {
                // first step
                modelValue.value = 0
            } else {
                // do we have any steps that require to be completed before this step
                // by counting backwards we can find the first step that is disabled
                const prevStep = props.steps.slice(0, stepIndex).reverse().find(s => s.nextButtonIsDisabled)
                if (prevStep) {
                    modelValue.value = props.steps.findIndex(s => s.id === prevStep.id)
                } else {
                    modelValue.value = stepIndex
                }
            }
        }
    }, { immediate: true })
</script>

<style lang="scss">
@import './ow-wizard';
</style>
