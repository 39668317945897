<template>
    <nav class="settings-sub-navigation">
        <ul class="settings-sub-navigation__container">
            <li :class="{
                'is-active': route.name === Route.MattersSettings,
            }">
                <router-link :to="overviewLink"
                             class="caption-highlight">
                    Overview
                </router-link>
            </li>
            <li v-if="hasAccessToAlerts"
                :class="{
                    'is-active': route.name === Route.MattersMonitoringPreferences,
                }">
                <router-link :to="monitoringPreferencesLink"
                             class="caption-highlight">
                    {{ $t('assetMonitoring.navigationHeadings.pageTitle') }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import {
        type RouteLocationRaw,
        useRoute,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import {FeatureId} from "@/composables/use-licence-controller"
    import { inject as userProvider } from '@/composables/use-user'
    import { Route } from '@/enums/route.enum'
    import { checkFlag } from "@/feature-flags"


    const route = useRoute()
    const store = useStore()

    const props = defineProps<{
        currentMatterId?: Number,
    }>()

    const { hasAccessToAlerts } = userProvider()

    const overviewLink = computed<RouteLocationRaw>((): RouteLocationRaw => {
        return {
            name: Route.MattersSettings,
            params: { matterId: props.currentMatterId?.toString() },
        }
    })

    const monitoringPreferencesLink = computed<RouteLocationRaw>((): RouteLocationRaw => {
        return {
            name: Route.AssetMonitoringSettings,
            params: { matterId: props.currentMatterId?.toString(), tabId: "titles" },
        }
    })
</script>

<style scoped lang="scss">
    @import './settings-sub-navigation';
</style>
