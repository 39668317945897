import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { getNotificationHubUri } from "@/utils/environment.utils"

export type ICreateCompanyGroupRequest = {
    matterId: number
    groupName: string
    isEnabled: boolean
    companyNumbers: string[]
}

export type IUpdateCompanyGroupRequest = {
    groupName: string
    isEnabled: boolean
}

export type IGetCompanyGroupResponse = {
    id: string
    matterId: number
    groupName: string
    isEnabled: boolean
    companies: {
        name: string
        address: string
        companyNumber: string
    }[]
}

export type IGetCompanyGroupsResponse = {
    companyGroups: IGetCompanyGroupResponse[]
}

export default class CompanyGroupsApi {
    static END_POINT = `${ getNotificationHubUri() }/api/companygroups`

    static createCompanyGroup(request: ICreateCompanyGroupRequest): Promise<IHttpClientResponse> {
        return httpClient.post(`${ this.END_POINT }`, request)
    }

    static updateCompanyGroupCompanies(groupId: string, companies: string[]): Promise<IHttpClientResponse> {
        return httpClient.put(`${ this.END_POINT }/${ groupId }/companies`, companies)
    }

    static updateCompanyGroup(groupId: string, request: IUpdateCompanyGroupRequest): Promise<IHttpClientResponse> {
        return httpClient.put(`${ this.END_POINT }/${ groupId }`, request)
    }

    static getCompanyGroups(matterId: number): Promise<IHttpClientResponse<IGetCompanyGroupsResponse>> {
        return httpClient.get(`${ this.END_POINT }/matter/${ matterId }`)
    }

    static deleteCompanyGroup(companyGroupId: string): Promise<IHttpClientResponse> {
        return httpClient.delete(`${ this.END_POINT }/${ companyGroupId }`)
    }

    static getCompanyGroup(companyGroupId: string): Promise<IHttpClientResponse<IGetCompanyGroupResponse>> {
        return httpClient.get(`${ this.END_POINT }/${ companyGroupId }`)
    }
}
