<template>
    <div class="corporate-owner-results">
        <div v-if="showActions"
             class="corporate-owner-results_actions">
            <ow-button :outlined="true"
                       data-track="TITLE-DETAILS-PANEL - NEARBY - Add to matter from corporate owners"
                       @click="addToMatter">
                {{ $t('matter.addToMatterWithCount', selectedTitlesCount) }}
            </ow-button>
            <ow-button data-track="TITLE-DETAILS-PANEL - NEARBY - Export corporate owners results"
                       outlined
                       @click="download">
                <img alt="Click to download the tags as CSV"
                     class="ml-tags-card__excel-icon"
                     src="../../../../media/icon-excel.png"
                     width="16"
                     height="16"
                     title="Export as CSV" />
                {{ $t('action.exportToExcelWithCount', selectedTitlesCount) }}
            </ow-button>
        </div>
        <ow-data-grid ref="grid"
                      :allow-selection="true"
                      :headers="dataGridHeaders"
                      :is-loading="isLoading"
                      :items="records"
                      :sort-by="sortBy"
                      theme="light"
                      hover
                      item-key="ownerName"
                      @items-selected="onItemsSelected">
            <template #item.ownerName="{ item }">
                <td class="find-nearby-results-corporate-owners__title"
                    :class="{ highlight: shouldHighlightItem(item)}"
                    @mouseover="$emit('mouse-over-item', item)">
                    {{ item.ownerName }}
                    <a v-if="hasCompanyNumbers"
                       :href="getCompaniesHouseLink(item)"
                       target="_blank">
                        {{ item.companyRegistrationNumbers.join(', ') }}
                    </a>
                </td>
            </template>
            <template #item.titlesCount="{ item }">
                <td class="find-nearby-results-corporate-owners__title"
                    :class="{ highlight: shouldHighlightItem(item)}"
                    @mouseover="$emit('mouse-over-item', item)">
                    {{ item.titlesCount }}
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>

<script lang="ts">
    import {
        PropType,
        ref,
    } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import { FindNearbyCorporateOwnerService } from '@/components/find-nearby/implementations/corporate-owners/corporate-owners-service'
    import { INearbyCorporateOwner } from '@/components/find-nearby/implementations/corporate-owners/nearby-corporate-owner.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { exportAsCsv } from '@/utils/csv-utils'
    import { format } from '@/utils/date-utils'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'

    export default {
        name: 'MatterTitleResults',

        components: {
            OwDataGrid,
            OwButton,
        },

        props: {
            records: {
                type: Array as PropType<INearbyCorporateOwner[]>,
                required: false,
                default: () => [],
            },
            service: {
                types: Object as PropType<FindNearbyCorporateOwnerService>,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            highlightResults: {
                type: Array as PropType<INearbyCorporateOwner[]>,
                required: false,
            },
        },

        emits: ['mouse-over-item'],

        data() {
            return {
                dataGridHeaders: [
                    { title: 'Owner', key: 'ownerName', width: '350px' },
                    { title: 'Titles', key: 'titlesCount', width: '65px' },
                ],
                selectedItems: [],
                sortBy: ref([{key: 'ownerName', order: 'asc'}]),
            }
        },

        computed: {
            showActions() {
                return this.selectedItems.length > 0
            },

            selectedTitlesCount() {
                return this.selectedItems.map(x => x.titlesCount).reduce((a, b) => a + b, 0)
            },
        },

        watch: {
            records() {
                this.clearSelection()
            },
        },

        methods: {
            exportAsCsv,

            shouldHighlightItem(item: INearbyCorporateOwner): boolean {
                return this.highlightResults?.map(x => x.ownerName).includes(item.ownerName)
            },

            getCompaniesHouseLink(item) {
                return getCompaniesHouseLinkFromCompanyRegistrationNumber(item.companyRegistrationNumbers[0])
            },

            hasCompanyNumbers(item) {
                return !isNullOrEmpty(item.companyRegistrationNumbers)
            },

            onItemsSelected(items: Array<any>) {
                this.selectedItems = this.records.filter(obj => items.find(x => x === obj.ownerName))
            },

            download(): Array<string> {
                const rows = []
                const headers = ['Owner', 'Company Registration Numbers', 'Title', 'Title Address', 'Distance (Metres)']
                const data = []
                this.selectedItems
                    .forEach(x => {
                        x.titles.forEach(t => {
                            data.push([
                                x.ownerName,
                                x.companyRegistrationNumbers.join(', '),
                                t.titleNumber,
                                t.address,
                                t.distanceMetres,
                            ])
                        })
                    })

                rows.push(headers, ...data)
                const filename = `nearby-owners-data-export-${ format(new Date(), 'dd-MM-yyyy') }.csv`
                this.exportAsCsv(rows, filename)
                this.clearSelection()
                return rows
            },

            addToMatter() {
                const titlesToAdd = this.selectedItems.flatMap(x => x.titles.map(t => t.titleNumber))
                this.service.onAddTitlesToMatter(titlesToAdd)
                this.clearSelection()
            },

            clearSelection() {
                this.$refs.grid.clearSelection()
            },
        },
    }
</script>

<style lang="scss">
    @import './corporate-owners-results.scss';
</style>
