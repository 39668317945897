<template>
    <base-monitoring-preference-card :card-type="t('assetMonitoring.text.titles')"
                                     :card-type-description="t('assetMonitoring.text.titleAlerts')">
        <ow-textfield v-model="searchText"
                      :placeholder="t('assetMonitoring.filters.searchMatterTitles')"
                      clearable>
            <template #iconPrefix>
                <v-icon>$search</v-icon>
            </template>
        </ow-textfield>
        <ul class="title-group-card__options">
            <li v-for="title in filteredTitles"
                :data-test="`title-group-card-${ title.titleNumber }`">
                <ow-toggle-card v-model="title.isWhitelisted"
                                :title="title.titleNumber"
                                no-border
                                theme="preferences">
                    <ul>
                        <li v-if="title.label"
                            class="caption-regular"
                            data-test="title-group-card-label">
                            <span class="title-group-card__label">{{ t('label.label') }}:</span> {{ title.label }}
                        </li>
                        <li v-if="title.groupName"
                            class="caption-regular"
                            data-test="title-group-card-group-name">
                            <span v-if="title.groupName"
                                  class="title-group-card__label">{{ t('label.group') }}:</span> {{ title.groupName }}
                        </li>
                        <li v-if="title.address"
                            class="caption-regular"
                            data-test="title-group-card-address">
                            <span class="title-group-card__label">{{ t('label.address') }}:</span> {{ title.address }}
                        </li>
                    </ul>
                </ow-toggle-card>
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import { debounce } from 'lodash'
    import {
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import BaseMonitoringPreferenceCard from '@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue'
    import { ITitleGroup } from '@/components/asset-monitoring/title-groups/title-group.model'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import OwToggleCard from '@/components/core/ow-toggle-card.vue'
    import { dynamicSortMultipleFields } from '@/utils/array-utils'

    const { t } = useI18n()

    const props = defineProps<{
        titles: ITitleGroup[],
    }>()

    const searchText = ref<string>(null)
    const filteredTitles = ref<ITitleGroup[]>(props.titles)
    const searchTitles = debounce(() => {
        filteredTitles.value = props.titles
            .filter((title: ITitleGroup) => {
                return title.searchString.includes(searchText.value.trim().toLowerCase())
            }).sort(dynamicSortMultipleFields([ 'isWhitelisted', 'titleNumber' ]))
    }, 500)
    watch(searchText, () => {
        searchTitles()
    })
    watch(filteredTitles, () => {
        filteredTitles.value = filteredTitles.value.sort(dynamicSortMultipleFields([ '-isWhitelisted', 'titleNumber' ]))
    }, { deep: true, immediate: true })
</script>

<style lang="scss" scoped>
@import './title-group-card';
</style>
