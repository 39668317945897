<template>
    <div class="document-library ow-page-container">
        <documents-library-header :current-matter-id="matterId"
                                  :is-loading="isLoading"
                                  :is-disabled="disableHeaderActions"
                                  :search-text="searchText"
                                  :status-options="statusOptions"
                                  :type-options="typeOptions"
                                  @search-text-updated="searchTextUpdatedHandler"
                                  @status-filter-updated="statusFilterUpdatedHandler"
                                  @types-filter-updated="typesFilterUpdatedHandler"
                                  @upload-started="onUploadStarted" />

        <div class="document-library__content">
            <copilot-cta v-if="showCopilotCta"
                         class="mb-4"
                         :title="$t('copilot.banner.documentLibrary.title')"
                         :description="$t('copilot.banner.documentLibrary.description')"
                         :matter-id="matterId"
                         :matter-code="matterCode"
                         :client-code="clientCode"
                         position="Document Library">
                <template #actions>
                    <open-copilot-button :title="copilotButtonTitle"
                                         :selected-documents="selectedDocuments"
                                         show-all-documents
                                         is-secondary
                                         track-position="Document Library"
                                         data-test="document-library-open-copilot"
                                         data-track="DOCUMENT-LIBRARY - Open Copilot" />
                </template>
            </copilot-cta>

            <v-tabs v-model="selectedTab"
                    slider-color="primary"
                    :show-arrows="false"
                    class="mb-0">
                <v-tab :key="tabsIndexKey.purchased"
                       :ripple="false"
                       class="document-library__tab"
                       data-test="document-library-tab-purchased"
                       data-track="DOCUMENTS - Purchased tab"
                       light>
                    <span class="button-small">
                        {{ $t('documents.library.tabs.purchased', { count: isLoading ? 0 : numberOfPurchasedDocuments }) }}
                    </span>
                </v-tab>
                <v-tab :key="tabsIndexKey.uploaded"
                       :ripple="false"
                       class="document-library__tab"
                       data-test="document-library-tab-uploaded"
                       data-track="DOCUMENTS - Uploaded tab"
                       light>
                    <span class="button-small">
                        {{ $t('documents.library.tabs.uploaded', { count: isLoading ? 0 : numOfUploadedDocuments }) }}
                    </span>
                </v-tab>
                <v-tab v-if="isOrderSearchEnabled"
                       :key="tabsIndexKey.searches"
                       :ripple="false"
                       class="document-library__tab"
                       data-test="document-library-tab-searches"
                       data-track="DOCUMENTS - Searches tab"
                       light>
                    <span class="button-small">
                        {{ $t('documents.library.tabs.searches', { count: isLoading ? 0 : numberOfSearchesDocuments }) }}
                    </span>
                </v-tab>
                <v-tab :key="tabsIndexKey.exported"
                       :ripple="false"
                       class="document-library__tab"
                       data-test="document-library-tab-exported"
                       data-track="DOCUMENTS - Exported tab"
                       light>
                    <span class="button-small">
                        {{ $t('documents.library.tabs.exported', { count: isLoading ? 0 : numberOfExportedDocuments }) }}
                    </span>
                </v-tab>
            </v-tabs>

            <v-window v-model="selectedTab">
                <v-window-item :key="tabsIndexKey.purchased">
                    <loading-skeleton v-if="isLoading" />
                    <purchased-options v-else
                                       :current-matter-id="matterId"
                                       :documents="internalPurchasedDocuments"
                                       :filtered-document-types="filteredDocumentTypes"
                                       :filtered-status-options="filteredStatusOptions"
                                       :has-filter="hasFilter"
                                       :is-loading="isLoading"
                                       :search-text="searchText"
                                       class="document-library__v-window-content"
                                       @refresh-documents="refreshUnpurchasedDocuments"
                                       @select-documents="handlePurchasedSelectDocuments" />
                </v-window-item>

                <v-window-item :key="tabsIndexKey.uploaded">
                    <loading-skeleton v-if="isLoadingUploadedDocs"
                                      is-beta />
                    <uploaded-documents v-else
                                        :current-matter-id="matterId"
                                        :documents="uploadedDocuments"
                                        :is-downloading="isDownloading"
                                        :is-loading="isLoadingUploadedDocs"
                                        class="document-library__v-window-content"
                                        @download="downloadUploadedDocumentHandler"
                                        @view="viewUploadedDocument"
                                        @refresh-documents="getUploadedDocuments"
                                        @select-documents="handleUploadedSelectDocuments" />
                </v-window-item>

                <v-window-item v-if="isOrderSearchEnabled"
                               :key="tabsIndexKey.searches">
                    <loading-skeleton v-if="isLoading"
                                      is-beta />
                    <searches-documents v-else
                                        class="document-library__v-window-content"
                                        :searches-documents="filteredSearchesDocuments"
                                        :is-loading="isLoading"
                                        :matter-id="matterId" />
                </v-window-item>

                <v-window-item :key="tabsIndexKey.exported">
                    <loading-skeleton v-if="isLoading" />
                    <exported-documents v-else
                                        :is-loading="isLoading"
                                        :current-matter-id="matterId"
                                        :documents="exportedDocuments"
                                        class="document-library__v-window-content"
                                        @refresh-documents="getExportedDocuments"
                                        @select-documents="handleExportedSelectDocuments" />
                </v-window-item>
            </v-window>
        </div>

        <teleport to="body">
            <ow-modal v-model="showDocumentModal"
                      class="open-copilot-button__modal">
                <document-selection :selected-documents="selectedDocuments"
                                    :document-ids="selectedDocuments.map(doc => doc.id)"
                                    @close="showDocumentModal = false" />
            </ow-modal>
        </teleport>
    </div>
</template>

<script lang="ts">
    import { RouteRecordRaw,
             useRoute,
             useRouter } from 'vue-router'
    import { mapActions,
             mapGetters,
             mapState } from 'vuex'

    import CopilotCta from '@/components/copilot/copilot-cta.vue'
    import DocumentSelection from '@/components/copilot/document-selection/document-selection.vue'
    import { IDocumentSelectionGroupDocument } from '@/components/copilot/document-selection/document-selection-group-document.interface'
    import OpenCopilotButton from '@/components/copilot/open-copilot-button.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import DocumentsLibraryHeader from '@/components/documents/library/documents-library-header.vue'
    import ExportedDocuments from '@/components/documents/library/exported-documents/exported-documents.vue'
    import LoadingSkeleton from '@/components/documents/library/loading-skeleton.vue'
    import PurchasedOptions from '@/components/documents/library/purchased-documents/purchased-documents.vue'
    import SearchesDocuments from '@/components/documents/library/searches-documents/searches-documents.vue'
    import UploadedDocuments from '@/components/documents/library/uploaded-documents/uploaded-documents.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { ApplicationRoles } from '@/enums/application-roles.enum'
    import { Route as OwRoutes } from '@/enums/route.enum'
    import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IOptionItem } from '@/interfaces/option-item.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import {
        DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
        DOCUMENTS_FETCH_SEARCHES_DOCUMENTS,
        DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
        GET_LIBRARY_DOCUMENTS,
        GET_LIBRARY_EXPORTED_DOCUMENTS,
        GET_LIBRARY_SEARCH_DOCUMENTS,
        GET_LIBRARY_UPLOADED_DOCUMENTS,
        REFRESH_DOCUMENTS,
    } from '@/store/modules/documents/documents-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getDocumentStatusOptions,
             getDocumentTypeOptions } from '@/utils/document-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'DocumentLibraryPage',

        components: {
            SearchesDocuments,
            ExportedDocuments,
            DocumentsLibraryHeader,
            LoadingSkeleton,
            PurchasedOptions,
            UploadedDocuments,
            CopilotCta,
            OpenCopilotButton,
            OwModal,
            DocumentSelection,
        },

        mixins: [flagsMixin],

        data() {
            return {
                searchText: '',
                statusOptions: [] as Array<IOptionItem>,
                typeOptions: [] as Array<IOptionItem>,
                internalPurchasedDocuments: [] as Array<any>,
                tabsIndexKey: {
                    purchased: 0,
                    uploaded: 1,
                    searches: 2,
                    exported: 3,
                },
                selectedTab: 0,
                disableHeaderActions: false,
                isDownloading: false,
                router: useRouter(),
                route: useRoute(),
                openDocument: null,
                showDocumentModal: false,
                selectedDocuments: [],
            }
        },

        computed: {
            ...mapState({
                loading: (state: IState) => state.documents.library.loading,
                loadingUploadedDocs: (state: IState) => state.documents.library.loadingUploadedDocuments,
                loadingList: (state: IState) => state.matter.loadingList,
                loadingState: (state: IState) => state.matter.loadingState,
                matterId: (state: IState) => state.matter.currentMatter.id,
                matterCode: (state: IState) => state.matter.currentMatter.code,
                clientCode: (state: IState) => state.matter.currentMatter.clientCode,
                matterTitles: (state: IState) => state.matter.currentMatter.selectedTitles,
                hasCopilotRole: (state: IState) => state.user.roles.includes(ApplicationRoles.Copilot),
            }),

            ...mapGetters({
                purchasedDocuments: GET_LIBRARY_DOCUMENTS,
                uploadedDocuments: GET_LIBRARY_UPLOADED_DOCUMENTS,
                searchesDocuments: GET_LIBRARY_SEARCH_DOCUMENTS,
                exportedDocuments: GET_LIBRARY_EXPORTED_DOCUMENTS,
            }),

            filteredSearchesDocuments(): Array<any> {
                const data = this.searchesDocuments
                if (!isNullOrWhitespace(this.orderId)) {
                    const result = data.filter((x: any) => x.orderItemId === this.orderId)
                    return result
                }
                return data
            },

            filteredDocumentTypes(): Array<string> {
                return this.typeOptions.filter(option => option.selected)
                    .map(option => option.value)
            },

            filteredStatusOptions(): Array<string> {
                return this.statusOptions.filter(option => option.selected)
                    .map(option => option.value)
            },

            hasFilter(): boolean {
                return (!isNullOrEmpty(this.filteredStatusOptions) ||
                    !isNullOrEmpty(this.filteredDocumentTypes) ||
                    !isNullOrWhitespace(this.searchText))
            },

            isLoading(): boolean {
                return this.loading ||
                    this.loadingState.loadingCurrentMatter ||
                    this.loadingList
            },

            isLoadingUploadedDocs(): boolean {
                return this.loadingUploadedDocs ||
                    this.loadingState.loadingCurrentMatter ||
                    this.loadingList
            },

            numOfUploadedDocuments(): number {
                return this.uploadedDocuments?.length
            },

            numberOfPurchasedDocuments(): number {
                return this.purchasedDocuments.length
            },

            numberOfExportedDocuments(): number {
                return this.exportedDocuments.length
            },

            // TODO: Check if this is still needed on the next merge from dev
            // eslint-disable-next-line vue/no-unused-properties
            numberOfSearchesDocuments(): number {
                return this.filteredSearchesDocuments.length
            },

            orderId(): string {
                return this.route?.params?.orderId ?? ''
            },

            showCopilotCta(): boolean {
                return this.hasCopilotRole
            },

            copilotButtonTitle(): string {
                return isNullOrEmpty(this.selectedDocuments)
                    ? this.$t('copilot.banner.documentLibrary.openCopilotNoSelection')
                    : this.$t('copilot.banner.documentLibrary.openCopilot', { count: this.selectedDocuments.length })
            },
        },

        watch: {
            matterTitles() {
                this.refreshLibraryOptions()
            },

            matterId(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    this.refreshPurchasedDocuments()
                    this.getUploadedDocuments()
                    this.getSearchesDocuments()
                }
            },

            purchasedDocuments(val, oldVal) {
                // Populate internal list of documents
                this.internalPurchasedDocuments = val
                this.refreshLibraryOptions()
                // Re-select previously selected documents
                if (oldVal) {
                    this.internalPurchasedDocuments.forEach(doc => {
                        doc.selected = oldVal.some(oldDoc => oldDoc.selected === true)
                    })
                }
            },

            selectedTab(newVal, oldVal): void {
                if (newVal !== oldVal) {
                    this.setSelectedTab(newVal)
                }
            },

            isLoading(newVal, oldVal): void {
                if (oldVal && !newVal) {
                    const tab = this.convertHashToTabIndex(this.route.hash) || this.tabsIndexKey.purchased
                    if (tab !== this.selectedTab
                        || this.route.hash === '') {
                        this.selectedTab = tab
                    }
                }
            },

            $route: {
                handler(to: RouteRecordRaw, from: RouteRecordRaw): void {
                    if (to?.name === OwRoutes.DocumentsLibrary &&
                        to?.name !== from?.name
                    ) {
                        const wasViewingTitleDetails = from?.name?.toString().includes(OwRoutes.DocumentsLibraryTitleDetails)
                            ?? false

                        this.initialiseLibrary(wasViewingTitleDetails)
                    }
                },
                immediate: true,
            },
        },

        async mounted() {
            const baseTasks = [this.refreshUploadedDocuments(), this.refreshPurchasedDocuments()]

            if (this.matterId) {
                baseTasks.push(this.refreshSearchesDocuments(this.matterId))
            }

            await Promise.all(baseTasks)
        },

        methods: {
            ...mapActions({
                downloadUploadedDocument: DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
                refreshPurchasedDocuments: REFRESH_DOCUMENTS,
                refreshUploadedDocuments: DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
                refreshSearchesDocuments: DOCUMENTS_FETCH_SEARCHES_DOCUMENTS,
                refreshExportedDocuments: REFRESH_DOCUMENTS,
            }),

            async initialiseLibrary(wasViewingTitleDetails = false): Promise<void> {
                const tab = this.convertHashToTabIndex(this.route.hash) || this.tabsIndexKey.purchased
                if(tab !== this.selectedTab
                    || this.route.hash === '') {
                    await this.setSelectedTab(tab)
                }

                if (!wasViewingTitleDetails) {
                    await this.refreshUnpurchasedDocuments()
                    await this.getUploadedDocuments()
                    await this.getSearchesDocuments()
                }
            },

            searchTextUpdatedHandler(searchText: string): void {
                this.searchText = searchText ?? ''
            },

            statusFilterUpdatedHandler(updatedStatuses: Array<IOptionItem>): void {
                this.statusOptions = updatedStatuses
            },

            typesFilterUpdatedHandler(updatedTypes: Array<IOptionItem>): void {
                this.typeOptions = updatedTypes
            },

            refreshLibraryOptions(): void {
                // Populate the document type filter when the documents in the library change
                this.typeOptions = getDocumentTypeOptions(
                    this.purchasedDocuments,
                    this.typeOptions.filter(t => t.selected),
                )

                // Populate the document status filter when the documents in the library change
                this.statusOptions = getDocumentStatusOptions(
                    this.purchasedDocuments,
                    this.statusOptions.filter(s => s.selected),
                )

                this.selectedDocuments = []
            },

            async refreshUnpurchasedDocuments(): Promise<void> {
                if (this.matterId !== null) {
                    await this.refreshPurchasedDocuments()
                    this.refreshLibraryOptions()
                }
            },

            convertHashToTabIndex(name: string): number {
                if (isNullOrWhitespace(name)) {
                    return this.tabsIndexKey.purchased
                }
                switch (name.toLowerCase()) {
                    case '#uploaded':
                        return this.tabsIndexKey.uploaded
                    case '#exported':
                        return this.tabsIndexKey.exported
                    default:
                        if (this.isOrderSearchEnabled && name.toLowerCase().includes('#searches')) {
                            return this.tabsIndexKey.searches
                        }
                        return this.tabsIndexKey.purchased
                }
            },

            async setSelectedTab(tabIndex: number): Promise<void> {
                let hash
                this.openDocument = this.route?.params?.openDocument ? this.route.params.openDocument : null
                switch (tabIndex) {
                    case this.tabsIndexKey.uploaded:
                        this.disableHeaderActions = true
                        hash = '#uploaded'
                        break
                    case this.tabsIndexKey.exported:
                        this.disableHeaderActions = true
                        hash = '#exported'
                        break
                    case this.tabsIndexKey.searches:
                        this.disableHeaderActions = true
                        hash = '#searches'
                        break
                    default:
                        this.disableHeaderActions = false
                        hash = '#purchased'
                }
                if (this.route.name === OwRoutes.DocumentsLibrary
                    || this.route.hash !== hash) {
                    await this.router.push({ name: OwRoutes.DocumentsLibrary, hash, params: { matterId: this.matterId, openDocument: this.openDocument, orderId: this.orderId } })
                }
            },

            async getUploadedDocuments(): Promise<void> {
                if (this.matterId) {
                    await this.refreshUploadedDocuments(this.matterId)
                }
            },

            async getExportedDocuments(): Promise<void> {
                if (this.matterId) {
                    await this.refreshExportedDocuments(this.matterId)
                }
            },

            async getSearchesDocuments(): Promise<void> {
                if (this.matterId) {
                    await this.refreshSearchesDocuments(this.matterId)
                }
            },

            async downloadUploadedDocumentHandler(doc: IUploadedDocument): Promise<void> {
                if (this.matterId && doc.id) {
                    this.isDownloading = true
                    await this.downloadUploadedDocument({
                        matterId: this.matterId,
                        documentId: doc.id,
                    })
                    this.isDownloading = true
                }
            },

            async viewUploadedDocument(doc: IUploadedDocument): Promise<void> {
                let type = HighLevelDocumentType.Uploaded
                let id = doc.id
                if (doc.type == UploadedDocumentType.OfficialCopy && doc.externalId != null) {
                    type = HighLevelDocumentType.UploadedOC2
                    id = doc.externalId
                }
                await this.router.push({
                    name: OwRoutes.DocumentViewer,
                    params: {
                        documentType: type,
                        documentId: id,
                    },
                    query: {
                        fromMatterId: this.matterId,
                    },
                })
            },

            async onUploadStarted(): Promise<void> {
                this.selectedTab = this.tabsIndexKey.uploaded
            },

            handlePurchasedSelectDocuments(docs: Array<any>): void {
                // remove all purchased documents from selected documents before adding in the new ones.
                this.selectedDocuments = this.selectedDocuments.filter((d: IDocumentSelectionGroupDocument) => d.documentSource !== DOCUMENT_SOURCE.HMLR)
                this.selectedDocuments = [
                    ...this.selectedDocuments,
                    ...docs,
                ]
            },

            handleExportedSelectDocuments(docs: Array<any>): void {
                // remove all purchased documents from selected documents before adding in the new ones.
                this.selectedDocuments = this.selectedDocuments.filter((d: IDocumentSelectionGroupDocument) => d.documentType === 'Spreadsheet')
                this.selectedDocuments = [
                    ...this.selectedDocuments,
                    ...docs,
                ]
            },

            handleUploadedSelectDocuments(docs: Array<IUploadedDocument>): void {
                // remove all uploaded documents from selected documents before adding in the new ones.
                this.selectedDocuments = this.selectedDocuments.filter((d: IDocumentSelectionGroupDocument) => d.documentSource !== DOCUMENT_SOURCE.UPLOADED)
                this.selectedDocuments = [
                    ...this.selectedDocuments,
                    // Need to reshape the uploaded doc to have the purchased doc properties that we care about.
                    ...docs.map(d => ({
                        ...d,
                        documentSource: DOCUMENT_SOURCE.UPLOADED,
                        documentType: d.type,
                        documentId: d.id,
                    })),
                ]
            },
        },
    }
</script>
<style lang="scss">
    @import './document-library';
</style>
