<template>
    <div id="top-nav"
         class="d-flex flex-row justify-space-between top-nav">
        <div class="d-flex flex-row">
            <h1 v-if="headerTitle"
                class="top-nav__header headers-h1-page-title">
                <v-icon v-if="headerIcon"
                        class="top-nav__header--icon">
                    {{ headerIcon }}
                </v-icon>
                {{ headerTitle }}
                <ow-colored-label v-if="hasBetaLabel"
                                  class="accents-small"
                                  is-beta-label
                                  is-extra-small />
            </h1>
            <div class="pa-3 d-flex flex-row top-nav__items">
                <top-nav-item v-for="item in items"
                              :key="`${item?.route}-${isSelected(item)}`"
                              class="top-nav__item"
                              v-bind="item"
                              :selected="isSelected(item)"
                              :disabled="item.disabled || item.comingSoon"
                              @click="e => emit('input', e)" />
            </div>
        </div>
        <div class="pa-0 d-flex flex-row top-nav__actions">
            <v-divider vertical
                       class="top-nav__divider" />
            <top-nav-button v-for="action in localActions"
                            :key="action.title"
                            class="top-nav__action"
                            v-bind="action"
                            @click="emit('action-click', action)" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import debounce from 'lodash.debounce'
    import {
        type Component,
        onBeforeUnmount,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import {
        useRoute,
    } from 'vue-router'

    import OwColoredLabel from "@/components/core/ow-colored-label.vue"

    import TopNavButton from './top-nav-button.vue'
    import TopNavItem from './top-nav-item.vue'
    import {
        NavAction,
        NavItem,
    } from './types'

    const props = withDefaults(defineProps<{
        items?: NavItem[],
        actions?: NavAction[],
        value?: string,
        sideNavCollapsed?: boolean,
        headerTitle?: string,
        hasBetaLabel?: boolean,
        headerIcon?: string,
    }>(), {
        items: () => [],
        actions: () => [],
        sideNavCollapsed: false,
        value: '',
        hasBetaLabel: false,
    })

    const route = useRoute()

    const emit = defineEmits<{
        (e: 'input', route: string),
        (e: 'action-click', action: NavAction),
    }>()

    const isSelected = (item: NavItem) => {
        if (item.childNavName) {
            return item.childNavName === route.meta.childNavName
        } else if (Array.isArray(item.route)) {
            return item.route.some((route) => props.value.includes(route))
        }
        return props.value.includes(item.route)
    }
    const localActions = ref(props.actions)

    /**
     * Set the visibility of the action button labels on mount and when the window is resized
     */
    onMounted(() => {
        window.addEventListener('resize', debouncedResizeHandler)
        debouncedResizeHandler()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', debouncedResizeHandler)
    })

    /**
     * Debounce the resize handler to prevent it from firing too often
     */
    const debouncedResizeHandler = debounce(() => {
        setActionButtonLabelVisibility()
    }, 100)

    /**
     * Set the visibility of the action button labels based on the current toolbar width
     */
    const setActionButtonLabelVisibility = () => {
        const canShowTitle = (action) => {
            const toolbar = document.getElementById('top-nav')
            const toolbarWidth = toolbar?.clientWidth || 0
            switch (action.responsive) {
                case 'xs':
                    return toolbarWidth > 550
                case 'sm':
                    return toolbarWidth > 600
                case 'md':
                    return toolbarWidth > 700
                case 'lg':
                    return toolbarWidth > 800
                default:
                    return true
            }
        }

        localActions.value = localActions.value.map((action) => {
            return {
                ...action,
                showTitle: canShowTitle(action),
            }
        })
    }

    /**
     * Watch for changes in the sideNavCollapsed prop and update the action button labels accordingly
     */
    watch(() => props.sideNavCollapsed, () => {
        setActionButtonLabelVisibility()
    }, {
        immediate: true,
    })

    /**
     * Watch for changes in the route patch and update the action button labels accordingly
     */
    watch(() => route?.path, () => {
        setActionButtonLabelVisibility()
    }, {
        immediate: true,
    })

    defineExpose({
        emit,
    })
</script>

<style lang="scss" scoped>
    @import './top-nav.scss';
</style>
