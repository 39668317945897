<template>
    <title-panel-card :title="titleText"
                      :subtitle="subtitleText"
                      class="find-nearby-container">
        <template #titleSuffix>
            <div v-if="selectedRows.length"
                 class="d-flex flex-row">
                <ow-button-ds is-text
                              is-flat
                              data-test="export-matter-titles-button"
                              data-track="export-matter-titles-button"
                              class="boundary__content--matter-button mx-2"
                              @click="download">
                    <label v-t="'action.exportToExcel'" />
                </ow-button-ds>
                <ow-button-ds v-if="selectedOwners.length"
                              is-text
                              is-flat
                              data-test="nearby-add-titles-button"
                              data-track="TITLE-DETAILS-PANEL - NEARBY - Add to matter from corporate owners"
                              class="boundary__content--matter-button mx-2"
                              @click="addToMatter">
                    {{ $t('matter.addToMatterWithCount', selectedTitlesCount) }}
                </ow-button-ds>
            </div>
        </template>
        <section>
            <find-nearby-header :available-options="nearbyOptions"
                                @distance="handleDistanceUpdated"
                                @selected-option="setSelectedOption" />

            <div v-if="!hasError"
                 class="d-flex"
                 style="height: 70vh;">
                <component :is="selectedNearbyService.displayComponentName"
                           v-if="filteredResults"
                           class="find-nearby-container__results"
                           :is-loading="loading"
                           :service="selectedNearbyService"
                           :records="filteredResults.records"
                           :highlight-results="highlightResults"
                           @selected-owners-updated="handleSelectedOwnersUpdated"
                           @mouse-over-item="handleMouseOverItem" />
                <ow-nearby-map enable-map-interactions
                               :geoserver-nps-layer-name="npsLayer"
                               class="nearby-map"
                               style="height: inherit;"
                               name="find-nearby-map"
                               :selected-nearby-service="selectedNearbyService"
                               :selected-title-numbers="selectedTitleNumbers"
                               :selected-distance="selectedDistance"
                               :current-matter-id="currentMatterId"
                               :features-to-highlight="featuresToHighlight"
                               @filtered-results-updated="handleUpdatedResults"
                               @default-radius-updated="handleDistanceUpdated"
                               @hover-over-features="handleHoverOverFeatures"
                               @error-state-changed="handleErrorUpdated"
                               @loading-state-changed="handleLoadingUpdated" />
            </div>
            <div v-else-if="hasError && !loading"
                 v-t="'titlePanel.findNearby.error'"
                 class="find-nearby-container__error" />
        </section>
    </title-panel-card>
</template>

<script lang="ts">
    import throttle from 'lodash.throttle'
    import Feature from 'ol/Feature'
    import { PropType } from "vue"
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import CoreMap from "@/components/core/maps/core-map.vue"
    import OwNearbyMap from "@/components/core/maps/ow-nearby-map.vue"
    import OwTitleBoundaryMap from "@/components/core/maps/ow-title-boundary-map.vue"
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import OwCard from '@/components/core/ow-card.vue'
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import OwTitleBoundaryMapLoadingSkeleton from "@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue"
    import TitlePanelCard from "@/components/title-panel/v2/cards/title-panel-card.vue"
    import FindNearbyHeader from '@/components/title-panel/v2/find-nearby/find-nearby-header.vue'
    import {
        IFindNearbyResults,
        IFindNearbyService,
    } from '@/components/title-panel/v2/find-nearby/implementations/_common/find-nearby-service.interface'
    import CorporateOwnerResults from '@/components/title-panel/v2/find-nearby/implementations/corporate-owners/corporate-owners-results.vue'
    import { FindNearbyCorporateOwnerService } from '@/components/title-panel/v2/find-nearby/implementations/corporate-owners/corporate-owners-service'
    import MatterTitleResults from '@/components/title-panel/v2/find-nearby/implementations/matter-titles/matter-titles-results.vue'
    import { FindNearbyMatterTitlesService } from '@/components/title-panel/v2/find-nearby/implementations/matter-titles/matter-titles-service'
    import { INearbyMatterTitle } from '@/components/title-panel/v2/find-nearby/implementations/matter-titles/nearby-matter-title.interface'
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from "@/interfaces/store/state.interface"
    import { MATTER_ADD_MULTIPLE_TITLES } from '@/store/modules/matter/types'
    import {
        NPS_GET_FEATURES_BY_TITLE_NUMBERS,
        NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
    } from '@/store/modules/nps/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import {isNullOrEmpty} from "@/utils/array-utils"
    import {exportAsCsv} from "@/utils/csv-utils"
    import {format} from "@/utils/date-utils"

    export default {
        name: 'FindNearbyContainer',
        components: {
            OwButtonDs,
            OwLoadingSkeleton,
            OwTitleBoundaryMapLoadingSkeleton, CoreMap,
            OwTitleBoundaryMap,
            OwNearbyMap,
            FindNearbyHeader,
            MatterTitleResults,
            OwCard,
            CorporateOwnerResults,
            TitlePanelCard,
        },

        mixins: [flagsMixin],

        props: {
            selectedTitleNumbers: {
                type: Array,
                required: true,
            },
            currentMatterId: {
                type: Number,
                required: true,
            },
            tabController: {
                type: Object as PropType<ITitlePanelTabController>,
                required: true,
            },
        },

        data() {
            return {
                selectedNearbyService: null as IFindNearbyService,
                highlightResults: [],
                throttledHeapLogModifyRadius: throttle(this._heapLogModifyRadius, 2000),
                // selectedTitleNumbers: ['TGL50538', 'WA858410', 'BK375158'], // Uncomment to test support for multiple titles.
                loading: false,
                featuresToHighlight: [] as Feature[],
                selectedDistance: 0,
                filteredResults: null as IFindNearbyResults,
                hasError: false,
                selectedRows: [],
                selectedOwners: [],
            }
        },

        computed: {
            ...mapGetters({
                getFeaturesByTitlesNumbers: NPS_GET_FEATURES_BY_TITLE_NUMBERS,
            }),

            ...mapState({
                npsLayer: (state: IState) => state.config.settings.npsLayer,
            }),

            subtitleText(): string {
                return this.selectedNearbyService?.getSubtitleText({
                    filteredResults: this.filteredResults?.records ?? [],
                    selectedDistanceInMetres: this.selectedDistance,
                    selectedTitleNumbers: this.selectedTitleNumbers,
                })
            },

            titleText(): string {
                return this.selectedNearbyService?.getTitleText()
            },

            nearbyOptions(): Array<IFindNearbyService> {
                // Defaults
                const options = [
                    new FindNearbyMatterTitlesService({
                        getMapFeaturesFn: this.getTitleNumberFeatures,
                    }),
                ] as Array<IFindNearbyService>

                // Additional options based on feature flags
                if (this.isFindNearbyCorporateOwnersEnabled) {
                    options.push(new FindNearbyCorporateOwnerService({
                        getMapFeaturesFn: this.getTitleNumberFeatures,
                        onAddTitlesToMatterFn: this.onAddTitlesToMatter,
                    }))
                }
                return options
            },

            activeSection(): ITitlePanelTabController {
                return this.tabController.getActiveSection()
            },

            selectedTitlesCount() {
                return this.selectedOwners.map(x => x.titlesCount).reduce((a, b) => a + b, 0)
            },
        },

        watch: {
            async selectedNearbyService(newService: IFindNearbyService, oldService: IFindNearbyService): Promise<void> {
                oldService?.reset()
                this.selectedNearbyService = newService
            },
            activeSection: {
                handler(section): void {
                    if (section) {
                        const selectedOption = this.nearbyOptions.find(option => option.id === section.id)
                        if (selectedOption) {
                            this.setSelectedOption(selectedOption)
                        } else {
                            this.setSelectedOption(this.nearbyOptions[0])
                        }

                        this.initialised = true
                    }
                },
                deep: true,
                immediate: true,
            },
        },

        mounted() {
            const tabSections = [ {
                id: 'matterTitles',
                order: 0,
                onClick: () => this.setSelectedOption(this.nearbyOptions[0]),
            } ]
            if (this.isFindNearbyCorporateOwnersEnabled) {
                tabSections.push(
                    {
                        id: 'corporateOwners',
                        order: 1,
                        onClick: () => this.setSelectedOption(this.nearbyOptions[1]),
                    })
            }
            this.tabController.addTabSections(TitlePanelTabName.FindNearby, tabSections)
        },

        methods: {
            ...mapActions({
                // TODO: Move logging into service too
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                // TODO: Move the NPS store into a service
                loadFeaturesForTitleNumbers: NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
                addTitlesToMatter: MATTER_ADD_MULTIPLE_TITLES,
            }),

            handleDistanceUpdated(distanceMetres: number): void {
                if (this.selectedDistance !== distanceMetres) {
                    this.selectedDistance = distanceMetres
                }
                this.throttledHeapLogModifyRadius()
            },

            _heapLogModifyRadius(): void {
                if (this.loading) {
                    return
                }
                this.logHeapEvent({
                    type: 'NEARBY TAB - Modify radius',
                    metadata: {
                        distanceMetres: this.selectedDistance,
                        visibleResults: this.filteredResults?.records?.length ?? 0,
                    },
                })
            },

            handleMouseOverItem(item: INearbyMatterTitle): void {
                this.featuresToHighlight = this.selectedNearbyService.getFeaturesForRecords([item], this.selectedDistance)
            },

            async getTitleNumberFeatures(titleNumbers: number[]): Promise<Feature[]> {
                await this.loadFeaturesForTitleNumbers(titleNumbers)
                const features = await this.getFeaturesByTitlesNumbers(titleNumbers)
                return features
            },

            async setSelectedOption(service: IFindNearbyService) {
                if (service.displayComponentName !== this?.selectedNearbyService?.displayComponentName) {
                    this.filteredResults = null
                    this.selectedOwners = []
                    this.selectedNearbyService = service
                }
            },

            async onAddTitlesToMatter(titleNumbers: Array<string>) {
                await this.addTitlesToMatter({
                    showPopup: true,
                    titleNumbers,
                })
            },

            handleUpdatedResults(results: IFindNearbyResults): void {
                if(!isNullOrEmpty(results?.records)) {
                    const data = []
                    this.selectedRows = []
                    if (this.selectedNearbyService.id === 'corporateOwners') {
                        const headers = ['Owner', 'Company Registration Numbers', 'Title', 'Title Address', 'Distance (Metres)']
                        results.records
                            .forEach(x => {
                                x.titles.forEach(t => {
                                    data.push([
                                        x.ownerName,
                                        x.companyRegistrationNumbers.join(', '),
                                        t.titleNumber,
                                        t.address,
                                        t.distanceMetres,
                                    ])
                                })
                            })
                        this.selectedRows.push(headers, ...data)
                    } else if (this.selectedNearbyService.id === 'matterTitles') {
                        const headers = ['Title Number', 'In Matter', 'In Group']
                        results.records
                            .forEach(x => {
                                data.push([
                                    x.titleNumber,
                                    x.matterName,
                                    x.groupName ?? 'N/A',
                                ])
                            })
                        this.selectedRows.push(headers, ...data)
                    }
                }
                this.filteredResults = results
            },

            handleHoverOverFeatures(features: Feature[]): void {
                this.highlightResults = features
            },

            handleErrorUpdated(hasError: boolean): void {
                this.hasError = hasError
            },

            handleLoadingUpdated(isLoading: boolean): void {
                this.loading = isLoading
            },

            exportAsCsv,

            download(): Array<string> {
                const filename = `nearby-data-export-${ format(new Date(), 'dd-MM-yyyy') }.csv`
                this.exportAsCsv(this.selectedRows, filename)
            },

            handleSelectedOwnersUpdated(selectedOwners: Array<any>): void {
                this.selectedOwners = selectedOwners
            },

            addToMatter() {
                const titlesToAdd = this.selectedOwners.flatMap(x => x.titles.map(t => t.titleNumber))
                this.selectedNearbyService.onAddTitlesToMatter(titlesToAdd)
            },
        },
    }
</script>

<style scoped lang="scss">
    @import './find-nearby-container-with-map';
</style>
