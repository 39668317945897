<template>
    <div :class="{
             'is-new-matter': isNewMatter,
             'elevation-3': addBorder,
         }"
         class="matter-details-permissions">
        <v-radio-group v-model="shareTypeModelValue"
                       color="primary"
                       data-test="matter-details-permissions-options"
                       hide-details
                       @update:model-value="checkTypeEdit">
            <v-radio v-if="isNewMatter || matter.createdByMe"
                     color="primary"
                     data-test="permissions-radio-private"
                     data-track="MAT-SETTINGS - Select private matter"
                     :value="MatterShareType.Private">
                <template #label>
                    <div v-t="'matter.share.privateMatter'"
                         class="body-copy" />
                </template>
            </v-radio>

            <v-radio color="primary"
                     data-test="permissions-radio-specific"
                     data-track="MAT-SETTINGS - Select share with specific users"
                     :value="MatterShareType.Specific">
                <template #label>
                    <div v-t="'matter.share.withSpecificPeople'"
                         class="body-copy" />
                </template>
            </v-radio>

            <div v-if="shareTypeModelValue === MatterShareType.Specific"
                 class="matter-details-permissions__people-list"
                 data-test="permissions-people-list">
                <div v-if="isLoadingPermissionsList"
                     class="matter-details-permissions__people-list--loading-skeleton"
                     data-test="permissions-loading-skeleton">
                    <ow-loading-skeleton margin-left="32"
                                         width="256" />
                    <ow-loading-skeleton margin="8 0 0 32"
                                         width="256" />
                    <ow-loading-skeleton margin="8 0 16 32"
                                         width="256" />
                </div>
                <ow-field>
                    <v-text-field v-if="!isLoadingPermissionsList"
                                  id="filter-people"
                                  v-model="filterPermissionsText"
                                  class="matter-details-permissions__people-list--filter"
                                  :clearable="true"
                                  data-test="permissions-filter-people"
                                  data-track="MAT-SETTINGS - Select filter users"
                                  hide-details
                                  variant="outlined"
                                  :placeholder="$t('action.filter')"
                                  prepend-inner-icon="$search" />
                </ow-field>

                <ul v-if="!isLoadingPermissionsList"
                    class="matter-details-permissions__filter-people hide-in-percy"
                    data-test="permissions-filter-people">
                    <li v-for="permission in filteredPermissions"
                        :key="permission.userId"
                        class="hide-in-percy">
                        <ow-checkbox :id="`matter-permissions-${permission.userId}`"
                                     v-model="permission.shared"
                                     :label="permission.userName"
                                     @input="checkForUserPermissionsEdit" />
                    </li>
                </ul>
            </div>


            <v-radio v-if="canShareMattersWithOrg"
                     color="primary"
                     data-test="permissions-radio-whole-organisation"
                     :value="MatterShareType.All">
                <template #label>
                    <div v-t="'matter.share.withEveryone'"
                         class="body-copy"
                         data-track="MAT-SETTINGS - Select share with whole organisation" />
                </template>
            </v-radio>
        </v-radio-group>

        <div v-if="!hideActions">
            <v-row v-if="!isNewMatter"
                   class="matter-details-permissions__actions">
                <ow-tooltip :disabled="shareTypeModelValue !== MatterShareType.Specific || hasSelectedPeople"
                            :position="OwTooltipPosition.Bottom">
                    <template #activator>
                        <div>
                            <v-btn :disabled="isUpdateButtonDisabled"
                                   :loading="isLoading"
                                   color="primary"
                                   data-test="permissions-button-update"
                                   data-track="MAT-SETTINGS - Update permissions"
                                   size="large"
                                   @click="update">
                                Update
                            </v-btn>
                        </div>
                    </template>
                    <span v-t="'matter.share.youMustSelectOnePerson'" />
                </ow-tooltip>
            </v-row>
            <v-row v-if="isNewMatter"
                   class="matter-details-permissions__actions d-flex justify-space-between">
                <div class="matter-details-permissions__actions--left-btns">
                    <v-btn :disabled="isLoading"
                           data-test="matter-create-cancel"
                           size="large"
                           variant="outlined"
                           @click="cancel">
                        {{ $t('action.cancel') }}
                    </v-btn>
                    <v-btn :disabled="isLoading"
                           class="matter-details-permissions__action-back"
                           data-test="permissions-button-back"
                           size="large"
                           variant="text"
                           @click="goBack">
                        {{ $t('action.back') }}
                    </v-btn>
                </div>
                <v-btn :disabled="isCreateButtonDisabled"
                       :loading="isLoading"
                       color="primary"
                       data-test="permissions-button-create"
                       size="large"
                       @click="update">
                    {{ shareTypeModelValue === MatterShareType.Private ? $t('matter.share.createMatter') : $t('matter.share.createAndShareMatter') }}
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        onActivated,
        onMounted,
        ref,
        watch,
    } from "vue"
    import {
        useStore,
    } from "vuex"

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwField from "@/components/core/ow-field.vue"
    import owLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import { MatterShareType } from "@/enums/matter-share-type.enum"
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import { CORE_MUTATE_PREVENT_TRANSITION } from "@/store/modules/core/types"
    import { MATTER_REFRESH_PERMISSIONS,
             MATTER_UPDATE_PERMISSIONS } from "@/store/modules/matter/types"
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_INTERCOM_TRACK_EVENT,
        USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG,
    } from "@/store/mutation-types"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const props = defineProps<{
        matter?: any,
        loading?: boolean,
        permissions?: any[],
        isNewMatter?: boolean,
        resetFormOnModalClose?: boolean,
        addBorder?: boolean,
        hideActions?: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'update', value: any): void,
        (e: 'cancel'): void,
    }>()

    const store = useStore()

    const shareTypeModelValue = defineModel<MatterShareType>('shareType', {
        default: MatterShareType.Private,
    })
    const selectedModelValue = defineModel('selected')

    const originalShareType = ref(MatterShareType.Private)
    const originalPermissionUserIds = ref([])
    const isUpdating = ref(false)
    const hasEdits = ref(false)
    const filterPermissionsText = ref(null)
    const isLoadingPermissionsList = ref(false)

    const hasSelectedPeople = computed(() => props.permissions.some((permission) => permission.shared))
    const isLoading = computed(() => props.loading || isUpdating.value)
    const isUpdateButtonDisabled = computed(() => !hasEdits.value || (shareTypeModelValue.value === MatterShareType.Specific && !hasSelectedPeople.value) || isLoading.value)
    const isCreateButtonDisabled = computed(() => isLoading.value || (shareTypeModelValue.value === MatterShareType.Specific && !hasSelectedPeople.value))
    const filteredPermissions = computed(() => {
        if (!isNullOrWhitespace(filterPermissionsText.value)) {
            return props.permissions.filter(permission =>
                permission.userName.toLowerCase().includes(filterPermissionsText.value.toLowerCase()))
        }
        return props.permissions
    })
    const canShareMattersWithOrg = computed(() => store.getters[USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG])

    const updateCurrentShareType = () => {
        if (!props.isNewMatter) {
            if (props.matter.id) {
                shareTypeModelValue.value = MatterShareType.Private // assume private unless otherwise
                if (props.matter.sharedWithOrganisation === true) {
                    shareTypeModelValue.value = MatterShareType.All
                } else if (props.matter.sharedWithSpecificUsers === true) {
                    shareTypeModelValue.value = MatterShareType.Specific
                }
            }
            originalShareType.value = shareTypeModelValue.value
        }
    }

    const refreshPermissions = async () => {
        isLoadingPermissionsList.value = true
        await store.dispatch(MATTER_REFRESH_PERMISSIONS, props.matter.id)
        isLoadingPermissionsList.value = false
    }

    const resetForm = () => {
        props.permissions.forEach(permission => {
            if (!originalPermissionUserIds.value.includes(permission.userId)) {
                permission.shared = false
            }
        })
        shareTypeModelValue.value = originalShareType.value
        hasEdits.value = false
    }

    const getPermissionUserIds = () => {
        if (!props.permissions || !Array.isArray(props.permissions)) {
            return []
        }
        return props.permissions
            .filter(s => {
                if (s.shared) {
                    return s.userId
                }
                return false
            })
            .map(r => r.userId)
    }

    const checkForUserPermissionsEdit = () => {
        const currentPermissionUserIds = getPermissionUserIds()
        hasEdits.value = currentPermissionUserIds.join('') !== originalPermissionUserIds.value.join('')

        if (hasEdits.value) {
            store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAT-SETTINGS - Select specific user',
                metadata: {
                    sharedWithUserCount: currentPermissionUserIds.length,
                },
            })
        }
    }

    const update = async () => {
        if (!props.isNewMatter) {
            isUpdating.value = true

            let userIds = []
            if (shareTypeModelValue.value === MatterShareType.Specific) {
                userIds = getPermissionUserIds()
                originalPermissionUserIds.value = getPermissionUserIds()
            } else {
                props.permissions.forEach(s => {
                    s.shared = false
                })
                originalPermissionUserIds.value = []
            }

            const request = {
                matterId: props.matter.id,
                shareWithOrganisation: shareTypeModelValue.value === MatterShareType.All,
                userIds,
            }

            await store.dispatch(LOGGING_INTERCOM_TRACK_EVENT, {
                type: 'matter-shared',
                metadata: request,
            })
            await store.dispatch(MATTER_UPDATE_PERMISSIONS, request)

            isUpdating.value = false
            hasEdits.value = false
            originalShareType.value = shareTypeModelValue.value
        } else {
            emit('update', { // matter create -- NEEDS REFACTORING!
                shareType: shareTypeModelValue.value,
                userIds: getPermissionUserIds(),
            })
        }
    }

    const goBack = () => {
        window.history.back()
    }

    const cancel = () => {
        emit('cancel')
    }

    const checkTypeEdit = () => {
        hasEdits.value = shareTypeModelValue.value !== originalShareType.value
    }

    const updateSelectedModelValue = () => {
        let userIds = []
        if (shareTypeModelValue.value === MatterShareType.Specific) {
            userIds = getPermissionUserIds()
            originalPermissionUserIds.value = getPermissionUserIds()
        }
        selectedModelValue.value = userIds
    }

    watch(() => props.matter?.id, () => {
        updateCurrentShareType()
        refreshPermissions()
    })

    watch(() => props.matter?.sharedWithMe, () => {
        updateCurrentShareType()
    })

    watch(() => props.matter?.sharedWithOrganisation, () => {
        updateCurrentShareType()
    })

    watch(() => props.matter?.sharedWithSpecificUsers, () => {
        updateCurrentShareType()
    })

    watch(() => hasEdits.value, (val) => {
        if (!props.isNewMatter) {
            store.commit(CORE_MUTATE_PREVENT_TRANSITION, {
                isFormDirty: val,
                resetForm: resetForm,
                submitForm: update,
                resetFormOnModalClose: props.resetFormOnModalClose,
            })
        }
    })

    watch(() => props.permissions, () => {
        originalPermissionUserIds.value = getPermissionUserIds()
        updateSelectedModelValue()
    }, {
        deep: true,
    })

    watch(() => shareTypeModelValue.value, (newVal) => {
        store.dispatch(LOGGING_INTERCOM_TRACK_EVENT, {
            type: 'MAT-SETTINGS - Select share with',
            metadata: {
                shareType: newVal,
            },
        })
    })

    onMounted(() => {
        if (props.matter) {
            updateCurrentShareType()
            refreshPermissions()
        }
    })

    defineExpose({
        update,
        hasEdits,
        getPermissionUserIds,
        checkTypeEdit,
        checkForUserPermissionsEdit,
        originalPermissionUserIds,
        filterPermissionsText,
        filteredPermissions,
        permissions: props.permissions,
    })
</script>

<style lang="scss">
    @import './permissions.scss';
</style>
