<template>
    <base-sub-type-item v-model="isShowingDetailsPanel"
                        v-model:diff="diff"
                        :data-track-type="dataTrackType"
                        :notification="notification"
                        :show-expand-collapse="updateCount > 0"
                        :data-test="dataTest">
        <div v-if="updateCount === 0"
             class="base-sub-type-item--no-changes body-italic-x-small d-flex align-center gc-1">
            <v-icon>
                $info
            </v-icon>
            <span v-t="'label.noChangesFound'" />
        </div>
        <table v-show="updateCount > 0"
               class="base-sub-type-item__table">
            <base-sub-type-table-headers :detail-columns="detailColumns" />
            <base-sub-type-table-rows v-model:update-count="updateCount"
                                      :diff="diff"
                                      :render-details-column="renderDetailsColumn"
                                      :detail-columns="detailColumns"
                                      :headings="headings" />
        </table>
    </base-sub-type-item>
</template>

<script setup lang="ts" generic="T">
    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import { Difference } from "@/components/asset-monitoring/difference-generic"
    import BaseSubTypeItem from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-item.vue"
    import BaseSubTypeTableHeaders
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table-headers.vue"
    import BaseSubTypeTableRows
        from "@/components/asset-monitoring/grid/sub-type-items/base-sub-type-table/base-sub-type-table-rows.vue"
    import {
        IBaseSubTypeTableColumn,
    } from "@/components/asset-monitoring/grid/sub-type-items/types"

    defineProps<{
        notification: IAssetMonitoringNotificationDetail,
        dataTrackType: string,
        dataTest: string,

        headings?: IBaseSubTypeTableColumn<T>[],
        renderDetailsColumn?: boolean
        detailColumns?: IBaseSubTypeTableColumn<T>[],
    }>()

    const isShowingDetailsPanel = defineModel<boolean>({
        default: false,
    })
    const diff = defineModel<Difference<T>>('diff')
    const updateCount = defineModel<number>('update-count')
</script>

<style lang="scss" scoped>
    @import "../base-sub-type-item";
</style>
