import i18n from '@/plugins/i18n'

export const getTemplates = () => [
    {
        name: i18n.global.t('tam.templates.allRegisterEntries.name'),
        description: i18n.global.t('tam.templates.allRegisterEntries.description'),
        columns: [
            'titleNumber',
            'matterGroup',
            'entryNumber',
            'entryText',
            'entryDate',
            'entryType',
            'registerSection',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
        groupBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.overseasProprietor.name'),
        description: i18n.global.t('tam.templates.overseasProprietor.description'),
        columns: [
            'titleNumber',
            'matterGroup',
            'propertyAddresses',
            'tenureType',
            'proprietorName',
            'proprietorAddress',
            'proprietorCompanyRegNo',
            'countryIncorporated',
            'overseas',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
        groupBy: {
            property: 'overseas',
        },
    },
    {
        name: i18n.global.t('tam.templates.companiesHouse.name'),
        description: i18n.global.t('tam.templates.companiesHouse.description'),
        columns: [
            'titleNumber',
            'matterGroup',
            'propertyAddresses',
            'tenureType',
            'proprietorName',
            'proprietorAddress',
            'proprietorCompanyRegNo',
            'companyName',
            'companyStatus',
            'companyStatusActive',
            'chTitleOwnerMatch',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.defectiveFreehold.name'),
        description: i18n.global.t('tam.templates.defectiveFreehold.description'),
        columns: [
            'titleNumber',
            'tenureType',
            'qualityOfTenure',
            'propertyAddresses',
            'proprietorName',
        ],
        filters: [
            {
                property: 'tenureType',
                value: ['Freehold'],
            },
            {
                property: 'qualityOfTenure',
                value: 'Absolute',
                operator: '!=',
            },
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.defectiveLeasehold.name'),
        description: i18n.global.t('tam.templates.defectiveLeasehold.description'),
        columns: [
            'titleNumber',
            'tenureType',
            'qualityOfTenure',
            'propertyAddresses',
            'proprietorName',
        ],
        filters: [
            {
                property: 'tenureType',
                value: ['Leasehold'],
            },
            {
                property: 'qualityOfTenure',
                value: 'Absolute',
                operator: '!=',
            },
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.expiringLeases.name'),
        description: i18n.global.t('tam.templates.expiringLeases.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'qualityOfTenure',
            'tenureType',
            'editionDate',
            'proprietorName',
            'proprietorCompanyRegNo',
            'parentTitleNumber',
            'leaseDate',
            'term',
            'leaseStartDate',
            'leaseEndDate',
            'remainingLeaseTerm',
            'remainingLeaseTermDays',
        ],
        filters: [
            {
                property: 'tenureType',
                value: ['Leasehold'],
            },
        ],
        sortBy: {
            property: 'remainingLeaseTermDays',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.location.name'),
        description: i18n.global.t('tam.templates.location.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'proprietorName',
            'propertyDistrict',
            'propertyRegion',
            'propertyCounty',
            'propertyPostcode',
        ],
        groupBy: {
            property: 'propertyRegion',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.chargedOnly.name'),
        description: i18n.global.t('tam.templates.chargedOnly.description'),
        columns: [
            'titleNumber',
            'proprietorName',
            'tenure',
            'chargesTotalCount',
            'chargeCount',
            'bankChargeHolders',
            'allRestrictionsCount',
            'deedOfPostponementCount',
            'equitableChargeCount',
        ],
        sortBy: {
            property: 'chargesTotalCount',
            ascending: false,
        },
        filters: [
            {
                property: 'chargesTotalCount',
                value: 0,
                operator: '>',
            },
        ],
    },
    {
        name: i18n.global.t('tam.templates.freeholdTitle.name'),
        description: i18n.global.t('tam.templates.freeholdTitle.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'tenureType',
            'proprietorName',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
        filters: [
            {
                property: 'tenureType',
                value: ['Freehold'],
            },
        ],
    },
    {
        name: i18n.global.t('tam.templates.leaseholdTitle.name'),
        description: i18n.global.t('tam.templates.leaseholdTitle.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'tenureType',
            'proprietorName',
            'leaseDate',
            'term',
            'leaseStartDate',
            'leaseEndDate',
            'remainingLeaseTerm',
            'rent',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
        filters: [
            {
                property: 'tenureType',
                value: ['Leasehold'],
            },
        ],
    },
    {
        name: i18n.global.t('tam.templates.restrictions.name'),
        description: i18n.global.t('tam.templates.restrictions.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'proprietorName',
            'chargeRestrictions',
            'nonChargeRestrictions',
            'allRestrictionsCount',
        ],
        sortBy: {
            property: 'allRestrictionsCount',
            ascending: false,
        },
        filters: [
            {
                property: 'allRestrictionsCount',
                value: 0,
                operator: '>',
            },
        ],
    },
    {
        name: i18n.global.t('tam.templates.proprietorsAndChargeHolders.name'),
        description: i18n.global.t('tam.templates.proprietorsAndChargeHolders.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'proprietorEntryInTitleRegister',
            'proprietorAddress',
            'proprietorCompanyRegNo',
            'bankChargeHolders',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.titlesWithMoreThanOneAddress.name'),
        description: i18n.global.t('tam.templates.titlesWithMoreThanOneAddress.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'proprietorName',
            'propertyAddressCount',
        ],
        filters: [
            {
                property: 'propertyAddressCount',
                value: 1,
                operator: '>',
            },
        ],
        sortBy: {
            property: 'propertyAddressCount',
            ascending: false,
        },
    },
    {
        name: i18n.global.t('tam.templates.landRemovedFromTitle.name'),
        description: i18n.global.t('tam.templates.landRemovedFromTitle.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenure',
            'proprietorName',
            'greenOutEntryCount',
        ],
        filters: [
            {
                property: 'greenOutEntryCount',
                value: 0,
                operator: '>',
            },
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
    {
        name: i18n.global.t('tam.templates.proprietorsAndLeaseInfo.name'),
        description: i18n.global.t('tam.templates.proprietorsAndLeaseInfo.description'),
        columns: [
            'titleNumber',
            'propertyAddresses',
            'tenureType',
            'propertyDistrict',
            'propertyRegion',
            'propertyCounty',
            'propertyPostcode',
            'proprietorName',
            'proprietorAddress',
            'proprietorCompanyRegNo',
            'overseas',
            'parentTitleNumber',
            'leaseStartDate',
            'leaseEndDate',
            'leaseParty',
            'complexityLabel',
        ],
        sortBy: {
            property: 'titleNumber',
            ascending: true,
        },
    },
]
