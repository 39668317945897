<template>
    <div class="matter-create-company-alerts gc-4 d-flex mb-4">
        <div class="matter-create-company-alerts__search">
            <find-companies-card v-model:items="formData.items"
                                 v-model:selected="formData.selected"
                                 v-model:search="formData.search"
                                 fixed-height
                                 :current-group="formData.currentGroup" />
        </div>
        <div class="matter-create-company-alerts__update">
            <update-monitoring-group-card v-model:selected="formData.selected"
                                          v-model:dirty="formData.dirty"
                                          v-model="formData.currentGroup"
                                          hide-primary-button
                                          hide-secondary-button
                                          fixed-height
                                          :is-update="false"
                                          :is-saving="false"
                                          :is-loading="false"
                                          @save="onSave" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import FindCompaniesCard from "@/components/asset-monitoring/company-groups/find-companies-card.vue"
    import {
        IAssetMonitoringCompanyGroup,
        IAssetMonitoringCompanyGroupItem,
        IAssetMonitoringCompanyGroupSelected,
    } from "@/components/asset-monitoring/company-groups/types"
    import UpdateMonitoringGroupCard
        from "@/components/asset-monitoring/company-groups/update-monitoring-group-card.vue"
    import { IWizardStep } from "@/components/core/ow-wizard"
    import { IMatterCreateCompanyAlertsStepData } from "@/components/matter/create/types"
    import { inject as userProvider } from "@/composables/use-user"
    import { checkFlag } from "@/feature-flags"

    const props = defineProps<{
        step: IWizardStep<IMatterCreateCompanyAlertsStepData>
        stepData: Record<string, Record<string,any>>
    }>()

    const { t } = useI18n()

    const formData = computed({
        get: () => props.step.data,
        set: (value: IMatterCreateCompanyAlertsStepData) => {
            props.step.data = value
        },
    })

    const nextButtonLabel = computed(() => {
        return formData.value.currentGroup?.groupName && formData.value.dirty ? t('action.next') : t('action.setupLater')
    })

    const nextButtonIsSecondary = computed(() => {
        return !(formData.value.currentGroup?.groupName && formData.value.dirty)
    })

    const { hasAccessToAlerts } = userProvider()
    const isCompanyMonitorsEnabled = computed<boolean>(() => checkFlag('asset-monitoring-company-monitors-tabs', false))
    const hasAccess = ref<boolean>(false)

    onBeforeMount(async () => {
        formData.value = {
            items: [],
            selected: {
                searchRef: {
                    id: '',
                    searchText: '',
                },
                selected: [],
            },
            search: '',
            currentGroup: null,
            dirty: false,
        }
        props.step.disabled = true
        props.step.nextButtonLabel = nextButtonLabel
        props.step.nextButtonIsSecondary = nextButtonIsSecondary

        hasAccess.value = await hasAccessToAlerts()
    })

    const stepIndex = defineModel<number>()

    const onSave = () => {
        stepIndex.value = stepIndex.value + 1
    }

    watch(() => props.stepData, async () => {
        if (!hasAccess.value || !isCompanyMonitorsEnabled.value) {
            props.step.disabled = true
            return
        }
        props.step.disabled = props.stepData?.['matter-features']?.toggleCompanyAlerts !== true ?? true
    }, { deep: true, immediate: true })
</script>

<style lang="scss">
@import './matter-create-company-alerts-step.scss';
</style>