<template>
    <div :class="computedClass"
         :data-pa-index="properties.index"
         class="planning-application-result-item"
         @mouseleave="mouseOver = false"
         @mouseover="mouseOver = true">
        <div v-if="show"
             data-test="planning-application-result-item">
            <div class="planning-application-result-item__wrapper-top">
                <div v-dompurify-html="addressHTML"
                     class="planning-application-result-item__address"
                     data-test="planning-application-result-item-address" />
                <div>
                    <v-btn :disabled="!properties.url"
                           class="planning-application-result-item__open-on-portal"
                           data-test="planning-application-result-item-open-on-portal"
                           data-track="TITLE-DETAILS-PANEL - Open planning application on portal"
                           icon
                           size="small"
                           variant="text"
                           @click="openExternalPortal(properties.url)">
                        <v-icon>$open-in-new</v-icon>
                    </v-btn>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-if="properties.url">View on planning authority portal.</span>
                        <span v-else>We don't have a link to this planning application. Please check the local authority's portal.</span>
                    </ow-tooltip>
                </div>
            </div>

            <div class="planning-application-result-item__status-details hide-in-percy">
                <div :class="ratingClassName"
                     class="decision">
                    <span :class="backgroundClassName">
                        <strong>{{ properties.decisionText }}</strong>
                    </span>
                </div>
                <div class="date">
                    {{ receivedDateString }}
                </div>
                <div class="type">
                    ({{ properties.typeText }})
                </div>
                <div v-dompurify-html="identifierHTML"
                     class="identifier"
                     data-test="planning-application-result-item-identifier" />
            </div>

            <div v-dompurify-html="descriptionHTML"
                 class="planning-application-result-item__description body-copy--greyout"
                 data-test="planning-application-result-item-description" />
        </div>
    </div>
</template>

<script>

    import {mapMutations,
            mapState} from 'vuex'

    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import {OwTooltipPosition} from '@/enums/ow-tooltip-position'
    import {PLANNING_MUTATE_HIGHLIGHT_FEATURES} from '@/store/modules/planning/types'

    export default {

        components: {
            OwTooltip,
        },

        props: {
            result: {
                type: Object,
                required: true,
            },
            scrollElementContainer: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                mouseOver: false,
                element: null, // A reference to the component element determined on mounted().
                scrollVisibility: false, // Used to determine whether an element should be visible based on it's scroll position.
                scrollElement: null, // The parent scrollable element.
                scrollObserver: null, // The Intersection Observer used to detect visibility changes.
                OwTooltipPosition,
            }
        },

        computed: {

            ...mapState({
                highlightedFeatures: state => state.planning.highlightedFeatures,
                selectedFeatures: state => state.planning.selectedFeatures,
                textFilterKeywords: state => state.planning.inputs.textFilterKeywords,
            }),

            show() {
                return this.scrollVisibility
            },

            properties() {
                return this.result.getProperties()
            },

            ratingClassName() {
                return `planning-rating--${ this.properties.decision }`
            },

            backgroundClassName() {
                return `decision-span-rating--${ this.properties.decision }`
            },

            receivedDateString() {
                const dateString = this.properties.receivedDate?.substr(0, 10)
                if (dateString) {
                    return `(${ dateString })`
                }
                return ''
            },

            isHighlighted() {
                return this.highlightedFeatures.includes(this.result)
            },

            isSelected() {
                // For now, only one result can be selected; which is a significant shortcomming.
                // Clusters of results are to be addressed in a subsequent PR.
                return this.selectedFeatures.length > 0 ? this.selectedFeatures[0] === this.result : false
            },

            computedClass() {
                return {
                    selected: this.isSelected,
                    highlighted: this.isHighlighted,
                }
            },

            addressHTML() {
                return this.getTextWithSearchHighlights(this.properties.address)
            },

            descriptionHTML() {
                return this.getTextWithSearchHighlights(this.properties.description)
            },

            identifierHTML() {
                return this.getTextWithSearchHighlights(this.properties.identifier)
            },
        },

        watch: {
            mouseOver(val) {
                if (val) {
                    this.highlightFeatures([this.result])
                } else {
                    this.highlightFeatures([])
                }
            },
        },

        mounted() {
            this.initialiseIntersectionObserver()
        },

        methods: {

            ...mapMutations({
                highlightFeatures: PLANNING_MUTATE_HIGHLIGHT_FEATURES,
            }),

            initialiseIntersectionObserver() {
                // Listen for changes to the position of this component within it's parent scrollable element
                if (this.scrollObserver === null) {
                    this.element = this.$el
                    this.scrollElement = document.querySelector(this.scrollElementContainer)
                    if (this.scrollElement) {
                        const options = {
                            root: this.scrollElement,
                            rootMargin: '352px',
                            threshold: 1.0,
                        }

                        this.scrollObserver = new IntersectionObserver((entries) => {
                            entries.forEach(entry => {
                                if (parseInt(entry.target.dataset.paIndex) === this.properties.index) {
                                    this.scrollVisibility = entry.intersectionRatio > 0
                                }
                            })
                        }, options)
                        this.scrollObserver.observe(this.element)
                    }
                }
            },

            getTextWithSearchHighlights(text) {
                // eslint-disable-next-line no-unused-expressions
                this.textFilterKeywords?.forEach(keyword => {
                    text = text.replace(new RegExp(keyword, 'gi'), match => {
                        return `<span class="highlight-text">${ match }</span>`
                    })
                })
                return text
            },

            openExternalPortal(url) {
                window.open(url, '_blank')
            },
        },
    }

</script>
<style lang="scss" scoped>
    @import 'planning-application-result-item';
</style>
