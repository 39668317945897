<template>
    <section class="copilot-document-selection">
        <header>
            <copilot-cta :client-code="clientCode"
                         :description="$t('copilot.documentSelection.ctaHeader.description')"
                         :document-ids="selectedDocumentIds"
                         :matter-code="matterCode"
                         :matter-id="currentMatterId"
                         :title="$t('copilot.documentSelection.ctaHeader.title')"
                         :title-number="selectedTitleNumbers?.length === 1 ? selectedTitleNumbers[0] : null"
                         no-outline
                         position="Document selection modal" />
        </header>
        <template v-if="isLoading">
            <ow-loading-skeleton class="copilot-document-selection__loading-skeleton"
                                 height="600px" />
        </template>
        <template v-else>
            <div class="copilot-document-selection__main">
                <ow-textfield v-model="filterText"
                              :placeholder="$t('action.filterDocs')"
                              class="copilot-document-selection__main--filter"
                              data-test="document-selection-filter"
                              data-track="Copilot Document Selection - Filter Documents"
                              clearable>
                    <template #iconPrefix>
                        <v-icon>$filter</v-icon>
                    </template>
                    <span v-dompurify-html="$t('copilot.documentSelection.filterDocumentsHint')" />
                </ow-textfield>

                <div class="grid-table">
                    <div class="grid-table__header">
                        <ow-checkbox id="select-all"
                                     v-model="areAllSelected"
                                     :disabled="isNullOrEmpty(filteredItemDocuments)"
                                     :indeterminate="isSelectAllIndeterminate" />
                        <div class="grid-table__header--cell label-caps-small">
                            {{ $t('copilot.documentSelection.tableHeaders.docName') }}
                        </div>
                        <div class="grid-table__header--cell label-caps-small">
                            {{ $t('copilot.documentSelection.tableHeaders.type') }}
                        </div>
                        <div class="grid-table__header--cell label-caps-small">
                            {{ $t('copilot.documentSelection.tableHeaders.title') }}
                        </div>
                        <div class="grid-table__header--cell label-caps-small">
                            {{ $t('copilot.documentSelection.tableHeaders.group') }}
                        </div>
                        <div class="grid-table__header--cell label-caps-small">
                            {{ $t('copilot.documentSelection.tableHeaders.docDate') }}
                        </div>
                    </div>

                    <div class="grid-table__groups">
                        <document-selection-title-num-group v-for="group in filteredItems"
                                                            :key="group.titleNumber"
                                                            :data-group="group.titleNumber"
                                                            :group="group"
                                                            scroll-element-container=".grid-table__groups"
                                                            @change="updateDocumentsSelected" />
                    </div>
                </div>
            </div>

            <footer class="copilot-document-selection__footer">
                <ow-button data-test="document-selection-back-button"
                           data-track="Copilot Document Selection: Back button"
                           @click="emit('close')">
                    {{ $t('action.back') }}
                </ow-button>
                <div class="copilot-document-selection__footer--continue">
                    <p v-if="!hasSelectedDocuments"
                       data-test="document-selection-no-documents-selected">
                        {{ $t('copilot.documentSelection.noDocsSelected') }}
                    </p>
                    <p v-if="selectedDocumentIds.length > COPILOT_DOC_LIMIT"
                       class="copilot-document-selection__footer--error"
                       data-test="document-selection-error-message">
                        {{ maxDocsSelectedMessage }}
                    </p>
                    <ow-button v-if="hasCopilotRole"
                               :disabled="!hasSelectedDocuments"
                               is-primary
                               data-test="document-selection-send-to-copilot-button"
                               data-track="Copilot Document Selection: Send to Copilot button"
                               @click="handleSubmit">
                        {{ $t('action.sendToOrbitalCopilot') }}
                    </ow-button>
                </div>
            </footer>
        </template>
    </section>
</template>

<script lang="ts" setup>
    import { computed,
             onMounted,
             PropType,
             reactive,
             ref,
             watch } from 'vue'
    import { useStore } from 'vuex'

    import { COPILOT_DOC_LIMIT,
             COPILOT_SUPPORTED_DOC_TYPES } from '@/components/copilot/constants'
    import CopilotCta from '@/components/copilot/copilot-cta.vue'
    import { FilterDocuments } from '@/components/copilot/document-selection/dcoument-filter'
    import { IDocumentSelectionGroup } from '@/components/copilot/document-selection/document-selection-group.interface'
    import { IDocumentSelectionGroupDocument } from '@/components/copilot/document-selection/document-selection-group-document.interface'
    import DocumentSelectionTitleNumGroup from '@/components/copilot/document-selection/document-selection-title-num-group.vue'
    import { useCopilot } from '@/components/copilot/use-copilot'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { ApplicationRoles } from "@/enums/application-roles.enum"
    import { IState } from "@/interfaces/store/state.interface"
    import {
        DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
        GET_LIBRARY_DOCUMENTS,
        GET_LIBRARY_UPLOADED_DOCUMENTS,
        REFRESH_DOCUMENTS,
    } from '@/store/modules/documents/documents-types'
    import { OPEN_WINDOW } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { format } from '@/utils/date-utils'
    import { getPurchasedDocumentName } from '@/utils/document-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const emit = defineEmits<{
        (e: 'close'),
    }>()

    const store = useStore()
    const currentMatterId = computed((): number => parseInt(store.state.matter.currentMatter.id))
    const clientCode = computed((): string => store.state.matter.currentMatter.clientCode)
    const matterCode = computed((): string => store.state.matter.currentMatter.code)
    const matterTitles = computed(() => store.state.matter.currentMatter.selectedTitles)
    const matterGroups = computed(() => store.state.matter.currentMatter.groups)
    const purchasedDocuments = computed((): any[] => store.getters[GET_LIBRARY_DOCUMENTS])
    const uploadedDocuments = computed((): any[] => store.getters[GET_LIBRARY_UPLOADED_DOCUMENTS])
    const hasCopilotRole = computed(() => store.state.user.roles.includes(ApplicationRoles.Copilot))

    const items = reactive<Array<IDocumentSelectionGroup>>([])
    const filterText = ref<string>('')
    const isLoading = ref<boolean>(false)
    const initiallySelectAllFilteredDocs = ref<boolean>(false)

    const props = defineProps({
        documentIds: {
            type: Array as PropType<number[]>,
            required: false,
        },
        selectedTitleNumbers: {
            type: Array as PropType<string[]>,
            required: false,
        },
        showEmptyTitles: {
            type: Boolean,
            required: false,
            default: false,
        },
    })

    watch(() => props.documentIds, () => {
        if (!isNullOrEmpty(props.documentIds)) {
            // If title number passed in, then want to pre-select all the documents for that title number.
            initiallySelectAllFilteredDocs.value = true
        }
    }, { immediate: true })

    watch(() => props.selectedTitleNumbers, () => {
        filterText.value = props.selectedTitleNumbers?.length === 1 ? props.selectedTitleNumbers[0] : ''
        if (!isNullOrWhitespace(filterText.value)) {
            // If title number passed in, then want to pre-select all the documents for that title number.
            initiallySelectAllFilteredDocs.value = true
        }
    }, { immediate: true })

    const {
        copilotUrl,
        trackClick,
        setDocumentIds,
        setTitleNumbers,
    } = useCopilot({
        clientCode: clientCode.value,
        matterCode: matterCode.value,
        report: '',
        matterId: currentMatterId.value,
        position: 'Document Selection Modal',
    })

    /**
     * Creates an array of items with associated documents for each title numbers in the matter.
     * Any titles that have no documents are removed.
     */
    const createItemsArray = (): void => {
        // If no matter titles exit
        if (isNullOrEmpty(matterTitles?.value)) {
            return
        }
        const titles: any[] = props?.selectedTitleNumbers?.length ? matterTitles?.value.filter(t => props.selectedTitleNumbers.includes(t.titleNumber)) : matterTitles?.value

        // Create the title number groups for all title numbers in the matter.
        titles?.map(mt => {
            // Shouldn't have duplicates, but just in case check the title number isn't there!
            if (items.some(it => it.titleNumber === mt.titleNumber)) {
                return
            }

            items.push({
                titleNumber: mt.titleNumber,
                address: mt.addresses[0],
                documents: [],
                groupId: mt.matterGroupId,
            })
        })

        const titleNumbers = items.map(it => it.titleNumber)

        // Add the uploaded docs to each of the title number groups.
        uploadedDocuments?.value.forEach(ud => {
            if (titleNumbers.includes(ud.titleNumber)) {
                const matchingTitleNumberObject = items.find(it => it.titleNumber === ud.titleNumber)
                matchingTitleNumberObject.documents
                    .push({
                        documentType: ud.type,
                        source: 'Uploaded',
                        group: matterGroups.value.find(g => g.id === ud.groupId)?.name,
                        docDate: format(ud.updatedOn),
                        documentId: ud.id,
                        titleNumber: ud.titleNumber,
                        selected: false,
                        name: ud.fileName,
                    })
            }
        })

        // Add the purchased docs to each of the title number groups.
        purchasedDocuments?.value.forEach(pd => {
            if (titleNumbers.includes(pd.titleNo) &&
                COPILOT_SUPPORTED_DOC_TYPES.includes(pd.documentType)) {
                items
                    .find(it => it.titleNumber === pd.titleNo)
                    .documents
                    .push({
                        documentType: pd.documentType,
                        source: pd.documentSource,
                        group: matterGroups.value.find(g => g.id === pd.groupId)?.name,
                        docDate: pd.documentDate,
                        documentId: pd.documentId,
                        titleNumber: pd.titleNo,
                        selected: false,
                        name: getPurchasedDocumentName(pd),
                    })
            }
        })

        // Reactively remove titles that have no documents.
        if (!props.showEmptyTitles) {
            const updated = items.filter(it => !isNullOrEmpty(it.documents))
            items.splice(0, items.length, ...updated)
        } else {
            // order the items by placing empty titles at the end
            items.sort((a, b) => {
                if (a.documents.length === 0 && b.documents.length > 0) {
                    return 1
                }
                if (a.documents.length > 0 && b.documents.length === 0) {
                    return -1
                }
                return 0
            })
        }

        // Want to pre-select any documents that have been passed in.
        if (!isNullOrEmpty(props.documentIds)) {
            updateDocumentsSelected(true, props.documentIds)
        }

        // Initially pre-select any filtered documents.
        if (!isNullOrEmpty(props.selectedTitleNumbers) &&
            initiallySelectAllFilteredDocs.value &&
            isNullOrEmpty(props.documentIds)) {
            updateDocumentsSelected(true, filteredItemDocumentIds.value)
            initiallySelectAllFilteredDocs.value = false

        // If selected title numbers passed in, but no document ids, then select all documents for those title numbers.
        } else if (props.selectedTitleNumbers?.length &&
            isNullOrEmpty(props.documentIds)) {
            updateDocumentsSelectedByTitleNumbers(true, props.selectedTitleNumbers)
        }

    }

    // Create computed properties based off the items array.
    const itemsDocs = computed((): Array<IDocumentSelectionGroupDocument> =>
        items.map(i => i.documents.concat()).flat(1))

    /**
     * Update the selected state of the documents.
     */
    const selectedDocuments = ref<Array<IDocumentSelectionGroupDocument>>(itemsDocs.value.filter(d => d.selected))
    const selectedDocumentIds = computed((): Array<number> => selectedDocuments.value.map(d => d.documentId))
    const selectedTitleNumberIds = computed((): Array<string> => {
        // unique title numbers using Set
        return [...new Set(selectedDocuments.value.map(d => d.titleNumber))]
    })
    const hasSelectedDocuments = computed((): boolean => itemsDocs.value.filter(d => d.selected).length > 0)

    const selectionIsValid = computed(() =>
        hasSelectedDocuments.value &&
        selectedDocumentIds.value.length <= COPILOT_DOC_LIMIT)
    const maxDocsSelectedMessage = computed(() => `Too many documents selected (${ COPILOT_DOC_LIMIT } max). Please deselect at least ${ selectedDocumentIds.value.length - COPILOT_DOC_LIMIT }.`)

    /**
     * Update the selected state of the documents.
     */
    const updateDocumentsSelected = (selected: boolean, documentIds: number[]) => {
        items.forEach(tn => {
            tn.documents.forEach(d => {
                if (documentIds.includes(d.documentId)) {
                    d.selected = selected
                }
            })
        })
        selectedDocuments.value = itemsDocs.value.filter(d => d.selected)
        setSelectAllIndeterminate()
    }

    /**
     * Update the selected state of the documents for the filtered title numbers
     */
    const updateDocumentsSelectedByTitleNumbers = (selected: boolean, titleNumbers: string[]) => {
        items.forEach(tn => {
            if (titleNumbers.includes(tn.titleNumber)) {
                tn.documents.forEach(d => {
                    d.selected = selected
                })
            }
        })
        selectedDocuments.value = itemsDocs.value.filter(d => d.selected)
        setSelectAllIndeterminate()
    }

    /**
     * Deal with the select all checkbox.
     */
    const areAllSelected = computed({
        get: (): boolean => {
            return !isSelectAllIndeterminate.value &&
                selectedDocumentIds.value.length === itemsDocs.value.length
        },
        set: (isSelected: boolean): void => {
            updateDocumentsSelected(isSelected, filteredItemDocumentIds.value)
        },
    })

    const isSelectAllIndeterminate = ref<boolean>(false)
    // Should select all checkbox be set to indeterminate, checked or empty?
    const setSelectAllIndeterminate = (): void => {
        if (isNullOrEmpty(selectedDocuments.value)) {
            isSelectAllIndeterminate.value = false
            return
        }

        isSelectAllIndeterminate.value = selectedDocuments.value.length !== itemsDocs.value.length
    }

    /**
     * Handle document filtering.
     */
    const filteredItems = computed(() => {
        const filterVal = filterText.value

        return new FilterDocuments(items).filter(filterVal)
    })

    const filteredItemDocuments = computed((): Array<IDocumentSelectionGroupDocument> =>
        filteredItems.value.map(fi => fi.documents.concat()).flat(1))
    const filteredItemDocumentIds = computed((): Array<number> => filteredItemDocuments.value.map(d => d.documentId))

    /**
     * Handles form submission.
     */
    const handleSubmit = async (): Promise<void> => {
        if (selectionIsValid.value) {
            // Only send title number if all documents are from the same title.
            const allDocsFromSameTitleNumber =
                selectedDocuments.value.every(x => x.titleNumber === selectedDocuments.value[0].titleNumber)
            setTitleNumbers(allDocsFromSameTitleNumber ? [selectedDocuments.value[0].titleNumber] : selectedTitleNumberIds.value)

            setDocumentIds(selectedDocumentIds.value)
            await trackClick()
            console.info('Copilot URL: ', copilotUrl.value)
            await store.dispatch(OPEN_WINDOW, copilotUrl.value)
        }
    }

    /**
     * Initialise the documents required for the component.
     */
    onMounted(async () => {
        if (currentMatterId.value &&
            (isNullOrEmpty(purchasedDocuments.value) && isNullOrEmpty(uploadedDocuments.value))) {
            isLoading.value = true
            await store.dispatch(DOCUMENTS_FETCH_UPLOADED_DOCUMENTS, currentMatterId.value)
            await store.dispatch(REFRESH_DOCUMENTS, currentMatterId.value)
            isLoading.value = false
        }
        createItemsArray()
    })
</script>

<style lang="scss" scoped>
    @import './document-selection';
</style>
