<template>
    <section class="base-sub-type-item">
        <h3 v-show="showExpandCollapse"
            class="caption-highlight expansion-panel__toggle"
            data-track="Asset monitoring: toggle details panel"
            :data-test="`${dataTest}-toggle`"
            :data-track-type="dataTrackType"
            @click="isShowingDetailsPanel = !isShowingDetailsPanel">
            {{ isShowingDetailsPanel ? t('label.hideDetails') : t('label.showDetails') }}
            <v-icon>{{ isShowingDetailsPanel ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        </h3>
        <div v-show="isShowingDetailsPanel"
             class="expansion-panel__content">
            <slot />
        </div>
    </section>
</template>

<script lang="ts" setup generic="Datapoint">
    import {
        onMounted,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import { Difference } from "@/components/asset-monitoring/difference-generic"

    const props = withDefaults(defineProps<{
        dataTrackType: string,
        dataTest: string,
        notification: IAssetMonitoringNotificationDetail
        showExpandCollapse?: boolean
    }>(), {
        showExpandCollapse: true,
    })

    const { t } = useI18n()
    const isShowingDetailsPanel = defineModel({
        default: false,
    })

    const diff = defineModel<Difference<Datapoint>>('diff')

    onMounted(() => {
        if (props.notification) {
            diff.value = JSON.parse(props.notification?.difference)
        }
    })
</script>

<style lang="scss" scoped>
    @import "base-sub-type-item";
</style>
