<template>
    <base-row data-test="title-register-row"
              :title="title"
              export-button-data-test="order-register-button-document"
              export-button-data-track="TITLE-DETAILS-PANEL - Export register as word document from summary panel"
              heap-tracker-prefix="TITLE_REGISTER_CARD"
              class="order-title-card"
              type="register"
              :dates="dates"
              :show-export-button="isDocumentAvailable ? true : false"
              :current-matter-id="currentMatterId"
              :selected-title-number="selectedTitleNumber"
              :document-id="registerAvailability?.documentId"
              :info-content="infoContent"
              :disable-view-button="!isDocumentAvailable"
              :disable-export-button="!isDocumentAvailable"
              :backdated="showBackdatedViewButton"
              :document-availability-code="documentAvailabilityCode"
              :disable-export-button-menu="disableExportMenu"
              :is-loading="registerAvailability?.loadingOrder || loading"
              @order="orderRegister(true)"
              @view="viewRegisterDocument"
              @export="exportAsPdf" />
</template>

<script setup lang="ts">
    import { formatDistance } from 'date-fns'
    import { computed,
             nextTick,
             ref,
             watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'
    import {
        useStore,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import BaseRow, {
        DateType,
        InfoContentType } from '@/components/title-panel/tabs/summary-tab/order-documents-card/sub-type-renderer/base-row.vue'
    import useDates from '@/composables/use-dates'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { DOCUMENT_SOURCE,
             getDataProviderText } from '@/consts/document-source'
    import { ColorClass } from '@/enums/color-class.enum'
    import { Route } from '@/enums/route.enum'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        ADD_DOCUMENT_TO_MATTER,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import { TITLE_MUTATE_REGISTER_ORDER_LOADING } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_GET_DOCUMENT_CHARGE,
    } from '@/store/mutation-types'
    import {
        differenceInMonths,
    } from '@/utils/date-utils'

    const props = defineProps<{
        selectedTitle?: any,
        selectedTitleNumber?: string,
        isOrderingAllowed: boolean,
        currentMatterId?: number,
        useReviewAssistant: boolean,
        title: string
    }>()

    const store = useStore()
    const { formatDateShort } = useDates()
    const { t } = useI18n()
    const router = useRouter()

    const formatDate = (value: Date): string => {
        return formatDateShort(value)
    }

    const registerAvailability = computed(() => props.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const loading = ref(false)

    const issuedOnDate = computed(() => {
        return registerAvailability?.value?.responseDateValue ?
            new Date(registerAvailability.value.responseDateValue) : null
    })

    const dates = ref<DateType[]>([])
    watch(() => props.selectedTitle?.officialCopiesAvailability?.results?.titleRegister, (newValue) => {
        const result = []
        if (newValue) {
            // edition date
            if (newValue?.editionDateValue) {
                result.push({
                    displayValue: t('documents.edition', { date: formatDate(newValue.editionDateValue)}),
                    dataTest: 'order-register-label-loaded-date',
                    emptyValue: t('reviewAssistant.document.editionDate'),
                })
            }

            // search from date
            if (newValue?.officialCopyDateTime) {
                result.push({
                    displayValue: t('documents.searchFrom', { date: formatDate(newValue.officialCopyDateTime)}),
                    dataTest : 'order-register-label-search-from-date',
                })
            }

            // issued on date
            if (newValue?.responseDateValue) {
                result.push({
                    displayValue: t('documents.issuedOn', { date: formatDate(newValue.responseDateValue) }),
                    dataTest: 'order-register-label-issued-on-date',
                    alert: issuedOnDate.value && differenceInMonths(new Date(), issuedOnDate.value) >= 3,
                })
            }
        }
        dates.value = result
    }, {
        immediate: true,
        deep: true,
    })

    const isExportPdfLoading = ref(false)

    const isFailedOrder = computed(() => {
        return registerAvailability.value?.failedOrder
    })

    const editionDateValue = computed(() => {
        return registerAvailability?.value?.editionDateValue ?
            new Date(registerAvailability.value.editionDateValue) : null
    })

    const isDocumentUnderInvestigation = computed(() => {
        return registerAvailability?.value?.orderStatus === DocumentOrderStatus.UNDER_INVESTIGATION
    })

    const isDocumentAvailable = computed(() => {
        return registerAvailability?.value?.documentId !== null || editionDateValue.value !== null
    })

    const showBackdatedViewButton = computed(() => {
        return registerAvailability?.value?.currentVersionIsBackdated ?? false
    })

    const documentAvailabilityCode = computed(() => {
        return registerAvailability?.value.hmlrAvailabilityCode ?? null
    })

    const documentCharge = computed(() => {
        return store.getters[USER_GET_DOCUMENT_CHARGE]
    })

    const disableExportMenu = computed(() => {
        return documentSource.value === DOCUMENT_SOURCE.SCOTLAND
    })

    const documentSource = computed<string>(() => props.selectedTitle?.record?.source)

    const refreshText = computed(() => {
        switch (documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                return t('action.refreshAllWithCost', { cost: documentCharge.value })
            default:
                return t('action.refreshWithCost', { cost: documentCharge.value })
        }
    })

    const refreshSubtitleText = computed(() => {
        if (!documentSource.value) {
            return ''
        }
        const oneday = 60 * 60 * 24 * 1000
        const date = issuedOnDate.value ? formatDistance(issuedOnDate.value, new Date(), {
            addSuffix: true,
        }) : ''
        if (issuedOnDate.value && new Date(issuedOnDate.value).getTime() >= new Date().getTime() - oneday) {
            return t(`documents.message.${ documentSource.value }.oldTitleRegisterWithDate`, { date: t('documents.message.today') })
        }
        return t(`documents.message.${ documentSource.value }.oldTitleRegisterWithDate`, { date })
    })

    const infoContent = computed<InfoContentType[]>(() => {

        const defaultRefreshInfo = {
            dataTest: 'order-register-button-refresh',
            dataTrack: documentSource.value === DOCUMENT_SOURCE.SCOTLAND
                ? `TITLE-DETAILS-PANEL - Refresh Title documents from ${ getDataProviderText(documentSource.value) }`
                : 'TITLE-DETAILS-PANEL - Refresh Title Register',
            subtitle:  refreshSubtitleText.value,
            ctaText: refreshText.value,
            clickHandler: () => orderRegister(),
            class: ColorClass.Information,
            show: props.isOrderingAllowed,
        }

        const info = []
        // if issued on date is over three months old
        if ((issuedOnDate.value && differenceInMonths(new Date(), issuedOnDate.value) >= 3)) {
            info.push({
                dataTest: 'order-register-button-refresh',
                dataTrack: 'TITLE-DETAILS-PANEL - Backdated - Refresh Title Register',
                title: 'Backdated',
                subtitle: refreshSubtitleText.value,
                ctaText: t('action.refreshWithCost', { cost: documentCharge.value }),
                clickHandler: () => orderRegister(true),
                class: ColorClass.Warning,
                show: props.isOrderingAllowed,
            })
        }

        if (isDocumentUnderInvestigation.value) {
            info.push({
                subtitle: t('documents.message.underInvestigation'),
                class: ColorClass.Primary,
            })
        }

        if (isFailedOrder.value && documentAvailabilityCode.value !== HmlrDocumentAvailabilityCode.Unavailable) {
            info.push({
                dataTest: 'order-register-button-refresh',
                dataTrack: 'TITLE-DETAILS-PANEL - Failed - Refresh Title Register',
                title: t('documents.error.failedOrder'),
                class: ColorClass.Danger,
                ctaText: t('action.retryWithCost', { cost: documentCharge.value }),
                clickHandler: () => orderRegister(true),
                show: props.isOrderingAllowed,
            })
        }

        if (info.length === 0 && !isDocumentUnderInvestigation.value) {
            info.push(defaultRefreshInfo)
        }

        return info
    })

    const exportAsPdf = async () => {
        isExportPdfLoading.value = true

        const documentId = props.selectedTitle?.record?.titleMetadata?.registerDocumentId
        if (!documentId) {
            console.error('No documentId found for register document')
            return
        }
        await DocumentsApi.downloadDocumentByTypeAndId(HighLevelDocumentType.Register, documentId)

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Register - Export document',
            metadata: {
                titleNumber: props.selectedTitleNumber,
                matterId: props.currentMatterId,
                docFormat: '.pdf',
                documentSource: getDataProviderText(documentSource.value),
            },
        })

        isExportPdfLoading.value = false
    }

    const orderRegister = async (force: boolean = false) => {
        try {
            store.commit(TITLE_MUTATE_REGISTER_ORDER_LOADING, true)
            nextTick( async () => {
                const request = DocumentOrderRequest.createRegisterRequest(
                    props.selectedTitleNumber,
                    props.currentMatterId,
                    force,
                )
                await store.dispatch(`documentOrdering/${ ORDER_DOCUMENTS }`, [request])
            })
        } catch (error) {
            console.error(error)
        }
    }

    const viewRegisterDocument = async () => {

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Register - View document',
            metadata: {
                titleNumber: props.selectedTitleNumber,
                matterId: props.currentMatterId,
                documentSource: getDataProviderText(documentSource.value),
            },
        })

        if (props.currentMatterId
            && props.useReviewAssistant
        ) {
            router.push({
                name: Route.ReviewAssistant,
                params: {
                    matterId: props.currentMatterId,
                    titleNumber: props.selectedTitleNumber,
                },
            })
        } else {
            const success = await addTitleAndDocumentToMatter()
            if (success) {
                const documentId = registerAvailability?.value?.documentId
                router.push({
                    name: Route.DocumentViewer,
                    params: {
                        documentType: HighLevelDocumentType.Register,
                        documentId,
                    },
                    query: {
                        fromMatterId: props.currentMatterId,
                    },
                })
            }
        }
    }

    const addTitleAndDocumentToMatter = async () => {
        await store.dispatch(MATTER_ADD_TITLE, {
            titleNumber: props.selectedTitleNumber,
            show: true,
        })
        const documentId = registerAvailability?.value?.documentId
        if (documentId) {
            await store.dispatch(ADD_DOCUMENT_TO_MATTER, {
                documentType: HighLevelDocumentType.Register,
                documentId,
                matterId: props.currentMatterId,
            })

            return true
        }
        console.error('Unable to add document to matter as no documentId in the store', registerAvailability.value)
        return false
    }

    watch(() => registerAvailability.value?.loadingOrder, (newValue) => {
        if (newValue) {
            loading.value = true
        }
    })

    defineExpose({
        router,
    })
</script>

<style lang="scss">
    @import './base-row.scss';
</style>
