<template>
    <div class="d-flex align-center flex-row justify-space-between mb-4">
        <section>
            <ow-loading-text v-if="isLoading"
                             data-test="find-companies-grid-loading"
                             :is-loading="isLoading"
                             :text="$t('assetMonitoring.preferences.cards.findCompanies.loading')" />
            <span v-else
                  data-test="find-companies-grid-companies-found"
                  class="caption-regular">{{ companiesFoundText }}</span>
        </section>

        <section class="d-flex align-center gc-2">
            <label class="caption-regular text-no-wrap"
                   for="sortBy">{{ $t('assetMonitoring.preferences.cards.findCompanies.sortBy') }}</label>
            <v-select class="find-companies-grid__sort-by"
                      data-test="find-companies-grid-sort-by"
                      :model-value="filter"
                      hide-details
                      density="compact"
                      item-value="value"
                      item-title="text"
                      :disabled="isLoading || items.length === 0"
                      :items="sortByItems"
                      variant="outlined"
                      @update:model-value="onSelectFilter" />
        </section>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import OwLoadingText from "@/components/core/ow-loading-text.vue"

    const props = defineProps<{
        items: any[],
        sortBy: any[],
        headers: any[],
        isLoading: boolean,
    }>()

    const { t } = useI18n()

    const filter = defineModel('filter', {
        default: 'name',
    })

    const emit = defineEmits<{
        (e: 'sort', value: any)
    }>()

    const sortByItems = computed(() => {
        return props.headers.map((header) => {
            return {
                text: header.title,
                value: header.key,
            }
        })
    })

    const companiesFoundText = computed(() => {
        if (props.items.length === 0) {
            return t('assetMonitoring.preferences.cards.findCompanies.search')
        }
        return t('assetMonitoring.preferences.cards.findCompanies.companiesFound', {
            number: props.items.length,
        })
    })

    const onSelectFilter = (value: string) => {
        // clear the current sort
        filter.value = value
        emit('sort', [{ key: value, order: props.sortBy[0].order === 'asc' ? 'desc' : 'asc' }])
    }

    watch(() => props.sortBy, () => {
        filter.value = props.sortBy[0].key
    })

</script>

<style scoped>

</style>