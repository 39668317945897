<template>
    <v-dialog v-model="show"
              max-width="570">
        <v-card class="title-analysis-template-selection"
                data-test="title-analysis-template-selection">
            <v-card-title>
                <div class="title-analysis-template-selection__title">
                    <h3>Templates</h3>
                    <v-btn data-test="title-analysis-templates-close"
                           data-track="TAM - Close template selection popup"
                           icon
                           variant="text"
                           @click="show = false">
                        <v-icon>$close</v-icon>
                    </v-btn>
                </div>
            </v-card-title>
            <v-divider />

            <v-card-text>
                <p class="title-analysis-template-selection__info">
                    Apply column, group, and filter settings for common tasks.
                </p>
                <div v-for="(template, index) in data"
                     :key="index"
                     :data-template-name="template.name"
                     class="d-flex flex-row title-analysis-template-selection__option"
                     data-test="title-analysis-template-selection-item">
                    <div>
                        <h4 class="content__main-heading">
                            {{ template.name }}
                        </h4>
                        <p style="font-size: small;">
                            {{ template.description }}
                        </p>
                    </div>
                    <v-btn color="primary"
                           data-test="title-analysis-template-apply"
                           data-track="TAM - Apply template"
                           size="large"
                           @click="applyTemplate(template)">
                        Apply
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapActions,
             mapMutations,
             mapState } from 'vuex'

    import { getTemplates } from '@/components/title-analysis/configuration/templates'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import {
        ANALYSIS_MUTATE_APPLYING_TEMPLATE,
        ANALYSIS_MUTATE_GROUPING,
        ANALYSIS_MUTATE_SELECTED_COLUMNS,
        ANALYSIS_MUTATE_SELECTED_FILTERS,
        ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION,
        ANALYSIS_MUTATE_SORT,
        ANALYSIS_MUTATE_TEMPLATE_APPLIED,
    } from '@/store/modules/title-analysis/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    export default {
        name: 'TitleAnalysisTemplateSelection',

        mixins: [FeatureFlagsMixin],

        data() {
            return {
                data: [],
            }
        },

        computed: {
            ...mapState({
                getShowTemplateSelection: state => state.titleAnalysis.showTemplateSelection,
                initialGridConfig: state => state.titleAnalysis.initialGridConfig,
            }),

            show: {
                get() {
                    return this.getShowTemplateSelection
                },
                set(val) {
                    if (val) {
                        this.refresh()
                    }
                    this.showTemplateSelection(val)
                },
            },

        },

        watch: {
            show(val) {
                if (val) {
                    this.refresh()
                }
            },
        },

        mounted() {
            this.showTemplateSelection(false)
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations({
                showTemplateSelection: ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION,
                mutateSelectedColumns: ANALYSIS_MUTATE_SELECTED_COLUMNS,
                mutateSort: ANALYSIS_MUTATE_SORT,
                mutateGrouping: ANALYSIS_MUTATE_GROUPING,
                mutateApplyingTemplate: ANALYSIS_MUTATE_APPLYING_TEMPLATE,
                mutateFilters: ANALYSIS_MUTATE_SELECTED_FILTERS,
                mutateTemplateApplied: ANALYSIS_MUTATE_TEMPLATE_APPLIED,
            }),

            refresh() {
                this.data = getTemplates()
            },

            applyTemplate(template) {
                this.mutateApplyingTemplate(true)

                // Add columns based on the template config
                const columns = template.columns.map(c => {
                    return this.initialGridConfig.columns.find(i => i.field === c)
                })
                this.mutateSelectedColumns(columns)

                // Add groupings
                if (template.groupBy) {
                    this.mutateGrouping({
                        field: template.groupBy.property,
                        ascending: template.groupBy.ascending === true,
                        complexMapping: false,
                    })
                } else {
                    this.mutateGrouping(null)
                }

                // Add sorters
                if (template.sortBy) {
                    this.mutateSort(
                        {
                            field: template.sortBy.property,
                            ascending: template.sortBy.ascending === true,
                        },
                    )
                } else {
                    this.mutateSort(null)
                }

                // Add filters
                // TODO: These filters will not be selected in the FilterBar component - Bryntum Grid issue?
                if (template.filters) {
                    const filters = []
                    template.filters.forEach(f => {
                        const filter = {
                            property: f.property,
                            value: f.value,
                        }
                        if (f.operator) {
                            filter.operator = f.operator
                        }
                        filters.push(filter)
                    })
                    this.mutateFilters(filters)
                }

                // Finished - hide the panel...
                this.show = false
                this.mutateTemplateApplied(template)

                // Log event
                this.logHeapEvent({
                    type: 'TAM - Template Selection',
                    metadata: {
                        template: template.name,
                    },
                })

                // ...and indicate template application is done
                setTimeout(() => {
                    this.mutateApplyingTemplate(false)
                }, 2000)
            },

        },
    }
</script>
<style lang="scss">
    @import 'title-analysis-template-selection.scss';
</style>
