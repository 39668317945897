import {
    computed,
    ref,
    watch,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import {
    NavAction,
    NavItem,
} from '@/components/top-nav/types'
import { Route } from '@/enums/route.enum'
import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'

export function useMapTopNav() {
    const store = useStore()
    const { t } = useI18n()
    const matterId = ref(store.state.matter?.currentMatter?.id)

    const isSharedLinkView = ref(false)
    const isSiteVisit = ref(false)
    const isTopNavVisible = computed(() => !isSharedLinkView.value && !isSiteVisit.value)

    const topNavActions = computed<NavAction[]>(() => [
        {
            title: t('action.share').toString(),
            icon: '$share',
            responsive: 'sm',
            dataTrackId: 'Top Nav - Share button clicked',
            dataTestId: 'nav-share-btn',
        },
        {
            title: t('action.downloadSnapshot').toString(),
            icon: '$snapshot',
            responsive: 'xs',
            primary: true,
            dataTrackId: 'Top Nav - Download site plan button clicked',
            dataTestId: 'nav-download-snapshot-btn',
        },
    ])

    const topNavItems = computed<NavItem[]>(() => {
        const navItems: NavItem[] = [
            { title: t('titles.titles').toString(), route: Route.MatterMap, routeParams: { matterId: matterId.value?.toString() }, dataTrackId: 'Top Nav - Titles clicked', dataTestId: 'top-nav-titles' },
            { title: t('sketches.title').toString(), route: Route.MatterSketches, routeParams: { matterId: matterId.value?.toString() }, dataTrackId: 'Top Nav - Sketches clicked', dataTestId: 'top-nav-sketches' },
            { title: t('mapOverlays.title').toString(), route: Route.OverlaysList, routeParams: { matterId: matterId.value?.toString() }, dataTrackId: 'NAVIGATION - Overlay Plans', dataTestId: 'top-nav-overlays' },
        ]

        return [...navItems]
    })

    watch(() => store.state.matter?.currentMatter?.id, (value) => {
        matterId.value = value
    })

    watch(() => store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`], (value) => {
        isSharedLinkView.value = value
    }, {
        immediate: true,
    })

    watch(() => store.state?.siteVisit?.enabled, (value) => {
        isSiteVisit.value = value
    }, {
        immediate: true,
    })

    return {
        isTopNavVisible,
        topNavActions,
        topNavItems,
    }
}
