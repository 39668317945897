<template>
    <ow-full-width-layout data-test="matter-settings"
                          header-icon="$matter-settings"
                          header-title="Matter Settings"
                          :inline-step-flow="true"
                          is-fixed-width
                          page-name="matter-settings">
        <template #navigation>
            <settings-sub-navigation :current-matter-id="currentMatter.id" />
        </template>
        <div class="matter-settings__content"
             data-test="matters-settings-content">
            <matters-settings-loading-skeleton v-if="isLoading" />
            <div v-else>
                <matter-title class="px-2 mb-5"
                              :details="currentMatter"
                              data-test-id="matter-title"
                              @delete="onDeleteMatter"
                              @update="updateMatterName" />

                <div class="matter-settings__grid px-2 gc-5 gr-7 mb-7">
                    <matter-details :details="currentMatter"
                                    :types="types"
                                    @update="updateMatterDetails"
                                    @duplicate-matter="duplicateCurrentMatter" />

                    <disbursable-charges :charges="currentMatter.charges" />
                </div>

                <div class="px-2">
                    <matter-share full-width
                                  is-card-layout
                                  is-settings-page
                                  @log-heap-event="logHeapEventForPreview" />
                </div>
            </div>
        </div>
        <duplicate-matter-dialog :current-matter-id="currentMatter.id"
                                 :default-matter-name="`${currentMatter.name} - Copy`"
                                 :default-matter-code="`${currentMatter.code} - Copy`" />
    </ow-full-width-layout>
</template>

<script lang="ts">
    import { mapActions,
             mapGetters,
             mapMutations,
             mapState } from 'vuex'

    import OwFullWidthLayout from '@/components/core/layout/full-width-white-header.vue'
    import MattersSettingsLoadingSkeleton from '@/components/loading-skeletons/matters-settings-loading-skeleton.vue'
    import MatterShare from '@/components/matter/matter-share.vue'
    import DisbursableCharges from '@/components/matter/settings/disbursable-charges.vue'
    import DuplicateMatterDialog from '@/components/matter/settings/duplicate-matter-dialog.vue'
    import MatterDetails from '@/components/matter/settings/matter-details.vue'
    import MatterTitle from '@/components/matter/settings/matter-title.vue'
    import SettingsSubNavigation from '@/components/matter/settings/settings-sub-navigation.vue'
    import { ApplicationRoles } from '@/enums/application-roles.enum'
    import { Route } from '@/enums/route.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { MATTER_DELETE,
             MATTER_GET_TYPES,
             MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG,
             MATTER_UPDATE } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MattersSettingsPage',

        components: {
            SettingsSubNavigation,
            DuplicateMatterDialog,
            MatterDetails,
            DisbursableCharges,
            MatterTitle,
            MatterShare,
            MattersSettingsLoadingSkeleton,
            OwFullWidthLayout,
        },

        mixins: [FlagsMixin],

        computed: {
            ...mapGetters({
                types: MATTER_GET_TYPES,
            }),

            ...mapState({
                currentMatter: state => state.matter.currentMatter,
                loadingState: state => state.matter.loadingState,
                user: state => state.user,
            }),

            isLoading() {
                return this.loadingState.loadingCurrentMatter || this.loadingState.deleteMatter
            },
        },

        methods: {
            ...mapActions({
                updateMatter: MATTER_UPDATE,
                deleteMatter: MATTER_DELETE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),
            ...mapMutations({
                mutateShowDuplicateMatterDialog: MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG,
            }),

            async saveMatterDetails(updates, isNameUpdateOnly) {
                const current = {
                    id: this.currentMatter.id,
                    name: this.currentMatter.name,
                    code: this.currentMatter.code,
                    clientCode: this.currentMatter.clientCode,
                    type: this.currentMatter.type,
                }

                const matter = {
                    ...current,
                    ...updates,
                }

                // If the client code has previously been set, but the organisation isn't using client code,
                // then remove it so that the API doesn't throw an error.
                if (!this.user?.usesClientCodes && !isNullOrWhitespace(matter.clientCode)) {
                    matter.clientCode = null
                }

                await this.updateMatter({ matter, isNameUpdateOnly })
            },

            async updateMatterDetails(details) {
                await this.saveMatterDetails(details)
            },

            async updateMatterName(name) {
                await this.saveMatterDetails({ name }, true)
            },

            async duplicateCurrentMatter() {
                this.mutateShowDuplicateMatterDialog(true)
            },

            logHeapEventForPreview() {
                this.logHeapEvent({
                    type: 'Matter Settings: Enter Preview',
                    metadata: {
                        matterId: this.currentMatter.id,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },

            async onDeleteMatter() {
                await this.deleteMatter(this.currentMatter.id)
                await this.$router.push({ name: Route.MattersList })
            },
        },
    }
</script>

<style lang="scss">
    @import './matters-settings';
</style>
